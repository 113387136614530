<template>
  <th
    scope="col"
    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 bg-gray-50"
    :class="{'hover:bg-gray-200 cursor-pointer': column.sortable, 'sticky-col second-col': (!isReadOnly && isSticky), 'sticky-col shared-first-col': (isReadOnly && isSticky)}"
    :style="{'min-width': column.minWidth || '100px'}"
    @click="$emit('sort', column)"
  >
    <div v-if="showBulkFields && !isReadOnly" class="space-x-3">
      <button
        type="button"
        class="table-btn table-btn-indigo"
        @click.stop="$emit('showBulkEdit')"
      >Opdatering ({{bulkCount}})</button>
      <button
        type="button"
        class="table-btn table-btn-white"
        @click.stop="$emit('deleteBulk')"
      >Slet ({{bulkCount}})</button>
    </div>

    <div v-else class="flex items-center justify-between overflow-ellipsis">
      <span>{{column.label}}</span>
      <template v-if="column.sortable">
        <icon-sort-ascending
          v-if="sortField == column.field && sortDir == 'asc'"
          class="w-4 h-4 text-gray-400"
        ></icon-sort-ascending>
        <icon-sort-descending
          v-if="sortField == column.field  && sortDir == 'desc'"
          class="w-4 h-4 text-gray-400"
        ></icon-sort-descending>
      </template>
    </div>
  </th>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    column: {
      type: Object,
      required: true,
    },
    isSticky: {
      type: Boolean,
      default: false,
      description: 'Whether the th is sticky on scroll',
    },
    showBulkFields: {
      type: Boolean,
      default: false,
      description: 'Whether to show bulk edit and bulk delete. Typically only done on index 0',
    },
    bulkCount: {
      type: Number,
      default: 0,
      description: 'The current selected bulk count',
    },
    sortField: {
      type: String,
      default: '',
      description: 'The currently active sort field',
    },
    sortDir: {
      type: String,
      default: '',
      description: 'The currently active sort direction: asc | desc',
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.table-btn {
  @apply inline-flex items-center rounded  px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2;
}

.table-btn-indigo {
  @apply border border-indigo-300 bg-indigo-600 hover:bg-indigo-500 text-white;
}

.table-btn-white {
  @apply border border-gray-300 bg-white hover:bg-gray-50 text-gray-700;
}

</style>

<template>
  <job-poller
    v-if="executeMergePlanJobId"
    title="Udfører plan for sammenfletning"
    description="Dette kan tage nogle minutter, hvis der er mange opgaver"
    :job-id="executeMergePlanJobId"
    :type="jobTypes.EXECUTEMERGEPLAN"
    :dismissable="false"
    class="mb-6"
    @result="onExecuteMergePlanResult"
  >
    <image-container v-if="hasExecuteMergePlan" asset-name="success">
      <router-link :to="{name: 'tasks'}">
        <base-button>Gå til opgaver</base-button>
      </router-link>
    </image-container>
  </job-poller>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { SET_HAS_EXECUTED_MERGE_PLAN } from '@/store/mutations.type';

import JobPoller from '@/components/JobPoller.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import * as jobTypes from '@/util/jobTypes';

export default {
  name: 'MergeFlowExecute',
  components: {
    JobPoller,
    ImageContainer,
  },
  data() {
    return {
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('jobs', ['executeMergePlanJobId']),
    ...mapGetters('import', ['hasExecuteMergePlan']),
  },
  mounted() {
    if (!this.executeMergePlanJobId) {
      this.$router.push({ name: 'task-import-overview' });
    }
  },
  methods: {
    onExecuteMergePlanResult() {
      this.setHasExecutedMergePlan(true);
    },
    ...mapMutations('import', {
      setHasExecutedMergePlan: SET_HAS_EXECUTED_MERGE_PLAN,
    }),
  },
};
</script>

<style>

</style>

import { addDays, getISODay, format } from 'date-fns';

// https://stackoverflow.com/questions/54142402/how-to-find-the-nearest-day-of-the-week-with-date-fns
// eslint-disable-next-line import/prefer-default-export
export const getDayInPast = (dayOfWeek, fromDate) => {
  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const dayOfWeekMap = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek
  const targetISODay = dayOfWeekMap[dayOfWeek];
  const fromISODay = getISODay(fromDate);

  // targetISODay >= fromISODay means we need to trace back to last week
  // e.g. target is Wed(3), from is Tue(2)
  // hence, need to -7 the account for the offset of a week
  const offsetDays = targetISODay >= fromISODay ? -7 + (targetISODay - fromISODay) : targetISODay - fromISODay;

  return addDays(fromDate, offsetDays);
};

export const toHtmlDatePickerFormat = (date) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
};

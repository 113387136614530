<template>
  <div v-if="isVisible" v-loading="loading" class="absolute bg-white z-50">
    <base-input
      v-if="editorType === 'string'"
      ref="theInput"
      v-model="value"
      :rounded="false"
      type="text"
      input-classes="cell-height"
      @blur="onBlur"
    />
    <base-input
      v-if="editorType === 'number'"
      ref="theInput"
      v-model="value"
      :rounded="false"
      type="number"
      input-classes="cell-height"
      @blur="onBlur"
    />
    <base-input v-if="editorType === 'select'" :rounded="false" input-classes="cell-height">
      <base-select
        slot="input"
        ref="theInput"
        v-model="value"
        height="52px"
        :rounded="false"
        :options="editingCell.cell.options()"
        :clearable="true"
        @input="onBlur"
      ></base-select>
    </base-input>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { UPDATE_TASK } from '@/store/actions.type';

export default {
  name: 'CellEditor',
  props: {
    editingCell: {
      type: Object,
      default: null,
      description: 'The cell being edited',
    },
  },
  data() {
    return {
      isVisible: false,
      loading: false,
      value: '',
      editorType: 'string',
    };
  },
  watch: {
    editingCell: {
      handler: 'onEditingCellChange',
      deep: true,
    },
  },
  methods: {
    ...mapActions('tasks', {
      update: UPDATE_TASK,
    }),
    onEditingCellChange() {
      if (!this.editingCell) {
        this.isVisible = false;
        return;
      }

      const { cell } = this.editingCell;

      if (!cell.editor) {
        return;
      }

      this.isVisible = true;

      this.editorType = cell.type;
      this.value = this.editingCell.currentValue || '';

      this.$nextTick(() => {
        const { styles } = this.editingCell;
        this.$el.style.height = `${styles.clientHeight}px`;
        this.$el.style.width = `${styles.clientWidth}px`;
        this.$el.style.top = `${styles.offsetTop}px`;
        this.$el.style.left = `${styles.offsetLeft}px`;

        this.$refs.theInput.focus();
      });
    },
    async onBlur() {
      if (this.editingCell.currentValue !== this.value) {
        try {
          this.loading = true;
          const updatedFields = await this.update({
            id: this.editingCell.rowId,
            field: this.editingCell.cell.field,
            value: this.value?.toString(),
          });
          this.$emit('updated', {
            updatedFields,
            rowId: this.editingCell.rowId,
          });
        } catch (error) {
          this.$unhandledError(error);
        } finally {
          this.loading = false;
        }

        this.$ntf.success(`Opdaterede ${this.editingCell.cell.label}`);
      }

      this.isVisible = false;
    },
  },
};
</script>

<style>

</style>

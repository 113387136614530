<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8 w-full mx-auto desktop-container bg-gray-100">
    <h1 class="text-2xl font-extrabold text-gray-900">Detaljer</h1>
    <dl v-if="details" class="sm:divide-y sm:divide-gray-200 rounded-md pb-8 mt-8 grid-cols-1">
      <key-value-pair :value="details.created_at" label="Oprettet" :show-badge="false"></key-value-pair>
      <key-value-pair :value="details.new_plan_start_date" label="Startdato" :show-badge="false"></key-value-pair>
      <key-value-pair :value="details.new_plan_start_week" label="Startuge" :show-badge="false"></key-value-pair>
      <key-value-pair
        :value="details.last_even_week_no"
        label="Sidste ugenr. for lige tømning i gamle plan"
        :show-badge="false"
      ></key-value-pair>
      <key-value-pair
        :value="details.last_odd_week_no"
        label="Sidste ugenr. for ulige tømning i gamle plan"
        :show-badge="false"
      ></key-value-pair>
      <key-value-pair
        :value="details.max_overstep_in_days"
        label="Maks antal dage som hver opgave må overskride"
        :show-badge="false"
      ></key-value-pair>

      <key-value-pair
        v-for="(entry, index) in details.irregular_interval_list"
        :key="index"
        :value="entry.value"
        :label="entry.label"
        :show-badge="false"
      ></key-value-pair>
    </dl>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import KeyValuePair from '@/components/KeyValuePair.vue';
import { getISOWeek, parse, subWeeks } from 'date-fns';
import { GET_TRANSITION_PERIOD_DETAILS } from '@/store/actions.type';

export default {
  name: 'TransitionPeriodDetails',
  components: {
    KeyValuePair,
  },
  data() {
    return {
      details: null,
    };
  },
  computed: {
    ...mapGetters('departments', ['activeWorkspaceId']),
  },
  async mounted() {
    const data = await this.getDetails(this.activeWorkspaceId);

    const parsedStartDate = parse(data.new_plan_start_date, 'dd-MM-yyyy', new Date()); // not MM-DD-YY

    const lastWeekInOldPlan = subWeeks(parsedStartDate, 1);
    const lastWeekInOldPlanIso = getISOWeek(lastWeekInOldPlan);

    const irregular_interval_list = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data.irregular_interval_dict)) {
      irregular_interval_list.push({ label: `Hver ${key}. uge`, value: `Frekvensen er kommet til uge ${value} i uge ${lastWeekInOldPlanIso}` });
    }

    data.irregular_interval_list = irregular_interval_list;
    data.new_plan_start_week = getISOWeek(parsedStartDate);

    this.details = data;
  },
  methods: {
    ...mapActions('transitionperiod', {
      getDetails: GET_TRANSITION_PERIOD_DETAILS,
    }),
  },
};
</script>

<style>

</style>

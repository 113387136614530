<template>
  <div class="flex">
    <div class="w-96 flex-shrink-0 bg-white screen-vh-minus-double-nav flex flex-col">
      <the-route-viewer-filter
        v-if="hasPlanned"
        :vehicle-options="vehicleOptions"
        @toggleSidebarList="$emit('toggleSidebarList')"
        @filter="$emit('filter', $event)"
      ></the-route-viewer-filter>

      <image-container v-else asset-name="empty" title="Du har endnu ikke planlagt">
        <router-link :to="{name: 'plan'}" class="mt-6">
          <base-button color="indigo">
            Gå til rutebygning
            <span class="ml-2">&rarr;</span>
          </base-button>
        </router-link>
      </image-container>
    </div>
  </div>
</template>

<script>
import TheRouteViewerFilter from '@/components/TheRouteViewerFilter.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import { mapActions, mapGetters } from 'vuex';
import { CHECK_PLANNED_STATUS } from '@/store/actions.type';

import VehicleService from '@/services/vehicles.service';

export default {
  name: 'TheRouteViewerSidebar',
  components: {
    TheRouteViewerFilter,
    ImageContainer,
  },
  data() {
    return {
      vehicleOptions: [],
    };
  },
  computed: {
    ...mapGetters('status', ['hasPlanned']),
  },
  async mounted() {
    await this.checkPlannedStatus();

    if (this.hasPlanned) {
      await this.loadFilters();
    }
  },
  methods: {
    ...mapActions('status', {
      checkPlannedStatus: CHECK_PLANNED_STATUS,
    }),
    async loadVehicles() {
      const { data } = await VehicleService.getSelectOptions();
      this.vehicleOptions = data;
    },
    async loadFilters() {
      try {
        await this.loadVehicles();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

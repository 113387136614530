/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

import 'leaflet-draw/dist/leaflet.draw.css';

export default class LeafletBase {
  zoomRestrictedLayer = null;

  features = [];

  ZOOM_FEATURE_VISBILITY_LEVEL = 9;

  CENTER_BOUNDS = [];

  // eslint-disable-next-line no-unused-vars
  constructor(id = 'leaflet') {
    const mymap = L.map(id, {
      preferCanvas: true,
      drawControl: false,
    });

    mymap.setView([55.977, 11.1338], 7);
    mymap.setMinZoom(7);

    this.CENTER_BOUNDS = mymap.getBounds();
    mymap.setMaxBounds(this.CENTER_BOUNDS);
    this.map = mymap;

    const url = `https://api.mapbox.com/styles/v1/${process.env.VUE_APP_MAPBOX_STYLE}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}`;
    this.vectorLayer = L.tileLayer(url, {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
    });

    this.showVectorLayer = true;
    this.map.addLayer(this.vectorLayer);

    const satelliteUrl = `https://api.mapbox.com/styles/v1/${process.env.VUE_APP_MAPBOX_SATELITTE_KEY}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}`;
    this.satelliteLayer = L.tileLayer(satelliteUrl, {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
    });

    const toggleFunc = () => {
      this.layerToggler();
    };

    this.map.on('zoomend', toggleFunc);
  }

  toggleSatelliteLayer() {
    this.showVectorLayer = !this.showVectorLayer;

    if (this.showVectorLayer) {
      this.map.removeLayer(this.satelliteLayer);
      this.map.addLayer(this.vectorLayer);
    } else {
      this.map.addLayer(this.satelliteLayer);
      this.map.removeLayer(this.vectorLayer);
    }
  }

  focus() {
    this.map.getContainer().focus();
  }

  addFeatures(features, zoom = true) {
    const group = L.featureGroup(features).addTo(this.map);
    if (zoom) {
      this.map.flyToBounds(group.getBounds(), {
        padding: [100, 100],
        duration: 0.75,
      });
    }
    return group;
  }

  /** only show pins on zoom level */
  layerToggler() {
    if (this.zoomRestrictedLayer) {
      if (this.map.getZoom() < this.ZOOM_FEATURE_VISBILITY_LEVEL) {
        this.map.removeLayer(this.zoomRestrictedLayer);
      } else {
        this.map.addLayer(this.zoomRestrictedLayer);
      }
    }
  }

  removeFeatureGroup(fg) {
    this.map.removeLayer(fg);
  }

  zoomToFeatureGroup(feature) {
    this.map.flyToBounds(feature.getBounds(), {
      padding: [100, 100],
      duration: 0.75,
    });
  }

  centerView() {
    this.map.flyToBounds(this.CENTER_BOUNDS, {
      padding: [100, 100],
      duration: 0.75,
    });
  }

  destroy() {
    this.map.off();
    this.map.remove();
  }
}

/*
 * Workaround for 1px lines appearing in some browsers due to fractional transforms
 * and resulting anti-aliasing.
 * https://github.com/Leaflet/Leaflet/issues/3575
 */
// eslint-disable-next-line wrap-iife
const removeLines = () => {
  const originalInitTile = L.GridLayer.prototype._initTile;
  L.GridLayer.include({
    _initTile(tile) {
      originalInitTile.call(this, tile);

      const tileSize = this.getTileSize();

      tile.style.width = `${tileSize.x + 1}px`;
      tile.style.height = `${tileSize.y + 1}px`;
    },
  });
};
removeLines();

export default Object.freeze({
  FREQUENCY_CHANGE: 'FREQUENCY_CHANGE',
  UPDATE: 'UPDATE',
  UPDATE_DUPLICATE: 'UPDATE_DUPLICATE',
  CREATE: 'CREATE',
  CREATE_DUPLICATE: 'CREATE_DUPLICATE',
  DELETE: 'DELETE',
  DELETE_DUPLICATE: 'DELETE_DUPLICATE',
  NO_CHANGES_FOR_CLIENT: 'NO_CHANGES_FOR_CLIENT',
});

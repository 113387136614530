import daLocale from '@/locales/da-DK';

import propertyZoneOptions from '@/tables/combo/property_zone_options';

import planningActorOptions from '@/tables/combo/planning_actor_options';

/* eslint-disable no-underscore-dangle */
class TaskConfig {
  _vehicleOptions = [];

  _plannedWeekNoOptions = [];

  _frequencyOptions = [];

  set vehicleOptions(options) {
    this._vehicleOptions = options;
  }

  get vehicleOptions() {
    return this._vehicleOptions;
  }

  set plannedWeekNoOptions(options) {
    this._plannedWeekNoOptions = options;
  }

  get plannedWeekNoOptions() {
    return this._plannedWeekNoOptions;
  }

  set frequencyOptions(options) {
    this._frequencyOptions = options;
  }

  get frequencyOptions() {
    return this._frequencyOptions;
  }

  asReadOnly() {
    this.columns = this.columns.map((column) => ({
      ...column,
      editor: false,
    }));
  }

  columns = [
    {
      label: 'Adresse',
      field: 'address',
      type: 'string',
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '300px',
    },
    {
      label: 'Tømningsdag',
      field: 'planned_disposal_days',
      type: 'select',
      options: () => [
        { label: 'Mandag', value: 'MONDAY' },
        { label: 'Tirsdag', value: 'TUESDAY' },
        { label: 'Onsdag', value: 'WEDNESDAY' },
        { label: 'Torsdag', value: 'THURSDAY' },
        { label: 'Fredag', value: 'FRIDAY' },
        { label: 'Lørdag', value: 'SATURDAY' },
        { label: 'Søndag', value: 'SUNDAY' },
        { label: 'Ukendt', value: 'UNKNOWN' },
      ],
      renderer: (value) => daLocale.disposalDayList(value),
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '250px',
    },
    {
      label: 'Startuge',
      field: 'planned_week_no',
      type: 'select',
      options: () => this.plannedWeekNoOptions,
      renderer: (value) => daLocale.plannedWeekNo(value),
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '60px',
    },
    {
      label: 'Antal',
      field: 'quantity',
      type: 'number',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '100px',
    },
    {
      label: 'Køretøj',
      field: 'vehicle',
      type: 'select',
      options: () => this.vehicleOptions,
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '150px',
    },
    {
      label: 'Frekvens',
      field: 'frequency',
      options: () => this.frequencyOptions,
      type: 'select',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Ejendomsstatus',
      field: 'property_zone',
      type: 'select',
      options: () => propertyZoneOptions,
      renderer: (value) => daLocale.propertyZone(value),
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: true,
      minWidth: '150px',
    },
    {
      label: 'Materiel',
      field: 'materiel',
      type: 'string',
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '250px',
    },
    {
      label: 'Fraktion',
      field: 'fraction',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '150px',
    },
    {
      label: 'Kun sommer',
      field: 'only_summer',
      type: 'boolean',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Materiel ID',
      field: 'ext_materiel_id',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '150px',
    },
    {
      label: 'Postnr.',
      field: 'zip_code',
      type: 'string',
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: true,
      minWidth: '120px',
    },
    {
      label: 'By',
      field: 'city',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Importeret frekvens',
      field: 'imported_frequency',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Importeret køretøj',
      field: 'imported_vehicle',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Importeret tømmedag',
      field: 'imported_disposal_day',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Importeret startuge',
      field: 'imported_week_no',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Importeret gl. frekvens',
      field: 'imported_transition_period_frequency',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Importeret gl. startuge',
      field: 'imported_transition_period_week_no',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: false,
      minWidth: '200px',
    },
    {
      label: 'Vej',
      field: 'address_road',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Nr',
      field: 'address_number',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '60px',
    },
    {
      label: 'Dør',
      field: 'address_door_letter',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '60px',
    },
    {
      label: 'Containernr',
      field: 'container_no',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Ejendomsnr',
      field: 'property_no',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Ejendomsforhold',
      field: 'property_reservations',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Placering',
      field: 'container_location',
      type: 'string',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Bemærkning',
      field: 'comment',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Planlægger',
      field: 'planning_actor',
      type: 'select',
      renderer: (value) => daLocale.planningActor(value),
      options: () => planningActorOptions,
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '150px',
    },
    {
      label: 'Adresse-link',
      field: 'address_link',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '160px',
    },

    {
      label: 'Vejkode',
      field: 'road_code',
      type: 'string',
      sortable: true,
      editor: true,
      minWidth: '80px',
    },

    {
      label: 'Opdateret',
      field: 'updated_at',
      sortable: false,
      filterable: false,
      editor: false,
      minWidth: '300px',
    },
    {
      label: 'Længdegrad',
      field: 'longitude',
      type: 'string',
      sortable: false,
      filterable: false,
      editor: true,
      minWidth: '160px',
    },
    {
      label: 'Breddegrad',
      field: 'latitude',
      type: 'string',
      sortable: false,
      filterable: false,
      editor: true,
      minWidth: '160px',
    },
  ];
}

export default new TaskConfig();

import CellEditor from '@/components/table/CellEditor.vue';
import TableActionBar from '@/components/table/TableActionBar.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableCheckbox from '@/components/table/TableCheckbox.vue';
import TableScrollHandles from '@/components/table/TableScrollHandles.vue';
import EmptyTableView from '@/components/table/EmptyTableView.vue';
import FilterMenu from '@/components/table/FilterMenu.vue';

import { buildFilterParam } from '@/util/queryBuilder';

const baseTableMixin = {
  components: {
    FilterMenu,
    CellEditor,
    TableScrollHandles,
    TableActionBar,
    TableHeader,
    TableCheckbox,
    EmptyTableView,
  },
  props: {
    hideScrollHelpers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSize: 50,
      pagination: {
        data: [],
        hasNext: false,
        nextLink: '',
        hasPrevious: false,
        previousLink: '',
        total: 0,
      },
      pageSizeOptions: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ],
      activeFilters: [],
      selectedData: [],
      loading: true,
      bulkEditActive: false,
      bulkSelected: false,
      sortField: '',
      sortDir: 'asc', // asc | desc
      editingCell: {
        rowId: null,
        styles: null,
        cell: null,
        currentValue: null,
      },
    };
  },
  created() {
    const savedPageSize = localStorage.getItem('northarc:pageSize');

    if (savedPageSize) {
      this.pageSize = +savedPageSize;
    }
  },
  computed: {
    tableTitle() {
      return `${this.activeFilters.length ? 'Filtrering' : 'Total'} : ${this.pagination.total}`;
    },
    indeterminate() {
      return this.selectedData.length > 0 && this.selectedData.length < this.pagination?.data.length;
    },
    containsItems() {
      if (!Object.keys(this.pagination).length) {
        return false;
      }

      return this.pagination.data.length;
    },
    bulkEditCount() {
      return this.bulkSelected ? this.pagination.total : this.selectedData.length;
    },
  },
  methods: {
    renderValue(data, column) {
      if (column.renderer) {
        return column.renderer(data[column.field]);
      }

      return data[column.field];
    },
    emitHasUpdated() {
      this.$emit('has-updated');
    },
    onCellUpdate({ updatedFields, rowId }) {
      const updatedRow = this.pagination.data.find((x) => x.id === rowId);

      // this will update any other cell that has been a side-effect change.
      // for example altering the address will affect the longitude/latitude values
      Object.keys(updatedFields).forEach((key) => {
        updatedRow[key] = updatedFields[key];
      });

      this.emitHasUpdated();
    },
    onStartCellEdit(cell, currentValue, rowId, event) {
      if (!cell.editor) {
        return;
      }

      this.editingCell = {
        rowId,
        cell,
        currentValue,
        styles: {
          clientHeight: event.srcElement.clientHeight,
          clientWidth: event.srcElement.clientWidth,
          offsetTop: event.srcElement.offsetTop,
          offsetLeft: event.srcElement.offsetLeft,
        },
      };
    },
    async setPageSize(value) {
      this.pageSize = value;
      localStorage.setItem('northarc:pageSize', value);

      this.resetSelection();
      await this.onCursor();
    },
    onSelectedData(isChecked, id) {
      this.bulkSelected = false;
      if (isChecked) {
        this.selectedData.push(id);
      } else {
        const index = this.selectedData.indexOf(id);
        this.selectedData.splice(index, 1);
      }
    },
    onSelectAll(isSelected) {
      this.bulkSelected = false;
      this.selectedData = isSelected ? this.pagination.data.map((p) => p.id) : [];
    },
    getFilterParams(disabledPageSize = false) {
      const params = [];
      if (this.activeFilters.length) {
        const filterParams = this.activeFilters.map((x) => buildFilterParam(x));
        params.push(...filterParams);
      }

      if (this.sortField) {
        const sortParam = this.sortDir === 'asc' ? `ordering=${this.sortField}` : `ordering=-${this.sortField}`;
        params.push(sortParam);
      }

      if (!disabledPageSize) {
        params.push(`page_size=${this.pageSize}`);
      }

      return params;
    },
    resetSelection() {
      this.bulkSelected = false;
      this.selectedData = [];
    },
    async onNext() {
      if (!this.bulkSelected) {
        this.resetSelection();
      }
      await this.onCursor(this.pagination.nextLink, this.bulkSelected);
    },
    async onPrevious() {
      if (!this.bulkSelected) {
        this.resetSelection();
      }
      await this.onCursor(this.pagination.previousLink, this.bulkSelected);
    },
    async clearFilters() {
      this.activeFilters = [];
      await this.onCursor();
    },

    async onSort(column) {
      if (!column.sortable) {
        return;
      }
      this.resetSelection();

      if (column.field === this.sortField && this.sortDir === 'desc') {
        this.sortField = '';
        this.sortDir = '';
      } else if (column.field === this.sortField && this.sortDir === 'asc') {
        this.sortDir = 'desc';
      } else {
        this.sortField = column.field;
        this.sortDir = 'asc';
      }

      await this.onCursor();
    },
    async onCursor(cursor, shouldSelectAllAfterLoad = false, disabledPageSize = false) {
      this.loading = true;
      const params = this.getFilterParams(disabledPageSize);

      try {
        this.selectedData = [];
        const data = await this.loadData(cursor, params);
        this.pagination = data;

        if (shouldSelectAllAfterLoad) {
          this.onSelectAll(true);
          this.bulkSelected = true;
        }
      } catch (error) {
        this.$unhandledError(error);
      }

      this.loading = false;
    },
  },
};

export default baseTableMixin;

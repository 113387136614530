<template>
  <slider v-model="model" width="max-w-lg" title="Geokod manuelt" :close-on-overlay-click="true">
    <div class="my-8 space-y-4">
      <base-input
        v-model="$v.form.address_road.$model"
        :required="true"
        :invalid="invalid($v.form.address_road)"
        :valid="valid($v.form.address_road)"
        type="text"
        label="Vej"
        tabindex="1"
      ></base-input>

      <base-input
        v-model="$v.form.address_number.$model"
        :invalid="invalid($v.form.address_number)"
        :required="true"
        :valid="valid($v.form.address_number)"
        type="text"
        label="Nr"
        tabindex="2"
      ></base-input>

      <base-input
        v-model="$v.form.address_door_letter.$model"
        :invalid="invalid($v.form.address_door_letter)"
        :valid="valid($v.form.address_door_letter)"
        type="text"
        label="Dør"
        tabindex="3"
      ></base-input>

      <base-input
        v-model="$v.form.zip_code.$model"
        :invalid="invalid($v.form.zip_code)"
        :required="true"
        :valid="valid($v.form.zip_code)"
        type="text"
        label="Postnr"
        tabindex="4"
      ></base-input>

      <base-input
        v-model="$v.form.city.$model"
        :invalid="invalid($v.form.city)"
        :valid="valid($v.form.city)"
        :required="true"
        type="text"
        label="By"
        tabindex="5"
      ></base-input>

      <base-input
        v-model="$v.form.latitude.$model"
        :invalid="invalid($v.form.latitude)"
        :valid="valid($v.form.latitude)"
        error="Feltet skal indeholde en dansk breddegrad"
        type="text"
        :required="true"
        label="Breddegrad"
        tabindex="6"
      ></base-input>

      <base-input
        v-model="$v.form.longitude.$model"
        :invalid="invalid($v.form.longitude)"
        :valid="valid($v.form.longitude)"
        error="Feltet skal indeholde en dansk længdegrad"
        type="text"
        :required="true"
        label="Længdegrad"
        tabindex="7"
      ></base-input>

      <div class="flex justify-end mt-6 space-x-2">
        <base-button
          tabindex="8"
          color="red"
          :inverted="true"
          @click="() => $emit('onDeleteRequest', task)"
        >Slet</base-button>
        <base-button
          tabindex="9"
          color="indigo"
          :disabled="$v.form.$invalid"
          @click="submit"
        >Sæt koordinater</base-button>
      </div>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';

import vuelidateMixin from '@/mixins/vuelidateMixin';
import { required } from 'vuelidate/lib/validators';

import { mustBeLatitude, mustBeLongitude } from '@/util/validators';

export default {
  name: 'ManualGeocodeSlider',
  components: {
    Slider,
  },
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
    task: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        id: '',
        latitude: '',
        longitude: '',
        address_road: '',
        address_number: '',
        address_door_letter: '',
        zip_code: '',
        city: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  watch: {
    task: {
      immediate: true,
      handler: 'taskChanged',
    },
  },
  methods: {
    taskChanged() {
      if (!this.task) {
        this.resetForm();
        return;
      }

      this.form = {
        id: this.task.id,
        address: this.task.address,
        latitude: this.task.latitude,
        longitude: this.task.longitude,
        address_road: this.task.address_road,
        address_number: this.task.address_number,
        address_door_letter: this.task.address_door_letter,
        zip_code: this.task.zip_code,
        city: this.task.city,
      };
    },
    resetForm() {
      this.form = {
        id: '',
        address: '',
        latitude: '',
        longitude: '',
        address_road: '',
        address_number: '',
        address_door_letter: '',
        zip_code: '',
        city: '',
      };
    },
    async submit() {
      this.$emit('update', this.form);
    },
  },
  validations() {
    return {
      form: {
        latitude: { required, mustBeLatitude },
        longitude: { required, mustBeLongitude },
        address_road: { required },
        address_number: { required },
        address_door_letter: {},
        zip_code: { required },
        city: { required },
      },
    };
  },
};
</script>

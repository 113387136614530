<template>
  <div class="pb-4">
    <template v-if="loading">
      <div
        v-loading="loading"
        class="flex flex-col items-center justify-center mt-4"
        style="min-height: 200px"
      ></div>

      <h3
        class="my-6 text-sm text-center font-medium text-gray-900"
      >Henter opgaver som ikke er geokodede ..</h3>
    </template>

    <image-container
      v-else-if="isDone"
      asset-name="adventure"
      title="Alt data er geokodet!"
      class="mt-4"
    ></image-container>

    <div v-show="!loading && !isDone" v-loading="isSubmitting">
      <base-alert
        title="Service advarsel"
        description="Vi anbefaler først at forsøge med Dawa, da google går over til at være en betalt løsning per forespørgsel, når den gratis kvote er opbrugt."
        type="warning"
        class="mt-4"
      ></base-alert>

      <base-alert
        v-if="hasUnsubmittedChanges"
        title="Du har ændringer som ikke er gemt"
        type="warning"
        class="mt-4"
      ></base-alert>

      <div class="flex justify-between items-center">
        <span class="text-gray-800">
          <span class="text-xl text-indigo-500 font-semibold">{{tasks.length}}</span> opgaver mangler koordinater
        </span>

        <div class="flex justify-end">
          <base-button
            :outline="true"
            color="indigo"
            class="my-4"
            @click="$emit('changeView', views.OVERVIEW)"
          >Tilbage til metodevalg</base-button>
          <base-button
            color="indigo"
            :disabled="!hasUnsubmittedChanges"
            class="my-4 ml-4"
            @click="submit"
          >Gem alle ændringer</base-button>
        </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="tasks"
        style-class="vgt-table striped custom-vgt-table"
        @on-row-click="onRowClick"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'dawa'">
            <base-button
              :inverted="true"
              color="indigo"
              @click="openDawa(props.row)"
              @click.stop
            >DAWA</base-button>
          </span>
          <span v-else-if="props.column.field == 'google'">
            <base-button
              :inverted="true"
              color="indigo"
              @click="openGoogle(props.row)"
              @click.stop
            >Google</base-button>
          </span>
          <span v-else>{{props.formattedRow[props.column.field]}}</span>
        </template>
      </vue-good-table>

      <div class="flex justify-end mt-4">
        <base-button
          color="indigo"
          :disabled="!hasUnsubmittedChanges"
          @click="submit"
        >Gem alle ændringer</base-button>
      </div>

      <manual-geocode-slider-dawa
        v-model="showDAWASlider"
        :row="selectedRow"
        @onCoordinate="onManualCoordinate"
      ></manual-geocode-slider-dawa>

      <manual-geocode-slider-google
        v-model="showGoogleSlider"
        :row="selectedRow"
        @onCoordinate="onManualCoordinate"
      ></manual-geocode-slider-google>

      <manual-geocode-slider
        v-model="showManualSlider"
        :task="selectedRow"
        @update="onManualUpdate"
        @onDeleteRequest="onRemoveRow"
      ></manual-geocode-slider>
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

import { mapActions, mapMutations } from 'vuex';

import { GET_TASKS_NOT_GEOCODED, SUBMIT_MANUALLY_GEOCODED_TASKS, DELETE_TASK } from '@/store/actions.type';
import { SET_HAS_NON_GEOCODED } from '@/store/mutations.type';

import ImageContainer from '@/components/ImageContainer.vue';
import ManualGeocodeSliderDawa from '@/components/ManualGeocodeSliderDawa.vue';
import ManualGeocodeSliderGoogle from '@/components/ManualGeocodeSliderGoogle.vue';
import ManualGeocodeSlider from '@/components/ManualGeocodeSlider.vue';

import columns from '@/components/table/configs/geocodeManual';

import views from '@/util/geocodeViews';

export default {
  name: 'TheGeocodeManual',
  components: {
    ImageContainer,
    ManualGeocodeSliderDawa,
    ManualGeocodeSliderGoogle,
    ManualGeocodeSlider,
    VueGoodTable,
  },
  data() {
    return {
      unsubmittedChanges: new Map(),
      loading: true,
      isSubmitting: false,
      selectedRow: null,
      tasks: [],
      showDAWASlider: false,
      showGoogleSlider: false,
      showManualSlider: false,
      views,
      columns,
    };
  },
  computed: {
    isDone() {
      return !this.loading && !this.tasks.length;
    },
    hasUnsubmittedChanges() {
      return this.unsubmittedChanges.size > 0;
    },
  },
  async mounted() {
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions('tasks', {
      getTasksNotGeocoded: GET_TASKS_NOT_GEOCODED,
      submitTasks: SUBMIT_MANUALLY_GEOCODED_TASKS,
      deleteTask: DELETE_TASK,
    }),
    ...mapMutations('status', {
      setHasNonGeocoded: SET_HAS_NON_GEOCODED,
    }),
    onRowClick(params) {
      this.selectedRow = params.row;
      this.showManualSlider = true;
    },
    onManualCoordinate({ coordinates, id }) {
      const task = this.tasks.find((t) => t.id === id);

      task.latitude = coordinates[1].toString().replace('.', ',');
      task.longitude = coordinates[0].toString().replace('.', ',');

      this.selectedRow = null;
      this.showDAWASlider = false;
      this.showGoogleSlider = false;
      this.showManualSlider = false;

      this.unsubmittedChanges = new Map(
        this.unsubmittedChanges.set(id, {
          x: task.latitude,
          y: task.longitude,
          address: task.address,
        }),
      );
    },
    onManualUpdate(form) {
      const task = this.tasks.find((t) => t.id === form.id);

      task.longitude = form.longitude.replace('.', ',');
      task.latitude = form.latitude.replace('.', ',');

      task.address_road = form.address_road;
      task.address_number = form.address_number;
      task.address_door_letter = form.address_door_letter;
      task.zip_code = form.zip_code;
      task.city = form.city;

      this.selectedRow = null;
      this.showManualSlider = false;

      this.unsubmittedChanges = new Map(
        this.unsubmittedChanges.set(form.id, {
          x: task.latitude,
          y: task.longitude,
          address_road: form.address_road,
          address_number: form.address_number,
          address_door_letter: form.address_door_letter,
          zip_code: form.zip_code,
          city: form.city,
        }),
      );
    },
    openDawa(row) {
      this.selectedRow = row;
      this.showDAWASlider = true;
    },
    openGoogle(row) {
      this.selectedRow = row;
      this.showGoogleSlider = true;
    },
    async fetch() {
      this.$ntf.info('Henter opgaver som ikke er geokodede..');
      try {
        this.tasks = await this.getTasksNotGeocoded();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async submit() {
      this.isSubmitting = true;
      this.$ntf.info('Gemmer ændringer');
      this.selectedRow = null;

      if (!this.unsubmittedChanges.size) {
        this.$ntf.info('Ingen ændringer');
        return;
      }
      try {
        const payload = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of this.unsubmittedChanges) {
          payload[key] = value;
        }

        await this.submitTasks(payload);
        this.$ntf.success('Ændringerne blev gemt succesfuldt');

        this.tasks = this.tasks.filter((task) => !this.unsubmittedChanges.has(task.id));

        if (!this.tasks.length) {
          this.setHasNonGeocoded(false);
        }
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.isSubmitting = false;
      }

      this.unsubmittedChanges = new Map();
    },
    async onRemoveRow(row) {
      try {
        await this.$confirm({
          title: 'Slet opgave',
          bodyText: 'Vil du opgaven?',
        });
        await this.deleteConfirmed(row);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async deleteConfirmed(row) {
      try {
        await this.deleteTask(row.id);

        this.tasks = this.tasks.filter((t) => t.id !== row.id);
        this.$ntf.success('Opgaven blev slettet');
      } catch (err) {
        this.$unhandledError(err);
      }
    },
  },
};
</script>

<template>
  <div v-loading="loading" class="pb-8 pt-8" style="minHeight: 300px'">
    <div v-if="newFrequenciesToMap.length" class="grid-cols-1">
      <div class="flex font-semibold text-gray-800 mb-2">
        <p class="flex-1 pl-6">Tekstværdi i datasæt</p>
        <p class="flex-1 pl-14">Oversat værdi i NorthArc</p>
        <p class="pr-14">Kun sommer?</p>
      </div>

      <ul>
        <li v-for="freq in newFrequenciesToMap" :key="freq">
          <mapping-line
            :options="frequencies"
            :title="freq"
            :value="frequenciesMapped[freq]"
            :use-value="true"
            placeholder="Vælg frekvens"
            @selected="onMapping"
          >
            <p v-if="!freq" slot="title" class="text-blue-700">Tomme frekvenser i datasættet</p>

            <div slot="extra" class="px-6 py-4 pr-14 flex items-center text-sm font-medium">
              <span class="ml-4 text-sm font-medium text-indigo-600">
                <base-checkbox
                  :value="onlySummerMapped[freq]"
                  @input="(evt) => onSummerChange({ value: evt, label: freq })"
                ></base-checkbox>
              </span>
            </div>
          </mapping-line>
        </li>
      </ul>

      <div class="flex justify-end mt-4">
        <div class="mt-4 flex justify-end">
          <base-button
            color="indigo"
            :disabled="submitDisabled|| loading"
            @click="nextClicked"
          >Kortlæg og generer plan</base-button>
        </div>
      </div>

      <dl
        v-if="previouslyMapped.length"
        class="sm:divide-y sm:divide-gray-200 rounded-md pb-8 grid-cols-1"
      >
        <h5 class="sub-headline mb-4">Kortlagte frekvenser i dit workspace</h5>

        <key-value-pair
          value="Oversat værdi i NorthArc"
          label="Tekstværdi i datasæt"
          :show-badge="false"
        ></key-value-pair>
        <key-value-pair
          v-for="pair in previouslyMapped"
          :key="pair.label"
          :value="`${pair.value} ${pair.onlySummer ? '(kun summer)' : ''}`"
          :label="pair.label"
        ></key-value-pair>
      </dl>
    </div>
  </div>
</template>

<script>
import FrequencyService from '@/services/frequencies.service';
import TaskService from '@/services/tasks.service';

import MappingLine from '@/components/MappingLine.vue';
import KeyValuePair from '@/components/KeyValuePair.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';
import mergeFlowSteps from '@/util/mergeFlowSteps';

import { GET_NEW_FREQUENCIES_TO_MAP, GET_MOST_IRREGULAR_FREQUENCY } from '@/store/actions.type';
import { SET_MERGE_NEW_FREQ_MAPPINGS, ADD_COMPLETED_MERGE_FLOW_STEP } from '@/store/mutations.type';

export default {
  name: 'MergeFlowFrequencies',
  components: {
    MappingLine,
    KeyValuePair,
  },
  data() {
    return {
      frequencies: [],
      frequenciesToMap: [],
      frequenciesMapped: {},
      onlySummerMapped: {},
      loading: false,
      previouslyMapped: [],
    };
  },
  computed: {
    ...mapGetters('import', ['newFrequenciesToMap', 'completedSteps']),
    submitDisabled() {
      return this.newFrequenciesToMap.length !== Object.keys(this.frequenciesMapped).length;
    },
  },
  async mounted() {
    this.loading = true;

    const hasCompletedPreviousStep = this.completedSteps.includes(mergeFlowSteps.IMPORT);
    if (!hasCompletedPreviousStep) {
      this.$router.push({ name: 'task-import-overview' });
      return;
    }

    try {
      this.$ntf.info('Tjekker om der skal kortlægges nye frekvenser');

      await this.getNewFrequenciesToMap();
      await this.fetchFrequencies();
      await this.fetchPreviouslyMapped();

      const nothingToMap = !this.newFrequenciesToMap.length;
      if (nothingToMap) {
        this.$ntf.success('Ingen nye frekvenser');
        this.nextClicked();
      }
    } catch (error) {
      this.$unhandledError(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('import', {
      getNewFrequenciesToMap: GET_NEW_FREQUENCIES_TO_MAP,
    }),
    ...mapMutations('import', {
      setMappedFrequencies: SET_MERGE_NEW_FREQ_MAPPINGS,
      addCompletedMergeFlowStep: ADD_COMPLETED_MERGE_FLOW_STEP,
    }),
    ...mapActions('frequencies', {
      getMostIrregularFrequency: GET_MOST_IRREGULAR_FREQUENCY,
    }),
    async fetchPreviouslyMapped() {
      try {
        const { data } = await TaskService.getPreviouslyMappedFrequencies();

        if (data.previouslyMappedFrequencies) {
          Object.keys(data.previouslyMappedFrequencies).forEach((key) => {
            this.previouslyMapped.push({
              label: key === 'null' ? 'Tomme frekvenser i datasættet' : key,
              value: this.frequencies.find((freq) => freq.value === data.previouslyMappedFrequencies[key]).label,
              onlySummer: data.previouslyMappedSummer[key],
            });
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchFrequencies() {
      try {
        const { data } = await FrequencyService.getSelectOptions();
        this.frequencies = data;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onMapping({ label, value }) {
      delete this.frequenciesMapped[label];

      if (value) {
        this.frequenciesMapped[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.frequenciesMapped = { ...this.frequenciesMapped };
    },
    onSummerChange({ value, label }) {
      delete this.onlySummerMapped[label];

      this.onlySummerMapped[label] = value;

      this.onlySummerMapped = { ...this.onlySummerMapped };
    },
    nextClicked() {
      this.setMappedFrequencies({ frequency_mappings: this.frequenciesMapped, summer_mappings: this.onlySummerMapped });
      this.addCompletedMergeFlowStep(mergeFlowSteps.MAP_FREQUENCIES);
      this.$router.push({ name: 'task-import-merge-plan' });
    },
  },
};
</script>

<style>

</style>

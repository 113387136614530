import ApiService from '@/common/api.service';

const WasteFractionService = {
  getAll() {
    const resource = 'waste-fractions/';
    return ApiService.get(resource);
  },

  assignDisposalCategories(payload) {
    const resource = 'waste-fractions/dispoal-categories';
    return ApiService.post(resource, payload);
  },
};

export default WasteFractionService;

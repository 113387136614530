<template>
  <nav
    class="z-40 bg-white text-sm font-medium shadow-sm border-b text-gray-900 py-5 ring-1 ring-gray-900 ring-opacity-5"
    aria-label="Tabs"
  >
    <ul
      class="mx-auto px-4 sm:px-6 lg:px-8 flex space-x-5 sm:space-x-10 lg:space-x-14 transition-all"
      :class="{ 'desktop-container': !isFullWidth}"
    >
      <slot />

      <slot name="extra" />
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'SecondaryNavBar',
  computed: {
    isFullWidth() {
      return this.$route.matched.some((x) => x.meta.fullNav);
    },
  },
};
</script>

<style scoped>
.active-nav-link {
  @apply text-indigo-500;
}
</style>

<template>
  <secondary-nav-bar-content title="Geokodning">
    <p slot="description" class="text-gray-500 mt-1.5" style="max-width: 800px">
      Her kan du geokode datasættet. Dette er en forudsætning for senere at kunne planlægge opgaverne.
      <br />
    </p>

    <div class="h-full w-full">
      <the-geocode-overview v-if="activeView === views.OVERVIEW" @changeView="changeView"></the-geocode-overview>
      <the-geocode-automatic v-else-if="activeView === views.AUTOMATIC" @changeView="changeView"></the-geocode-automatic>
      <the-geocode-manual v-else-if="activeView === views.MANUAL" @changeView="changeView"></the-geocode-manual>
    </div>
  </secondary-nav-bar-content>
</template>

<script>
import TheGeocodeOverview from '@/components/geocode/TheGeocodeOverview.vue';
import TheGeocodeAutomatic from '@/components/geocode/TheGeocodeAutomatic.vue';
import TheGeocodeManual from '@/components/geocode/TheGeocodeManual.vue';
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';

import views from '@/util/geocodeViews';

import { mapActions, mapGetters } from 'vuex';

import { CHECK_GEOCODE_STATUS } from '@/store/actions.type';

export default {
  name: 'ConfigurationGeocodeView',
  components: {
    TheGeocodeOverview,
    TheGeocodeAutomatic,
    TheGeocodeManual,
    SecondaryNavBarContent,
  },
  data() {
    return {
      views,
      activeView: views.OVERVIEW,
    };
  },
  computed: {
    ...mapGetters('jobs', ['geoCodingJobId']),
  },
  watch: {
    geoCodingJobId: {
      immediate: true,
      handler: 'routeToAutomaticIfActiveJob',
    },
  },
  beforeDestroy() {
    this.checkGeocodeStatus();
  },
  methods: {
    changeView(view) {
      this.activeView = view;
    },
    ...mapActions('status', {
      checkGeocodeStatus: CHECK_GEOCODE_STATUS,
    }),
    routeToAutomaticIfActiveJob(val) {
      if (val) {
        this.activeView = views.AUTOMATIC;
      }
    },
  },
};
</script>

<template>
  <slider v-model="model" width="max-w-lg" title="Ret materiel" :close-on-overlay-click="true">
    <div class="my-8">
      <base-input
        v-model="form.display_name"
        type="text"
        class="mt-4"
        label="Visningsnavn i NorthArc"
        :required="true"
        tabindex="1"
      ></base-input>

      <base-input
        v-model="form.abbreviation"
        class="mt-4"
        type="text"
        label="Forkortelse i NorthArc"
        :required="true"
        tabindex="1"
      ></base-input>

      <div class="flex justify-end mt-6">
        <base-button tabindex="11" color="indigo" :disabled="$v.form.$invalid" @click="submit">Ret</base-button>
      </div>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

import MaterielService from '@/services/materiel.service';

export default {
  name: 'MaterielEditSlider',
  components: {
    Slider,
  },
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
    materiel: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        id: '',
        display_name: '',
        abbreviation: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  watch: {
    materiel: {
      immediate: true,
      handler: 'materielChanged',
    },
  },
  methods: {
    materielChanged() {
      if (!this.materiel) {
        this.resetForm();
        return;
      }

      this.form = {
        id: this.materiel.id,
        display_name: this.materiel.display_name,
        abbreviation: this.materiel.abbreviation,
      };
    },
    resetForm() {
      this.form = {
        id: '',
        display_name: '',
        abbreviation: '',
      };
    },
    async submit() {
      try {
        await MaterielService.update(this.form);
        this.$ntf.success('Materiel blev rettet');
        this.resetForm();
        this.$emit('editted');
      } catch (error) {
        this.$ntf.error(error?.data?.error);
      }
    },
  },
  validations() {
    return {
      form: {
        display_name: { required },
        abbreviation: { required },
      },
    };
  },
};
</script>

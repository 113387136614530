<template>
  <ol
    class="border border-gray-200 flex divide-y-0 bg-white"
    :class="{
      'rounded-t-md': isFirst,
      'rounded-b-md': isLast,
    }"
  >
    <li class="relative flex-1 flex">
      <div class="group flex items-center w-full">
        <span class="px-6 py-4 flex items-center text-sm font-medium">
          <span
            class="flex-shrink-0 w-10 h-10 flex items-center justify-center border bg-gray-100 rounded-md"
          >
            <span>{{column}}</span>
          </span>

          <div class="flex flex-col ml-4">
            <span class="text-sm block font-medium text-gray-900">{{title}}</span>

            <p class="text-gray-600 font-normal">{{description}}</p>

            <p v-if="helperText" class="text-blue-900 font-normal">{{helperText}}</p>
          </div>
        </span>
      </div>

      <!-- Arrow separator for lg screens and up -->
      <div class="block absolute top-0 right-0 h-full w-5" aria-hidden="true">
        <svg
          class="h-full w-full text-gray-300"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vector-effect="non-scaling-stroke"
            stroke="currentcolor"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </li>

    <li class="relative flex-1 flex">
      <div
        class="px-6 py-4 flex items-center justify-between flex-1 text-sm font-medium"
        aria-current="step"
      >
        <slot>
          <span class="ml-4 text-sm font-medium text-indigo-600">
            <base-select
              v-model="model"
              :options="options"
              placeholder="Vælg kolonne"
              :clearable="true"
            ></base-select>
          </span>
          <span v-if="isNew">
            <base-badge color="green">Nyt felt</base-badge>
          </span>
          <span v-if="required" class="text-red-600 font-semibold">* (påkrævet)</span>
          <span v-if="showInfo" class="text-blue-900 font-semibold">(vigtig)</span>
        </slot>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'ImportMapping',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
    },
    showInfo: {
      type: Boolean,
    },
    isFirst: {
      type: Boolean,
    },
    isLast: {
      type: Boolean,
    },
    isNew: {
      type: Boolean,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('mapped', val);
      },
    },
  },
};
</script>

<template>
  <secondary-nav-bar-content title="Import">
    <div slot="description" class="text-gray-500 mt-1.5" style="max-width: 660px">
      <p>NorthArc har brug for forskellige typer oplysninger fra dig. Fortæl os, hvor vi finder oplysningerne i din CSV-fil ved at vælge de rigtige kolonner i dropdown-menuerne. Du skal starte med at vælge en CSV-fil fra din computer.</p>
    </div>
    <div slot="details" class="space-y-4 my-2">
      <p>Efter du har valgt en CSV-fil, kan du se forskellige typer af oplysninger, vi har brug for. Det er blandt andet “Vej” og “Materiel”.</p>

      <p>Ud for hver type oplysning er der en dropdown-menu. I dropdown-menuen kan du se navnene på alle kolonnerne i din CSV-fil.</p>

      <p>Vælg navnet på den kolonne, hvor NorthArc skal hente den pågældende type oplysning.</p>

      <p>Ud for “Vej” kan du for eksempel fortælle NorthArc, at din kolonne med vejoplysninger hedder “Vejnavne”.</p>

      <p>Tryk på “Gem opsætning”, hvis du vil gemme opsætningen. Det betyder, at NorthArc husker, hvad du kalder de forskellige typer af oplysninger i dine CSV-filer.</p>

      <p>På den måde kan du importere nye CSV-filer med oplysninger om andre kommuner eller lignende, uden at skulle lave en opsætning igen.</p>
    </div>
    <router-view></router-view>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';

export default {
  name: 'ImportOverView',
  components: {
    SecondaryNavBarContent,
  },
};
</script>

import daLocale from '@/locales/da-DK';

// vue-good-table
export default [
  {
    label: 'Navn',
    field: 'name',
    type: 'string',
  },
  {
    label: 'Man',
    field: 'unloads_mon',
    type: 'number',
  },
  {
    label: 'Tir',
    field: 'unloads_tue',
    type: 'number',
  },
  {
    label: 'Ons',
    field: 'unloads_wed',
    type: 'number',
  },
  {
    label: 'Tor',
    field: 'unloads_thu',
    type: 'number',
  },
  {
    label: 'Fre',
    field: 'unloads_fri',
    type: 'number',
  },
  {
    label: 'Lør',
    field: 'unloads_sat',
    type: 'number',
  },
  {
    label: 'Søn',
    field: 'unloads_sun',
    type: 'number',
  },
  {
    label: 'Aktiv?',
    field: (rowObj) => (rowObj.is_active_for_planning ? 'Ja' : 'Nej'),
    type: 'boolean',
  },
  {
    label: 'Start lokation',
    field: 'address',
    type: 'string',
  },
  {
    label: 'Aflæsningslokation',
    field: 'disposal_point',
    type: 'string',
  },
  {
    label: 'Gl. navn',
    field: 'old_name_reference',
    type: 'string',
  },
  {
    label: 'Ejendomsstatus',
    field: (rowObj) => daLocale.propertyZone(rowObj.property_zone),
    type: 'string',
  },
];

<template>
  <div class="flex">
    <div class="w-96 flex-shrink-0 bg-white screen-vh-minus-double-nav flex flex-col">
      <planning-area
        v-if="hasPlanned"
        :vehicle-options="vehicleOptions"
        :frequency-options="frequencyOptions"
        :is-shared-view="false"
        @filter="$emit('filter', $event)"
      ></planning-area>
      <image-container v-else asset-name="empty" title="Du har endnu ikke planlagt">
        <router-link :to="{name: 'plan'}" class="mt-6">
          <base-button color="indigo">
            Gå til rutebygning
            <span class="ml-2">&rarr;</span>
          </base-button>
        </router-link>
      </image-container>
    </div>
  </div>
</template>

<script>
import PlanningArea from '@/components/PlanningArea.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import { mapActions, mapGetters } from 'vuex';
import { CHECK_PLANNED_STATUS, GET_SHARED_WORKSPACE_LINK } from '@/store/actions.type';

import VehicleService from '@/services/vehicles.service';
import FrequencyService from '@/services/frequencies.service';

export default {
  name: 'ThePlanningSidebar',
  components: {
    PlanningArea,
    ImageContainer,
  },
  data() {
    return {
      vehicleOptions: [],
      frequencyOptions: [],
    };
  },
  computed: {
    ...mapGetters('status', ['hasPlanned']),
  },
  async mounted() {
    await this.checkPlannedStatus();

    const link = await this.getSharedWorkspaceLink();
    if (link) {
      document.querySelector('.share-control').classList.add('warning');
    }

    if (this.hasPlanned) {
      await this.loadFilters();
    }
  },
  methods: {
    ...mapActions('status', {
      checkPlannedStatus: CHECK_PLANNED_STATUS,
    }),
    ...mapActions('departments', {
      getSharedWorkspaceLink: GET_SHARED_WORKSPACE_LINK,
    }),
    async loadVehicles() {
      const { data } = await VehicleService.getSelectOptions();
      this.vehicleOptions = data;
    },
    async loadFrequencies() {
      const { data } = await FrequencyService.getFrequenciesInUseAsSelectOptions();
      this.frequencyOptions = data;
    },
    async loadFilters() {
      try {
        await this.loadVehicles();
        await this.loadFrequencies();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

<template>
  <image-container asset-name="empty" :title="title">
    <router-link :to="{name: isLightWorkspace ? 'task-import-extra' : 'task-import'}" class="mt-6">
      <base-button v-if="!isInTransitionPeriod" color="indigo">
        Gå til import
        <span class="ml-2">&rarr;</span>
      </base-button>
    </router-link>
  </image-container>
</template>

<script>
import ImageContainer from '@/components/ImageContainer.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'NoTasksView',
  components: {
    ImageContainer,
  },
  computed: {
    ...mapGetters('departments', ['isInTransitionPeriod', 'isLightWorkspace']),
    title() {
      return this.isInTransitionPeriod ? 'Det ser ikke ud til at dit workspace kræver nogle mellemtømninger' : 'Du har endnu ikke nogle opgaver';
    },
  },
};
</script>

<style>

</style>

<template>
  <div class="z-max">
    <base-modal
      v-model="model"
      :width="680"
      :is-full-screen="false"
      :close-on-click-outside="true"
      title="Genvejstaster til kort"
      @closed="$emit('closed')"
    >
      <div>
        <p class="text-gray-800 border-b pb-4">
          For at aktivere genvejstasterne skal kortet være i fokus.
          <br />Kortet fokuseres som regel automatisk og ellers kan det gøres ved at klikke et vilkårligt sted på kortet.
        </p>
        <div class="space-y-4 mt-4">
          <div class="list-container">
            <div class="w-8 list-icon">N</div>
            <div class="list-description">Nyt polygon</div>
          </div>

          <div class="list-container">
            <div class="w-20 list-icon">Delete</div>
            <div class="list-description">Slet det markerede polygon</div>
          </div>

          <div v-if="!isSharedView" class="list-container">
            <div class="w-16 list-icon">Enter</div>
            <div class="list-description">Åben opgave-tabel for det markerede polygon</div>
          </div>

          <div v-if="!isSharedView" class="list-container">
            <div class="w-24 list-icon">Ctrl + Enter</div>
            <div class="list-description">Åben opgave-tabel for alle polygoner</div>
          </div>

          <div v-if="!isSharedView" class="list-container">
            <div class="w-12 list-icon">Esc</div>
            <div class="list-description">Luk opgave-tabel</div>
          </div>

          <div class="list-container">
            <div class="w-12 list-icon">
              <icon-cursor class="h-4 w-4"></icon-cursor>
            </div>
            <div
              class="list-description"
            >{{isSharedView ? "Tryk på polygon for at se mere" : "Tryk på polygon for at rette"}}</div>
          </div>

          <div class="list-container">
            <div class="w-12 list-icon">
              <icon-cursor class="h-4 w-4"></icon-cursor>
              <span class="text-xs font-bold ml-0.5">x2</span>
            </div>
            <div class="list-description">Dobbeltklik for auto-slutning af påbegyndt polygon</div>
          </div>

          <div class="list-container">
            <div class="w-8 list-icon">i</div>
            <div class="list-description">Åben denne oversigt over genvejstaster</div>
          </div>

          <div class="list-container">
            <div class="w-12 list-icon">
              <icon-trash class="h-4 w-4"></icon-trash>
            </div>
            <div
              class="list-description"
            >Tryk på skraldespanden i venstre side af kortet og derefter 'Clear All' for at slette flere polygoner</div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
export default {
  name: 'ThePlanningInfoModal',
  props: {
    value: {
      type: Boolean,
    },
    isSharedView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
};
</script>

<style>

.list-container {
  @apply grid grid-cols-4 items-center;
}

.list-icon {
  @apply h-8 px-1.5 bg-gray-300 rounded-md flex items-center justify-center col-span-1;
}

.list-description {
  @apply col-span-3 text-gray-800;
}

</style>

<template>
  <div class="absolute top-4 right-4 inline-flex bg-white px-2 py-1 text-sm text-gray-700 border border-gray-400">
    <div class="flex items-center space-x-2">
      <span class="h-3 w-3 rounded-full" :class="count > 0 ? 'bg-yellow-400' : 'bg-green-400'"></span>
      <span>{{ count > 0 ? `${count} opgaver mangler geokodning` : 'Alle opgaver er geokodet' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeocodeStatusBox',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <h3 class="flow-root sticky top-0 bg-gray-100 z-10">
      <div
        class="py-4 w-full flex items-center justify-between cursor-pointer text-gray-400 hover:text-gray-500 px-4 z-10"
        @click="open = !open"
      >
        <div class="flex items-center w-full">
          <base-checkbox v-model="checkAll" @click.stop></base-checkbox>
          <span class="font-medium text-gray-900 flex items-center justify-between w-full ml-2">
            <span>{{label}}</span>
            <span
              v-if="value.length"
              class="bg-gray-300 text-xs inline-flex justify-center items-center text-gray-700 rounded-md h-5 w-5 ml-1.5"
            >{{value.length}}</span>
          </span>
        </div>
        <button type="button" class="ml-4 flex items-center">
          <icon-plus v-if="!open" class="h-5 w-5"></icon-plus>
          <icon-minus v-else class="h-5 w-5"></icon-minus>
        </button>
      </div>
    </h3>

    <div v-if="open">
      <div class="flex px-4">
        <ul v-if="allOddWeeks.length > 0" class="w-1/2">
          <div class="flex items-center py-2">
            <base-checkbox v-model="checkOddAll"></base-checkbox>
            <span class="font-medium text-indigo-600 underline ml-2">Ulige</span>
          </div>
          <li
            v-for="option in allOddWeeks"
            :key="option.value"
            class="flex relative items-center py-2"
            @click.stop="toggleValue(option.value)"
          >
            <div>
              <base-checkbox
                :value="value.includes(option.value)"
                @input="toggleValue(option.value)"
              ></base-checkbox>
            </div>
            <span class="text-sm text-gray-500 pl-2">{{option.label}}</span>
          </li>
        </ul>

        <ul v-if="allEvenWeeks.length > 0" class="w-1/2">
          <div class="flex items-center py-2">
            <base-checkbox v-model="checkEvenAll"></base-checkbox>
            <span class="font-medium text-indigo-600 underline ml-2">Lige</span>
          </div>
          <li
            v-for="option in allEvenWeeks"
            :key="option.value"
            class="flex relative items-center py-2"
            @click.stop="toggleValue(option.value)"
          >
            <div class>
              <base-checkbox
                :value="value.includes(option.value)"
                @input="toggleValue(option.value)"
              ></base-checkbox>
            </div>
            <span class="text-sm text-gray-500 pl-2">{{option.label}}</span>
          </li>
        </ul>
      </div>

      <div
        class="py-2 px-2 cursor-pointer focus:outline-none"
        @click.stop="toggleValue(unplannedWeek)"
      >
        <div
          class="flex relative items-center border-t border-indigo-50 rounded-md pt-4 pb-2 px-2 mr-2"
        >
          <base-checkbox :value="value.includes(unplannedWeek)" @input="toggleValue(unplannedWeek)"></base-checkbox>

          <span class="text-sm text-indigo-900 pl-2">Ukendt</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanningFilterBoxPlannedWeeks',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
      description: 'List of selected values',
    },
    label: {
      type: String,
      default: '',
    },
    showAdvancedFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: true,
      unplannedWeek: -1,
    };
  },
  computed: {
    checkAll: {
      get() {
        // + 1 for 'Unplanned week'
        return this.value.length === this.options.length + 1;
      },
      set(isChecked) {
        const values = isChecked ? [-1, ...this.options.map((x) => x.value)] : [];
        this.$emit('input', values);
      },
    },
    checkOddAll: {
      get() {
        return this.allOddWeeks.every((x) => this.value.includes(x.value));
      },
      set(isChecked) {
        // remove existing odd weeks -> add all odd weeks
        const onlyEvens = this.value.filter((x) => this.isEven(x));
        const values = isChecked ? [...onlyEvens, ...this.allOddWeeks.map((x) => x.value)] : [...onlyEvens];
        this.$emit('input', values);
      },
    },
    checkEvenAll: {
      get() {
        return this.allEvenWeeks.every((x) => this.value.includes(x.value));
      },
      set(isChecked) {
        // remove existing even weeks -> add all even weeks
        const onlyOdds = this.value.filter((x) => this.isOdd(x));
        const values = isChecked ? [...onlyOdds, ...this.allEvenWeeks.map((x) => x.value)] : [...onlyOdds];
        this.$emit('input', values);
      },
    },
    allEvenWeeks() {
      return this.options.filter((option) => this.isEven(option.value));
    },
    allOddWeeks() {
      return this.options.filter((option) => this.isOdd(option.value));
    },
    oddWeeksSelected() {
      return this.value.filter((option) => this.isOdd(option.value));
    },
    evenWeeksSelected() {
      return this.value.filter((option) => this.isEven(option.value));
    },
  },
  methods: {
    toggleValue(optionValue) {
      if (this.value.includes(optionValue)) {
        this.$emit(
          'input',
          this.value.filter((option) => option !== optionValue),
        );
      } else {
        this.$emit('input', [...this.value, optionValue]);
      }
    },
    isOdd(value) {
      // exclude -1 (Unplanned week) because we don't show these in the even/odd categories
      return value % 2 !== 0 && value !== -1;
    },
    isEven(value) {
      return !this.isOdd(value);
    },
  },
};
</script>

<template>
  <base-modal v-model="model" :close-on-click-outside="true" title="Opret tømmegruppe">
    <base-input
      v-model="form.name"
      type="text"
      label="Navn"
      class="my-4"
      :required="true"
      tabindex="1"
    ></base-input>

    <div class="flex justify-end">
      <base-button tabindex="9" color="indigo" :disabled="$v.form.$invalid" @click="submit">Opret</base-button>
    </div>
  </base-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

import DisposalCategoryService from '@/services/disposalCategory.service';

export default {
  name: 'DisposalCategoryCreateModal',
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
      };
    },
    async submit() {
      try {
        await DisposalCategoryService.create(this.form);
        this.$ntf.success('Tømmegruppen blev oprettet');
        this.resetForm();
        this.$emit('created');
      } catch (error) {
        this.$ntf.error(error?.data?.error);
      }
    },
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
};
</script>

<template>
  <div class="w-full pb-24">
    <div class="flex-1 min-w-0 xl:flex mt-4">
      <!-- Validation sidebar -->
      <div class="flex-shrink-0 w-72 mr-12">
        <merge-validation-area class="mt-4"></merge-validation-area>
      </div>

      <div class="rounded-md min-w-0 flex-1">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import MergeValidationArea from '@/components/MergeValidationArea.vue';

import { RESET_FLOW } from '@/store/mutations.type';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'MergeFlow',
  components: {
    MergeValidationArea,
  },
  data() {
    return {
      idFields: [],
      possibleIdFields: [
        {
          label: 'Addresse',
          field: 'address',
        },
        {
          label: 'Ext materiel id',
          field: 'ext_materiel_id',
        },
        {
          label: 'Fraktion',
          field: 'fraction',
        },
        {
          label: 'Adresse Link',
          field: 'address_link',
        },
        {
          label: 'Materiel',
          field: 'materiel',
        },
        {
          label: 'ContainerNr',
          field: 'container_no',
        },
        {
          label: 'EjendomsNr',
          field: 'property_no',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('import', ['mergePlan', 'hasExecuteMergePlan']),
  },
  methods: {
    toggleValue(optionValue) {
      if (this.value.includes(optionValue)) {
        this.idFields = this.value.filter((option) => option !== optionValue);
      } else {
        this.idFields = [...this.value, optionValue];
      }
    },
    ...mapMutations('import', {
      resetMergeFlow: RESET_FLOW,
    }),
  },
  beforeRouteLeave(to, from, next) {
    if (this.mergePlan && !this.hasExecuteMergePlan) {
      this.$confirm({
        title: 'Advarsel!',
        bodyText: 'Du vil ikke kunne tilgå plannen længere',
        confirmText: 'Forlad alligevel',
        cancelText: 'Fortryd',
        type: 'warning',
      })
        .then(() => {
          this.resetMergeFlow();
          next();
        })
        .catch(() => {
          next(false);
        });
    } else {
      this.resetMergeFlow();
      next();
    }
  },
};
</script>

<style>

</style>

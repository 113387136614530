<template>
  <div
    class="flex flex-col screen-vh-minus-double-nav"
    @mouseenter="isMouseActive = true"
    @mouseleave="isMouseActive = false"
  >
    <div class="px-4 pt-4">
      <div class="flex justify-between items-center">
        <h2 class="text-2xl font-extrabold text-gray-900 mb-4">Filtre</h2>
      </div>
    </div>

    <div
      class="flex flex-col flex-1 px-4 space-y-4"
      :class="[isMouseActive ? 'overflow-overlay slim-scrollbar': 'overflow-hidden']"
    >
      <div class="border-b border-gray-200 mt-4">
        <base-input
          type="text"
          label="Rækkefølge"
          tooltip="Vis rækkefølge, hvis du har beregnet kørelister. Rækkefølgen tager ikke forbehold, for at du har ændret i opgaverne efter kørelistene er lavet"
          class="mb-4"
        >
          <base-switch slot="input" v-model="includeOrder" :disabled="includeOrderDisabled"></base-switch>
        </base-input>
      </div>

      <base-input label="Køretøj">
        <base-select
          slot="input"
          v-model="vehicleSelected"
          placeholder="Vælg.."
          :options="vehicleOptions"
          width="w-full"
          tabindex="1"
          :clearable="false"
        ></base-select>
      </base-input>

      <base-input
        label="Tømningsuge"
        tooltip="Dette er ugen som opgaven tømmes i og ikke startugen."
      >
        <base-select
          slot="input"
          v-model="plannedWeekSelected"
          placeholder="Vælg.."
          :options="plannedWeekNoFilterOptions"
          width="w-full"
          tabindex="2"
          :clearable="false"
        ></base-select>
      </base-input>

      <base-input label="Tømningsdag">
        <base-select
          slot="input"
          v-model="disposalDaySelected"
          placeholder="Vælg.."
          :options="disposalDayOptions"
          width="w-full"
          tabindex="3"
          :clearable="false"
        ></base-select>
      </base-input>
    </div>

    <tooltip
      :disabled="!filterDisabled"
      content="Du mangler at krydse én eller flere kategorier af"
    >
      <div class="h-28 px-4 relative mt-auto flex items-center">
        <button
          v-loading="routesLoading"
          class="w-full h-16 inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-lg leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
          :class="[filterDisabled ? 'text-gray-700 border-gray-300 cursor-not-allowed bg-gray-200' : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500']"
          :disabled="filterDisabled"
          @click="onUpdateRoute"
        >
          <span>Opdater rute</span>
          <svg
            class="w-6 h-6 ml-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        </button>
      </div>
    </tooltip>
    <base-modal
      v-model="showUnsavedChangesModal"
      :title="'Unsaved Changes'"
      size="md"
      @close="onModalClose"
    >
      <div class="p-4 space-y-4">
        <p class="text-gray-700">
          Du har ændringer, der ikke er gemt. Hvis du fortsætter, vil disse ændringer blive mistet.
        </p>
        <div class="flex justify-end space-x-4">
          <base-button
            type="button"
            color="gray"
            @click="onModalClose"
          >
            Annuller
          </base-button>
          <base-button
            type="button"
            color="indigo"
            @click="onProceedWithFilter"
          >
            Fortsæt
          </base-button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import plannedDisposalDayOptions from '@/tables/combo/disposal_day_options';
import DrivingListsService from '@/services/drivingLists.service';

export default {
  name: 'PlanningArea',
  props: {
    vehicleOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMouseActive: false,
      plannedWeekSelected: '',
      vehicleSelected: '',
      disposalDayOptions: plannedDisposalDayOptions,
      disposalDaySelected: '',
      includeOrder: false,
      includeOrderDisabled: true,
      showUnsavedChangesModal: false,
    };
  },
  computed: {
    ...mapGetters('route', ['routesLoading', 'hasUnsavedChanges']),
    ...mapGetters('frequencies', ['plannedWeekNoFilterOptions']),
    filterDisabled() {
      const noFiltersActive = !this.vehicleSelected || !this.disposalDaySelected || !this.plannedWeekSelected;
      return this.routesLoading || noFiltersActive;
    },
  },
  watch: {
    plannedWeekNoFilterOptions: {
      immediate: true,
      handler: 'preselectPlannedWeekNoOptions',
    },
    vehicleOptions: {
      immediate: true,
      handler: 'vehicleOptionsChanged',
    },
  },
  async mounted() {
    this.preselectHardcodedOptions();
    await this.setIncludeOrder();
  },
  methods: {
    async onUpdateRoute() {
      if (this.hasUnsavedChanges) {
        // Open the unsaved changes modal if changes exist
        this.showUnsavedChangesModal = true;
        return;
      }
      // No unsaved changes, proceed with filtering
      this.onFilter();
    },
   onModalClose() {
      this.showUnsavedChangesModal = false;
    },
   onProceedWithFilter() {
      this.showUnsavedChangesModal = false;
      this.onFilter(); // Proceed with the filter operation
    },
    preselectPlannedWeekNoOptions() {
      this.plannedWeekSelected = this.plannedWeekNoFilterOptions.map((x) => x.value).find(Boolean) || '';
    },
    vehicleOptionsChanged() {
      this.vehicleSelected = this.vehicleOptions.map((x) => x.value).find(Boolean) || '';
    },
    preselectHardcodedOptions() {
      this.disposalDaySelected = this.disposalDayOptions.map((x) => x.value).find(Boolean) || '';
    },
    async setIncludeOrder() {
      const { data } = await DrivingListsService.exists();
      this.includeOrder = data;
      this.includeOrderDisabled = !data;
    },
    onFilter() {
      const payload = {
        disposal_week: this.plannedWeekSelected,
        vehicle_id: this.vehicleSelected,
        disposal_day: this.disposalDaySelected,
        include_order: this.includeOrder,
      };

      this.$emit('filter', payload);
    },
  },
};
</script>

<style>

</style>

<template>
  <div class="mb-4 px-4 py-2 bg-white border border-gray-200 shadow-lg rounded-lg absolute z-10">
    <ul class="space-y-2">
      <li class="text-sm text-gray-700">
        <strong>Længde:</strong> {{ formattedDistance }}
      </li>
      <li class="text-sm text-gray-700">
        <strong>Estimeret tid:</strong> {{ formattedDuration }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'TheRouteViewerRouteStats',
  props: {
    totalDistance: Number,
    totalDuration: Number,
  },
    computed: {
    formattedDistance() {
      return `${(this.totalDistance / 1000).toFixed(1) } km`;
    },
    formattedDuration() {
      const hours = Math.floor(this.totalDuration / 3600);
      const minutes = Math.floor((this.totalDuration % 3600) / 60);
      const seconds = Math.floor(this.totalDuration % 60);

      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(seconds).padStart(2, '0');

      return `${hours}h ${paddedMinutes}m ${paddedSeconds}s`;
    },
  },
};
</script>

<style>

.route-stats-height {
  height: calc(23vh - 30px);
  max-height: calc(23vh - 30px);
}

</style>

<template>
  <slider
    v-model="model"
    width="max-w-lg"
    title="Første række data i filen"
    :close-on-overlay-click="true"
  >
    <h3 class="text-gray-500 mb-2">{{filename}}</h3>
    <div class="mt-2 mb-8">
      <ul>
        <li v-for="(pair, index) in keyValueList" :key="index">
          <key-value-pair :value="pair[1]" :label="pair[0]" :show-badge="false"></key-value-pair>
        </li>
      </ul>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';
import KeyValuePair from '@/components/KeyValuePair.vue';

export default {
  name: 'TaskImportPeakSlider',
  components: {
    Slider,
    KeyValuePair,
  },
  props: {
    value: {
      type: Boolean,
    },
    keyValuePairs: {
      type: Object,
      default: () => ({}),
    },
    filename: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    keyValueList() {
      return Object.entries(this.keyValuePairs);
    },
  },
};
</script>

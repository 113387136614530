/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import L from 'leaflet';

// eslint-disable-next-line no-unused-vars
import LDraw from 'leaflet-draw';
import addCustomShapes from '@/map/draw.extensions';
import { createShapePopupContent, createMarkerPopupContent, createInfoCommand, createShareCommand, createLayerTogglerCommand } from '@/map/popup.dom';
import { formatCoordinate } from '@/map/leaflet.helpers';
import LeafletBase from './leaflet';

import hotkeyHandlers from './leaflet.hotkeys';

import 'leaflet.browser.print/dist/leaflet.browser.print';

addCustomShapes(L);

export default class LeafletDrawableMap extends LeafletBase {
  activeFeatureGroup = null;

  isSharedView = false;

  isLightWorkspace = false;

  constructor(popupFetcherFn, openGridFn, onInfoModalClick, isSharedView, onShareModalClick, isLightWorkspace) {
    super('leaflet');

    this.isSharedView = isSharedView;
    this.isLightWorkspace = isLightWorkspace;

    if (!popupFetcherFn) {
      throw Error('Requires a function definition for popup fetcher');
    }

    if (!openGridFn) {
      throw Error('Requires a function definition for opening a grid');
    }

    if (!onInfoModalClick) {
      throw Error('Requires a function definition for info modal click');
    }

    if (!this.isSharedView && !onShareModalClick) {
      throw Error('Requires a function definition for share modal click');
    }

    this.mostRecentlyTouchedLayer = null;
    this.editHandler = null;

    this.addDrawLayer();

    this.popupFetcherFn = popupFetcherFn;
    this.openGridFn = openGridFn;
    this.onInfoModalClick = onInfoModalClick;
    this.onShareModalClick = onShareModalClick;

    const self = this;

    L.control
      .browserPrint({
        documentTitle: 'Ruteudsnit',
        printModes: ['Portrait', 'Landscape', 'Auto'],
      })
      .addTo(this.map);

    L.Control.LayerToggler = L.Control.extend({
      el: null,

      clickHandler() {
        self.toggleSatelliteLayer();
      },

      onAdd() {
        const button = createLayerTogglerCommand(L, this, this.clickHandler);
        this.el = button;
        return button;
      },

      onRemove() {
        L.DomEvent.off(this.el, 'click', this.clickHandler, this);
      },
    });
    L.control.layerToggler = (opts) => new L.Control.LayerToggler(opts);
    L.control.layerToggler({ position: 'topleft' }).addTo(this.map);

    L.Control.InfoModal = L.Control.extend({
      el: null,

      clickHandler() {
        onInfoModalClick();
      },

      onAdd() {
        const button = createInfoCommand(L, this, this.clickHandler);
        this.el = button;
        return button;
      },

      onRemove() {
        L.DomEvent.off(this.el, 'click', this.clickHandler, this);
      },
    });

    L.control.infoModal = (opts) => new L.Control.InfoModal(opts);
    L.control.infoModal({ position: 'topleft' }).addTo(this.map);

    if (!this.isSharedView) {
      L.Control.ShareModal = L.Control.extend({
        el: null,

        clickHandler() {
          onShareModalClick();
        },

        onAdd() {
          const button = createShareCommand(L, this, this.clickHandler);
          this.el = button;
          return button;
        },

        onRemove() {
          L.DomEvent.off(this.el, 'click', this.clickHandler, this);
        },
      });

      L.control.shareModal = (opts) => new L.Control.ShareModal(opts);
      L.control.shareModal({ position: 'topleft' }).addTo(this.map);
    }

    this.addAdditionalContentOnPrint();
  }

  addAdditionalContentOnPrint() {
    this.map.on(L.Control.BrowserPrint.Event.PrintInit, () => {
      document.querySelector('.print-container').classList.remove('hidden');
    });

    this.map.on(L.Control.BrowserPrint.Event.PrintEnd, () => {
      document.querySelector('.print-container').classList.add('hidden');
    });
  }

  disableEditingOfAllLayers = () => {
    this.editHandler._featureGroup.eachLayer((editableLayer) => {
      this.editHandler._disableLayerEdit(editableLayer);
    });
  };

  addDrawLayer() {
    const editableLayers = new L.FeatureGroup();
    this.editableLayers = editableLayers;

    // used to keep track of added vertices during a polygon drawing
    // so that we can complete the shape on a double click
    let polygonVertices = null;

    this.map.addLayer(editableLayers);

    const drawControl = new L.Control.Draw({
      draw: {
        position: 'topleft',
        polyline: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
        circle: false,
      },
      edit: {
        featureGroup: editableLayers,
        remove: true,
      },
    });

    /* https://github.com/Leaflet/Leaflet.draw/issues/129 */
    const toolbar = new L.EditToolbar({
      featureGroup: editableLayers,
    });

    this.editHandler = toolbar.getModeHandlers()[0].handler;

    this.map.addControl(drawControl);

    const handleDrawLayer = (layer) => {
      const [items, count, totalQuantity] = layer.extractFeatures(this.myFeatureLayer.getLayers());

      const container = createShapePopupContent(L, items, count, totalQuantity, this.openGridFn, openAllPolygons, this.isSharedView);

      // need a grace period for the map
      setTimeout(() => {
        layer.bindPopup(container, { keepInView: true, closeButton: true, autoClose: false, autoPan: false }).openPopup();
        this.map.getContainer().focus();
      }, 500);
    };

    const openAllPolygons = () => {
      let allPolygonItems = [];
      let totalPolygonItemCount = 0;
      let totalPolygonItemQuantity = 0;

      const alreadySeen = new Set();

      // extract all polygons from the feature group
      this.editableLayers.eachLayer((polygonLayer) => {
        const [items, count, totalQuantity] = polygonLayer.extractFeatures(this.myFeatureLayer.getLayers(), alreadySeen);
        allPolygonItems = allPolygonItems.concat(items);
        totalPolygonItemCount += count;
        totalPolygonItemQuantity += totalQuantity;

        items.forEach((item) => alreadySeen.add(item));
      });

      this.openGridFn(allPolygonItems, totalPolygonItemCount, totalPolygonItemQuantity);
    };

    const onDrawCreated = (event) => {
      if (!this.myFeatureLayer) {
        this.removeAllDrawings();
        return;
      }

      this.map.getContainer().focus();

      const { layer } = event;
      handleDrawLayer(layer);

      // set this layer as the last touched layer
      this.mostRecentlyTouchedLayer = layer;

      layer.on('click', (clickedLayer) => {
        this.disableEditingOfAllLayers();

        // set editing of the clicked layer
        this.editHandler._enableLayerEdit(layer);
        this.mostRecentlyTouchedLayer = layer;
      });

      editableLayers.addLayer(layer);

      this.map.doubleClickZoom.disable();
      this.map.off('dblclick', onDoubleClick);
      polygonVertices = null;
    };

    const onEditDraw = (e) => {
      handleDrawLayer(e.poly);
    };

    const onDrawStart = (evt) => {
      this.disableEditingOfAllLayers();

      if (evt.layerType === 'polygon') {
        this.map.doubleClickZoom.disable();
        this.map.on('dblclick', onDoubleClick);
      }
    };

    const onDoubleClick = () => {
      // eslint-disable-next-line no-underscore-dangle
      polygonVertices[0]._icon.click();
    };

    const onDrawVertex = (evt) => {
      polygonVertices = evt.layers.getLayers();
    };

    const onEditVertix = (evt) => {
      this.map.closePopup();
      handleDrawLayer(evt.poly);
    };

    this.map.on(L.Draw.Event.DRAWSTART, onDrawStart);
    this.map.on(L.Draw.Event.CREATED, onDrawCreated);
    this.map.on(L.Draw.Event.EDITSTOP, onEditDraw);
    this.map.on(L.Draw.Event.DRAWVERTEX, onDrawVertex);
    this.map.on(L.Draw.Event.EDITVERTEX, onEditVertix);

    this.map.on('keydown', (e) => {
      const { key, ctrlKey } = e.originalEvent;

      if (key === 'Delete') {
        hotkeyHandlers.remove(this.map, this.editHandler, this.editableLayers);
      } else if (key === 'n') {
        hotkeyHandlers.create();
      } else if (ctrlKey && key === 'Enter') {
        openAllPolygons();
      } else if (key === 'Enter') {
        hotkeyHandlers.open();
      } else if (key === 'i') {
        this.onInfoModalClick();
      }
    });
  }

  removeAllPoints() {
    if (this.myFeatureLayer) {
      this.map.removeLayer(this.myFeatureLayer);
    }
  }

  removeAllDrawings = () => {
    if (this.editableLayers) {
      this.editableLayers.getLayers().forEach((layer) => {
        this.map.removeLayer(layer);
        this.editableLayers.removeLayer(layer);
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line class-methods-use-this
  addMarkerWithDynamicPopup({ id, x, y, task_id, color, sibling_tasks, sibling_planned_instances, quantity, sibling_quantity }, popupContentFetcher) {
    let formattedX = x;
    let formattedY = y;

    if (x.includes('.') || y.includes('.')) {
      formattedX = formatCoordinate(x, x.length);
      formattedY = formatCoordinate(y, y.length);
    }

    const circle = L.circleMarker([formattedX, formattedY], {
      id,
      task_id,
      sibling_tasks,
      sibling_planned_instances,
      quantity,
      sibling_quantity,
      color: 'black', // border
      weight: 1, // border width
      fillColor: color,
      fill: true,
      fillOpacity: 0.8,
      radius: 5,
    });
    const popup = circle.bindPopup('Henter...');

    popup.on('popupopen', async (evt) => {
      // eslint-disable-next-line no-shadow
      const { sibling_planned_instances, id } = evt.target.options;
      const { data } = await popupContentFetcher([...sibling_planned_instances, id]);
      const popupContent = createMarkerPopupContent(L, data.details, data.tasks, this.isLightWorkspace);
      evt.popup.setContent(popupContent);
      evt.popup.update();
    });

    return circle;
  }

  addMarkers(items, zoomTo) {
    const myFeatureLayer = new L.FeatureGroup().addTo(this.map);

    for (let i = 0; i < items.length; i++) {
      const marker = this.addMarkerWithDynamicPopup(items[i], this.popupFetcherFn);
      marker.addTo(myFeatureLayer);
    }

    if (zoomTo) {
      if (items.length) {
        this.zoomToFeatureGroup(myFeatureLayer);
      } else {
        this.map.centerView();
      }
    }

    this.myFeatureLayer = myFeatureLayer;
  }

  focusLastEditedLayer() {
    this.disableEditingOfAllLayers();
    this.editHandler._enableLayerEdit(this.mostRecentlyTouchedLayer);
  }
}

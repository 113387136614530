<template>
  <div class="w-full relative">
    <secondary-nav-bar>
      <secondary-nav-bar-item
        v-for="tab in tabs"
        :key="tab.destination"
        :label="tab.label"
        :hidden="tab.hidden"
        :show-warning="tab.showWarning"
        :destination="tab.destination"
      ></secondary-nav-bar-item>
    </secondary-nav-bar>

    <no-tasks-view v-if="!hasAnyTasks"></no-tasks-view>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import SecondaryNavBar from '@/layout/SecondaryNavBar.vue';
import SecondaryNavBarItem from '@/layout/SecondaryNavBarItem.vue';
import NoTasksView from '@/components/NoTasksView.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ExportView',
  components: {
    SecondaryNavBar,
    SecondaryNavBarItem,
    NoTasksView,
  },
  computed: {
    ...mapGetters('departments', ['isInTransitionPeriod', 'isLightWorkspace']),
    ...mapGetters('status', ['hasAnyTasks']),
    tabs() {
      return [
        {
          label: 'Kørelister',
          destination: 'driving-lists',
        },
        {
          label: 'Mellemtømninger',
          destination: 'transition-periods',
          hidden: this.isInTransitionPeriod || this.isLightWorkspace,
        },
        {
          label: 'Ændringslister',
          destination: 'change-lists',
          hidden: this.isInTransitionPeriod || this.isLightWorkspace,
        },
        {
          label: 'Ekstern eksport',
          destination: 'task-export',
          hidden: this.isInTransitionPeriod || this.isLightWorkspace,
        },
        {
          label: 'Kommune adresser',
          destination: 'municipality-address-export',
        },
      ];
    },
  },
};
</script>

<template>
  <div class="w-full z-10 flex-1 relative bg-indigo-600">
    <div id="leaflet" class="absolute inset-0"></div>
  </div>
</template>

<script>
import LeafletRouteViewerMap from '@/map/leaflet.route-viewer';
import { mapState } from 'vuex';

export default {
  name: 'TheLeafletRouteViewerMap',
  props: {
    popupDataFetcher: {
      type: Function,
      required: true,
      description: 'An async function to load remote data for a markers popup content',
    },
    addSelectedStops: {
      type: Function,
      required: true,
      description: 'A function that adds selected stops',
    },
  },
  data() {
    return {
      map: null,
    };
  },
  computed: {
    ...mapState('route', ['selectedStops']),
  },
  mounted() {
    this.map = new LeafletRouteViewerMap(this.popupDataFetcher, this.addSelectedStops);
  },
  methods: {
    clearFeatures() {
      this.map.removeAllPoints();
    },
    focus() {
      this.map.focus();
    },
    reZoom() {
      this.map.zoomToActiveFeatureGroup();
    },
    zoomToPoint({
                  x,
                  y,
                  zoom,
                }) {
      this.map.setView([y, x], zoom);
    },
    addStops(stops, startLocation, disposalLocation, zoomTo = true) {
      if (!stops.length) {
        this.$ntf.info('Ingen rute fundet med dit filter');
        return;
      }
      this.map.addMarkers(stops, startLocation, disposalLocation, zoomTo);
    },
    updateColorSelection(plannedInstances, selectedStops) {
      this.map.updateMarkers(plannedInstances, selectedStops);
    },
  },
};
</script>

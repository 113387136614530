<template>
  <slider v-model="model" width="max-w-lg" title="Ret køretøj" :close-on-overlay-click="true">
    <div class="my-8">
      <base-input
        v-model="form.name"
        type="text"
        label="Navn i NorthArc"
        :required="true"
        tabindex="1"
      ></base-input>

      <input-dawa
        v-model="form.address"
        class="mt-4"
        autocomplete="none"
        tabindex="2"
        :invalid="invalid($v.form.address)"
        :valid="valid($v.form.address)"
        :required="true"
        label="Køretøjets start lokation"
        tooltip="Dette har kun relevans for algoritmen"
      ></input-dawa>

      <input-dawa
        v-model="form.disposal_point"
        class="mt-4"
        autocomplete="none"
        tabindex="3"
        :invalid="invalid($v.form.disposal_point)"
        :valid="valid($v.form.disposal_point)"
        :required="true"
        label="Køretøjets aflæsningslokation"
        tooltip="Dette har kun relevans for algoritmen"
      ></input-dawa>

      <base-input label="Vælg zone" class="mt-4" :required="true">
        <base-select
          slot="input"
          v-model="form.property_zone"
          width="w-full"
          :clearable="true"
          :options="propertyZoneOptions"
          tabindex="4"
          placeholder="Land, By.."
        ></base-select>
      </base-input>

      <div class="relative mt-8">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-base font-medium text-gray-500">Antal tømninger</span>
        </div>
      </div>

      <div class="flex space-x-2 mt-6">
        <base-input
          v-model="form.unloads_mon"
          type="number"
          class="w-20"
          label="Mandag"
          :required="true"
          min="0"
          tabindex="5"
        ></base-input>

        <base-input
          v-model="form.unloads_tue"
          type="number"
          label="Tirsdag"
          class="w-20"
          :required="true"
          min="0"
          tabindex="6"
        ></base-input>

        <base-input
          v-model="form.unloads_wed"
          type="number"
          label="Onsdag"
          min="0"
          class="w-20"
          :required="true"
          tabindex="7"
        ></base-input>

        <base-input
          v-model="form.unloads_thu"
          type="number"
          label="Torsdag"
          class="w-20"
          min="0"
          :required="true"
          tabindex="8"
        ></base-input>

        <base-input
          v-model="form.unloads_fri"
          type="number"
          label="Fredag"
          class="w-20"
          min="0"
          :required="true"
          tabindex="9"
        ></base-input>
      </div>

      <div class="relative mt-8">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-base font-medium text-gray-500">Andet</span>
        </div>
      </div>

      <base-input class="mt-4">
        <base-checkbox
          slot="input"
          v-model="form.is_active_for_planning"
          tabindex="10"
          description="Skal køretøjet indgå i planlægning?"
          label="Aktiv"
        ></base-checkbox>
      </base-input>

      <div class="flex justify-end mt-6 space-x-2">
        <base-button
          tabindex="11"
          color="red"
          :inverted="true"
          @click="() => $emit('onDeleteRequest', vehicle)"
        >Slet</base-button>
        <base-button tabindex="12" color="indigo" :disabled="$v.form.$invalid" @click="submit">Ret</base-button>
      </div>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';
import InputDawa from '@/components/form/InputDawa.vue';

import propertyZoneOptions from '@/tables/combo/property_zone_options';

import { required, minValue } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

import { mapActions } from 'vuex';
import { EDIT_VEHICLE } from '@/store/actions.type';

export default {
  name: 'VehicleEditSlider',
  components: {
    Slider,
    InputDawa,
  },
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
    vehicle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        id: '',
        name: '',
        address: '',
        disposal_point: null,
        unloads_mon: 500,
        unloads_tue: 500,
        unloads_wed: 500,
        unloads_thu: 500,
        unloads_fri: 500,
        unloads_sat: 0,
        unloads_sun: 0,
        is_active_for_planning: true,
        property_zone: '',
      },
      propertyZoneOptions,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.disposal_point': function (newValue) {
      if (newValue === '') {
        this.form.disposal_point = null;
      }
    },
    vehicle: {
      immediate: true,
      handler: 'vehicleChanged',
    },
  },
  methods: {
    ...mapActions('vehicles', {
      edit: EDIT_VEHICLE,
    }),
    vehicleChanged() {
      if (!this.vehicle) {
        this.resetForm();
        return;
      }

      this.form = {
        id: this.vehicle.id,
        name: this.vehicle.name,
        address: this.vehicle.address,
        disposal_point: this.vehicle.disposal_point,
        unloads_mon: this.vehicle.unloads_mon,
        unloads_tue: this.vehicle.unloads_tue,
        unloads_wed: this.vehicle.unloads_wed,
        unloads_thu: this.vehicle.unloads_thu,
        unloads_fri: this.vehicle.unloads_fri,
        unloads_sat: this.vehicle.unloads_sat,
        unloads_sun: this.vehicle.unloads_sun,
        is_active_for_planning: this.vehicle.is_active_for_planning,
        property_zone: this.vehicle.property_zone,
      };
    },
    resetForm() {
      this.form = {
        id: '',
        name: '',
        address: '',
        disposal_point: null,
        unloads_mon: 500,
        unloads_tue: 500,
        unloads_wed: 500,
        unloads_thu: 500,
        unloads_fri: 500,
        unloads_sat: 0,
        unloads_sun: 0,
        is_active_for_planning: true,
        property_zone: '',
      };
    },
    async submit() {
      try {
        await this.edit(this.form);
        this.$ntf.success('Køretøjet blev rettet');
        this.resetForm();
        this.$emit('editted');
      } catch (error) {
        this.$ntf.error(error?.data?.error);
      }
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        address: { required },
        disposal_point: {},
        unloads_mon: { required, minValue: minValue(0) },
        unloads_tue: { required, minValue: minValue(0) },
        unloads_wed: { required, minValue: minValue(0) },
        unloads_thu: { required, minValue: minValue(0) },
        unloads_fri: { required, minValue: minValue(0) },
        is_active_for_planning: { required },
        property_zone: { required },
      },
    };
  },
};
</script>

import ApiService from '@/common/api.service';

const ConfigurationsService = {
  get(id) {
    const resource = `configurations/${id}`;
    return ApiService.get(resource);
  },

  getAllByType(type) {
    const resource = `configurations/?type=${type}`;
    return ApiService.get(resource);
  },

  create(config) {
    const resource = 'configurations/';
    return ApiService.post(resource, config);
  },

  update(config) {
    const resource = 'configurations/';
    return ApiService.put(resource, config.id, config);
  },
};

export default ConfigurationsService;

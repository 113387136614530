export default {
  labelInvalidField: 'Ugyldig fil',
  labelFileLoadError: 'Fejl under load',
  labelFileProcessing: 'Uploader',
  labelFileProcessingComplete: 'Upload færdig',
  labelFileProcessingAborted: 'Upload afbrudt',
  labelFileProcessingError: 'Fejl under upload',
  labelTapToCancel: 'Tryk for at afbryde',
  labelTapToRetry: 'Tryk for at gentage',
  labelButtonRemoveItem: 'Fjern',
  labelButtonAbortItemLoad: 'Afbryd',
  labelButtonRetryItemLoad: 'Gentag',
  labelButtonRetryItemProcessing: 'Gentag',
  labelButtonAbortItemProcessing: 'Afbryd',
  fileValidateTypeLabelExpectedTypes: 'Forventer {allButLastType} eller {lastType}',
  labelFileTypeNotAllowed: 'Ugyldig filtype',
};

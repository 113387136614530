<template>
  <secondary-nav-bar-content
    title="Frekvenser"
    description="Her kan du kortlægge hvordan systemet skal genkende forskellige frekvenstyper i datasættet"
  >
    <base-button slot="actions" color="indigo" @click="freqSliderOpen = true">Opret</base-button>

    <div v-loading="loading" class="pb-8 pt-8">
      <div v-if="frequenciesToMap.length" class="grid-cols-1">
        <div class="flex font-semibold text-gray-800 mb-2">
          <p class="flex-1 pl-6">Tekstværdi i datasæt</p>
          <p class="flex-1 pl-14">Oversat værdi i NorthArc</p>
          <p class="pr-14">Kun sommer?</p>
        </div>

        <ul>
          <li v-for="freq in frequenciesToMap" :key="freq">
            <mapping-line
              :options="frequencies"
              :title="freq"
              :value="frequenciesMapped[freq]"
              :use-value="true"
              placeholder="Vælg frekvens"
              @selected="onMapping"
            >
              <p v-if="!freq" slot="title" class="text-blue-700">Tomme frekvenser i datasættet</p>

              <div slot="extra" class="px-6 py-4 pr-14 flex items-center text-sm font-medium">
                <span class="ml-4 text-sm font-medium text-indigo-600">
                  <base-checkbox
                    :value="onlySummerMapped[freq]"
                    @input="(evt) => onSummerChange({ value: evt, label: freq })"
                  ></base-checkbox>
                </span>
              </div>
            </mapping-line>
          </li>
        </ul>

        <div class="flex justify-end mt-4">
          <base-button
            color="indigo"
            :disabled="submitDisabled || loading"
            @click="onSubmit"
          >Sæt frekvenser på opgaver</base-button>
        </div>
      </div>

      <dl
        v-if="previouslyMapped.length"
        class="sm:divide-y sm:divide-gray-200 rounded-md pb-8 grid-cols-1"
      >
        <h5 class="sub-headline mb-4">Kortlagte frekvenser i dit workspace</h5>

        <key-value-pair
          value="Oversat værdi i NorthArc"
          label="Tekstværdi i datasæt"
          :show-badge="false"
        ></key-value-pair>
        <key-value-pair
          v-for="pair in previouslyMapped"
          :key="pair.label"
          :value="`${pair.value} ${pair.onlySummer ? '(kun summer)' : ''}`"
          :label="pair.label"
        ></key-value-pair>
      </dl>
    </div>
    <frequency-create-slider
      v-model="freqSliderOpen"
      placeholder="Vælg frekvens"
      @created="onCreate"
    ></frequency-create-slider>
  </secondary-nav-bar-content>
</template>

<script>
import FrequencyService from '@/services/frequencies.service';
import TaskService from '@/services/tasks.service';
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import MappingLine from '@/components/MappingLine.vue';
import FrequencyCreateSlider from '@/components/FrequencyCreateSlider.vue';
import KeyValuePair from '@/components/KeyValuePair.vue';
import { mapActions } from 'vuex';
import { SUBMIT_FREQUENCY_MAPPINGS, GET_MOST_IRREGULAR_FREQUENCY } from '@/store/actions.type';

export default {
  name: 'ConfigurationFrequencyView',
  components: {
    SecondaryNavBarContent,
    MappingLine,
    FrequencyCreateSlider,
    KeyValuePair,
  },
  data() {
    return {
      frequencies: [],
      frequenciesToMap: [],
      frequenciesMapped: {},
      onlySummerMapped: {},
      loading: false,
      freqSliderOpen: false,
      previouslyMapped: [],
    };
  },
  computed: {
    submitDisabled() {
      return this.frequenciesToMap.length !== Object.keys(this.frequenciesMapped).length - this.previouslyMapped.length;
    },
  },
  async mounted() {
    await this.fetchFrequenciesToMap();
    await this.fetchFrequencies();
    await this.fetchPreviouslyMapped();
  },
  methods: {
    ...mapActions('tasks', {
      submitFrequencyMappings: SUBMIT_FREQUENCY_MAPPINGS,
    }),
    ...mapActions('frequencies', {
      getMostIrregularFrequency: GET_MOST_IRREGULAR_FREQUENCY,
    }),
    async fetchPreviouslyMapped() {
      try {
        const { data } = await TaskService.getPreviouslyMappedFrequencies();

        if (data.previouslyMappedFrequencies) {
          Object.keys(data.previouslyMappedFrequencies).forEach((key) => {
            this.previouslyMapped.push({
              label: key === 'null' ? 'Tomme frekvenser i datasættet' : key,
              value: this.frequencies.find((freq) => freq.value === data.previouslyMappedFrequencies[key]).label,
              onlySummer: data.previouslyMappedSummer[key],
            });
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchFrequencies() {
      try {
        const { data } = await FrequencyService.getSelectOptions();
        this.frequencies = data;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchFrequenciesToMap() {
      try {
        const { data } = await TaskService.getDistinctTaskFrequencies();
        this.frequenciesToMap = data.frequencies;

        if (data.previouslyMappedFrequencies) {
          Object.keys(data.previouslyMappedFrequencies).forEach((key) => {
            this.onMapping({ label: key, value: data.previouslyMappedFrequencies[key] });
            this.onSummerChange({ label: key, value: data.previouslyMappedSummer[key] });
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onMapping({ label, value }) {
      delete this.frequenciesMapped[label];

      if (value) {
        this.frequenciesMapped[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.frequenciesMapped = { ...this.frequenciesMapped };
    },
    onSummerChange({ value, label }) {
      delete this.onlySummerMapped[label];

      this.onlySummerMapped[label] = value;

      this.onlySummerMapped = { ...this.onlySummerMapped };
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.submitFrequencyMappings({ frequency_mappings: this.frequenciesMapped, summer_mappings: this.onlySummerMapped });
        this.$ntf.success('Frekvenserne er nu kortlagt');
        this.frequenciesToMap = [];
        this.previouslyMapped = [];
        await this.fetchPreviouslyMapped();

        // load the most irregular frequency into vuex, which is used for a number of components
        await this.getMostIrregularFrequency();
      } catch (error) {
        this.$unhandledError(error);
      }

      this.loading = false;
    },
    async onCreate() {
      await this.fetchFrequencies();
      this.freqSliderOpen = false;
    },
  },
};
</script>

/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import L from 'leaflet';

// eslint-disable-next-line no-unused-vars
import LDraw from 'leaflet-draw';
import addCustomShapes from '@/map/draw.extensions';
import { createMarkerPopupContent, createLayerTogglerCommand, createMarkerPopupStartLocationContent, createMarkerPopupDisposalPointContent, createShapePopupContent } from '@/map/popup.dom';
import { formatCoordinate } from '@/map/leaflet.helpers';
import hotkeyHandlers from '@/map/leaflet.hotkeys';
import LeafletBase from './leaflet';

addCustomShapes(L);

function extractOptionsInsidePolygon(polygonLayer, featureLayer) {
  const polygonBounds = polygonLayer.getBounds();
  const layersInsidePolygon = featureLayer.getLayers().filter((layer) => {
    if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
      return polygonBounds.contains(layer.getLatLng());
    }
    return false;
  });
  return layersInsidePolygon.map((layer) => layer.options);
}

export default class LeafletRouteViewer extends LeafletBase {
  constructor(popupFetcherFn, addSelectedStops) {
    super('leaflet');

    if (!popupFetcherFn) {
      throw Error('Requires a function definition for popup fetcher');
    }

    if (!addSelectedStops) {
      throw Error('Requires a function definition for adding selected stops');
    }

    this.editHandler = null;

    this.addDrawLayer();

    this.popupFetcherFn = popupFetcherFn;
    this.addSelectedStops = addSelectedStops;
    this.markers = {};

    const self = this;

    L.Control.LayerToggler = L.Control.extend({
      el: null,

      clickHandler() {
        self.toggleSatelliteLayer();
      },

      onAdd() {
        const button = createLayerTogglerCommand(L, this, this.clickHandler);
        this.el = button;
        return button;
      },

      onRemove() {
        L.DomEvent.off(this.el, 'click', this.clickHandler, this);
      },
    });
    L.control.layerToggler = (opts) => new L.Control.LayerToggler(opts);
    L.control.layerToggler({ position: 'topleft' }).addTo(this.map);
  }

  addDrawLayer() {
    const editableLayers = new L.FeatureGroup();
    this.editableLayers = editableLayers;
    this.map.addLayer(editableLayers);

    const drawControl = new L.Control.Draw({
      draw: {
        position: 'topleft',
        polyline: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
        circle: false,
      },
      edit: {
        featureGroup: editableLayers,
        remove: true,
      },
    });

    const toolbar = new L.EditToolbar({
      featureGroup: editableLayers,
    });

    this.editHandler = toolbar.getModeHandlers()[0].handler;
    this.map.addControl(drawControl);

    this.setupDrawEvents();
    this.setupKeyboardEvents();
  }

  setupDrawEvents() {
    let polygonVertices = null;

    const handleDrawLayer = (layer) => {
      const features = extractOptionsInsidePolygon(layer, this.myFeatureLayer);

      const newSelectedStops = features.map((feature) => ({
        planned_instance_id: feature.id,
      }));

      this.addSelectedStops({ newSelectedStops });

      setTimeout(() => {
        this.map.getContainer().focus();
      }, 500);
    };

    const onDrawCreated = (event) => {
      if (!this.myFeatureLayer) {
        this.removeAllDrawings();
        return;
      }

      this.map.getContainer().focus();
      const { layer } = event;
      handleDrawLayer(layer);

      this.editableLayers.addLayer(layer);
      polygonVertices = null;
      this.map.doubleClickZoom.disable();
      this.map.off('dblclick', onDoubleClick);

      this.map.removeLayer(layer);
      polygonVertices = null;
    };

    const onDrawStart = (evt) => {
      if (evt.layerType === 'polygon') {
        this.map.doubleClickZoom.disable(); // Disable double-click zoom when starting to draw
        this.map.on('dblclick', onDoubleClick); // Add double-click handler to finish drawing
      }
    };

    const onDoubleClick = () => {
      if (polygonVertices && polygonVertices[0]) {
        polygonVertices[0]._icon.click();
      }
    };

    const onDrawVertex = (evt) => {
      polygonVertices = evt.layers.getLayers();
    };

    const onEditDraw = (e) => {
      handleDrawLayer(e.poly);
    };

    const onEditVertex = (evt) => {
      this.map.closePopup();
      handleDrawLayer(evt.poly);
    };

    this.map.on(L.Draw.Event.DRAWSTART, onDrawStart);
    this.map.on(L.Draw.Event.CREATED, onDrawCreated);
    this.map.on(L.Draw.Event.EDITSTOP, onEditDraw);
    this.map.on(L.Draw.Event.DRAWVERTEX, onDrawVertex);
    this.map.on(L.Draw.Event.EDITVERTEX, onEditVertex);
  }

  setupKeyboardEvents() {
    this.map.on('keydown', (e) => {
      const { key, ctrlKey } = e.originalEvent;

      if (key === 'Delete') {
        hotkeyHandlers.remove(this.map, this.editHandler, this.editableLayers);
      } else if (key === 'n') {
        hotkeyHandlers.create();
      } else if (key === 'Enter') {
        hotkeyHandlers.open();
      }
    });
  }

  removeAllDrawings = () => {
    if (this.editableLayers) {
      this.editableLayers.getLayers().forEach((layer) => {
        this.map.removeLayer(layer);
        this.editableLayers.removeLayer(layer);
      });
    }
  };

  removeAllPoints() {
    if (this.myFeatureLayer) {
      this.map.removeLayer(this.myFeatureLayer);
      this.myFeatureLayer = null;
    }
  }

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line class-methods-use-this
  addMarkerWithDynamicPopup(item, color, popupContentFetcher) {
    let formattedX = item.x;
    let formattedY = item.y;
    if (item.x.includes('.') || item.y.includes('.')) {
      formattedX = formatCoordinate(item.x, item.x.length);
      formattedY = formatCoordinate(item.y, item.y.length);
    }

    let circle;

    if (item.order === -1) {
      circle = L.circleMarker([formattedY, formattedX], {
        id: item.planned_instance_id,
        task_id: item.task_id,
        quantity: item.quantity,
        color: 'black', // border
        weight: 1,
        fill: true,
        fillOpacity: 0.8,
        radius: 5,
      });
    } else {
      const customIcon = L.divIcon({
        className: 'h-6 w-6',
        html: `<div class=" text-white flex-center rounded-full h-6 w-6 border-2 border-black" style="background-color: ${color}">${item.order === -1 ? '' : item.order - 1}</div>`,
        iconSize: [20, 20],
      });
      circle = L.marker([formattedY, formattedX], {
        icon: customIcon,
        id: item.planned_instance_id,
        task_id: item.task_id,
        quantity: item.quantity,
        color: 'black', // border
        weight: 1, // border width
        fill: true,
        fillOpacity: 0.8,
        radius: 5,
      });
    }

    const popup = circle.bindPopup('Henter...');

    popup.on('popupopen', async (evt) => {
      // eslint-disable-next-line no-shadow
      const { sibling_planned_instances, id } = evt.target.options;
      const { data } = await popupContentFetcher([id]);
      const popupContent = createMarkerPopupContent(L, data.details, data.tasks, this.isLightWorkspace);
      evt.popup.setContent(popupContent);
      evt.popup.update();
    });

    circle.on('click', () => {
      const event = new CustomEvent('markerClick', {
        detail: { stop: item },
      });
      window.dispatchEvent(event);
    });

    this.markers[item.planned_instance_id] = circle;

    return circle;
  }

  bringMarkerToFront(plannedInstanceId) {
    const marker = this.markers[plannedInstanceId];
    if (marker) {
      marker.setZIndexOffset(1000);
      if (marker.bringToFront) {
        marker.bringToFront();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addStartLocation(startLocation, myFeatureLayer) {
    if (startLocation && startLocation.x && startLocation.y) {
      const startLocationMarker = L.circleMarker(
          [parseFloat(startLocation.y), parseFloat(startLocation.x)], {
            color: 'black',
            fillColor: '#7AFF65',
            weight: 1,
            fill: true,
            fillOpacity: 0.8,
            radius: 8,
          },
      );
      startLocationMarker.bindPopup(createMarkerPopupStartLocationContent(L, startLocation)).addTo(myFeatureLayer);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addDisposalLocation(disposalLocation, myFeatureLayer) {
    if (disposalLocation && disposalLocation.x && disposalLocation.y) {
      const disposalMarker = L.circleMarker(
          [parseFloat(disposalLocation.y), parseFloat(disposalLocation.x)], {
            color: 'black',
            fillColor: 'red',
            weight: 1,
            fill: true,
            fillOpacity: 0.8,
            radius: 8,
          },
      );
      disposalMarker.bindPopup(createMarkerPopupDisposalPointContent(L, disposalLocation)).addTo(myFeatureLayer);
    }
  }

  addMarkers(items, startLocation, disposalLocation, zoomTo) {
    const myFeatureLayer = new L.FeatureGroup().addTo(this.map);

    this.addStartLocation(startLocation, myFeatureLayer);
    this.addDisposalLocation(disposalLocation, myFeatureLayer);

    items.forEach((item) => {
      const marker = this.addMarkerWithDynamicPopup(item, item.color, this.popupFetcherFn);
      marker.addTo(myFeatureLayer);
      this.markers[item.planned_instance_id] = marker;
    });

    if (zoomTo && myFeatureLayer.getLayers().length > 0) {
      this.zoomToFeatureGroup(myFeatureLayer);
    } else {
      this.centerView();
    }

    this.myFeatureLayer = myFeatureLayer;
  }

  setView(coords, zoom) {
    if (this.map) {
      this.map.setView(coords, zoom);
    }
  }

  changeMarkerColor(item, color) {
    const marker = this.markers[item.planned_instance_id];
    if (marker) {
      this.myFeatureLayer.removeLayer(marker);
      const newMarker = this.addMarkerWithDynamicPopup(item, color, this.popupFetcherFn);
      newMarker.addTo(this.myFeatureLayer);
      this.markers[item.planned_instance_id] = newMarker;
    } else {
      console.warn(`Marker with id ${item.planned_instance_id} not found.`);
    }
  }

  resetMarkersZIndex(selectedStops) {
    Object.values(this.markers).forEach((marker) => {
      marker.setZIndexOffset(0);
    });

    selectedStops.forEach((stop) => {
      this.bringMarkerToFront(stop.planned_instance_id);
    });
  }

  updateMarkers(items, selectedStops) {
    const selectedIds = selectedStops.map((stop) => stop.planned_instance_id);
    items.forEach((item) => {
      const itemId = item.planned_instance_id;
      const isSelected = selectedIds.includes(itemId);
      const marker = this.markers[itemId];
      if (marker) {
        this.changeMarkerColor(item, isSelected ? '#4F46E5' : item.color);
        if (isSelected) {
          this.bringMarkerToFront(itemId);
        } else {
          marker.setZIndexOffset(0); // Reset z-index for non-selected markers
        }
      }
    });
  }
}

<template>
  <div class="grid grid-cols-2 mt-8 gap-8">
    <action-card
      title="Tilføj nye opgaver"
      description="Tilføj nye opgaver til dit workspace"
      @action="onSelection(true)"
    >
      <icon-view-grid-add slot="icon" class="h-6 w-6"></icon-view-grid-add>

      <div slot="content">
        <p class="text-indigo-900 font-semibold my-2">Workflow</p>

        <ol class="flex flex-col space-y-3 mt-3">
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Upload csv- eller excelfil med opgaverne</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Vælg kolonner så systemet kan læse filens værdier korrekt</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p class="ml-3 text-sm font-medium text-gray-600">Bekræft og importer værdierne</p>
          </li>
        </ol>
      </div>
    </action-card>
    <action-card
      title="Sammenflet opgaver"
      :disabled="!hasPreviousImport"
      description="Tilføj, ret og slet opgaver. Brug funktionen til at sammenflette, hvis det eksisterende datasæt skal tilrettes."
      @action="onSelection(false)"
    >
      <icon-view-grid slot="icon" class="h-6 w-6"></icon-view-grid>

      <div slot="content">
        <p class="text-indigo-900 font-semibold my-2">Workflow</p>

        <ol class="flex flex-col space-y-3 mt-3">
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Upload csv- eller excelfil med opgaverne</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Vælg kolonner, så systemet kan læse filens værdier korrekt</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Opret plan for sammenfletning, det vil sige, hvilke opgaver bliver tilføjet, rettet og slettet</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Check at planen ser rigtig ud, tilpas eller godkend herefter planen</p>
          </li>
          <li class="flex items-start">
            <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
              <div class="h-2 w-2 bg-gray-300 rounded-full"></div>
            </div>
            <p
              class="ml-3 text-sm font-medium text-gray-600"
            >Bekræft planen, hvorefter den udføres og opgaverne sammenflettes</p>
          </li>
        </ol>
      </div>
    </action-card>
  </div>
</template>

<script>
import ActionCard from '@/components/ActionCard.vue';

import { mapActions, mapGetters } from 'vuex';
import { GET_IMPORT_AUDIT } from '@/store/actions.type';

export default {
  name: 'ImportSelectionView',
  components: {
    ActionCard,
  },
  data() {
    return {
      hasPreviousImport: false,
    };
  },
  computed: {
    ...mapGetters('jobs', ['nonInitialImportJobId', 'executeMergePlanJobId']),
    ...mapGetters('import', ['mergePlan']),
  },
  async mounted() {
    if (this.nonInitialImportJobId || this.mergePlan) {
      this.$router.push({ name: 'task-import-merge-plan' });
    } else if (this.executeMergePlanJobId) {
      this.$router.push({ name: 'task-import-merge-execute' });
    } else {
      await this.getImportAudit();
    }
  },
  methods: {
    ...mapActions('import', {
      getLatestImportAudit: GET_IMPORT_AUDIT,
    }),
    onSelection(isNew) {
      if (isNew) {
        this.$router.push({ name: 'tasks-import-upload', query: { new: 1 } });
      } else {
        this.$router.push({ name: 'tasks-import-upload' });
      }
    },
    async getImportAudit() {
      try {
        const data = await this.getLatestImportAudit();
        this.hasPreviousImport = Boolean(data);
      } catch (error) {
        this.$unhandledError(error, true);
      }
    },
  },
};
</script>

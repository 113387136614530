<template>
  <li>
    <base-button @click="$emit('open')">
      <div class="flex items-center space-x-1">
        <span class="font-normal">{{filter.column.label}}</span>
        <span class="font-medium">{{getHumanReadableFilterType}}</span>
        <span class="font-normal">{{getHumanReadableFilterValue}}</span>

        <button @click.stop="$emit('remove')">
          <icon-x class="w-4 h-4 text-gray-800"></icon-x>
        </button>
      </div>
    </base-button>
  </li>
</template>

<script>
import { toLocale } from '@/components/table/helpers/filterTypes';

export default {
  name: 'FilterActivePill',
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getHumanReadableFilterValue() {
      if (typeof this.filter.value === 'string') {
        return this.filter.value;
      }

      return this.filter.value.label;
    },
    getHumanReadableFilterType() {
      return toLocale(this.filter.type);
    },
  },
};
</script>

<style>

</style>

export default [
  {
    label: 'Visningsnavn',
    field: 'display_name',
    type: 'string',
  },
  {
    label: 'Forkortelse',
    field: 'abbreviation',
    type: 'string',
  },
  {
    label: 'Indlæst navn',
    field: 'name',
    type: 'string',
  },
];

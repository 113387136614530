<template>
  <div
    class="flex flex-col"
    :class="[isSharedView ? 'screen-h-topbar' : 'screen-vh-minus-double-nav']"
    @mouseenter="isMouseActive = true"
    @mouseleave="isMouseActive = false"
  >
    <div class="px-4 pt-4">
      <div class="flex justify-between">
        <h2 class="text-2xl font-extrabold text-gray-900 mb-4">Filtre</h2>

        <button
          v-if="!isLightWorkspace"
          type="button"
          class="ml-4 flex items-center text-gray-500"
          @click="toggleAdvancedFilters"
        >
          <span class="text-sm mr-1">Flere filtre</span>

          <icon-plus v-if="!showAdvancedFilters" class="h-5 w-5"></icon-plus>
          <icon-minus v-else class="h-5 w-5"></icon-minus>
        </button>
      </div>

      <base-select
        v-model="partitionSelected"
        :disabled="isLightWorkspace"
        :options="partitionOptions"
        width="w-full"
      ></base-select>
    </div>

    <div
      class="flex flex-col flex-1 mt-4"
      :class="[isMouseActive ? 'overflow-overlay slim-scrollbar': 'overflow-hidden']"
    >
      <template v-if="!isLightWorkspace">
        <div v-show="showAdvancedFilters" class="flex flex-col">
          <planning-filter-box
            v-model="seasonsSelected"
            class="border-none"
            label="Sæson"
            :options="seasonOptions"
          ></planning-filter-box>
        </div>

        <planning-filter-box-planned-weeks
          v-model="plannedWeeksSelected"
          :show-advanced-filters="showAdvancedFilters"
          :class="[showAdvancedFilters ? 'border-t border-gray-200' : '']"
          :options="plannedWeekNoOptions"
          label="Uger"
        ></planning-filter-box-planned-weeks>
        <planning-filter-box
          v-model="disposalDaysSelected"
          label="Tømmedage"
          :options="disposalDayOptions"
        ></planning-filter-box>
        <planning-filter-box
          v-model="frequenciesSelected"
          label="Frekvenser"
          :options="frequencyOptions"
        ></planning-filter-box>
      </template>
      <planning-filter-box v-model="vehiclesSelected" label="Køretøjer" :options="vehicleOptions"></planning-filter-box>
    </div>

    <tooltip
      :disabled="!filterDisabled"
      content="Du mangler at krydse én eller flere kategorier af"
    >
      <div class="h-28 px-4 relative mt-auto flex items-center">
        <button
          v-loading="plannedInstancesLoading"
          class="w-full h-16 inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-lg leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
          :class="[filterDisabled ? 'text-gray-700 border-gray-300 cursor-not-allowed bg-gray-200' : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500']"
          :disabled="filterDisabled"
          @click="onFilter"
        >
          <span>Opdater kort</span>
          <svg
            class="w-6 h-6 ml-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        </button>
      </div>
    </tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PlanningFilterBox from '@/components/PlanningFilterBox.vue';
import PlanningFilterBoxPlannedWeeks from '@/components/PlanningFilterBoxPlannedWeeks.vue';

import plannedDisposalDayOptions from '@/tables/combo/disposal_day_options';

import COUNTING_TYPE_ENUM from '@/util/countingTypes';

const PARTITION_OPTIONS = Object.freeze([
  {
    label: 'Køretøj',
    value: 0,
  },
  {
    label: 'Frekvens',
    value: 1,
  },
  {
    label: 'Uge',
    value: 2,
  },
  {
    label: 'Tømmedag',
    value: 3,
  },
  {
    label: 'Uge & Køretøj',
    value: 4,
  },
  {
    label: 'Uge & Tømmedag',
    value: 5,
  },
  {
    label: 'Uge & Frekvens',
    value: 6,
  },
  {
    label: 'Uge & Køretøj & Tømmedag',
    value: 7,
  },
  {
    label: 'Køretøj & Frekvens',
    value: 8,
  },
  {
    label: 'Køretøj & Tømmedag',
    value: 9,
  },
  {
    label: 'Frekvens & Tømmedag',
    value: 10,
  },
  {
    label: 'Ejendomsstatus',
    value: 11,
  },
  {
    label: 'Sæson',
    value: 12,
  },
]);

export default {
  name: 'PlanningArea',
  components: {
    PlanningFilterBox,
    PlanningFilterBoxPlannedWeeks,
  },
  props: {
    isSharedView: {
      type: Boolean,
      default: false,
    },
    vehicleOptions: {
      type: Array,
      default: () => [],
    },
    frequencyOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMouseActive: false,
      showAdvancedFilters: false,
      partitionOptions: PARTITION_OPTIONS,
      partitionSelected: 0,
      plannedWeeksSelected: [],
      vehiclesSelected: [],
      frequenciesSelected: [],
      disposalDayOptions: plannedDisposalDayOptions,
      disposalDaysSelected: [],
      seasonOptions: [
        {
          label: 'Kun sommer',
          value: COUNTING_TYPE_ENUM.ONLY_SUMMER,
        },
        {
          label: 'Kun helårs',
          value: COUNTING_TYPE_ENUM.ONLY_ALL_YEAR,
        },
      ],
      seasonsSelected: [],
    };
  },
  computed: {
    ...mapGetters('planning', ['plannedInstancesLoading', 'partitionSummary']),
    ...mapGetters('frequencies', ['plannedWeekNoOptions']),
    ...mapGetters('departments', ['isLightWorkspace']),
    filterDisabled() {
      const noFiltersActive = !this.vehiclesSelected.length || !this.frequenciesSelected.length || !this.disposalDaysSelected.length || !this.plannedWeeksSelected.length || !this.seasonsSelected.length;
      return this.plannedInstancesLoading || noFiltersActive;
    },
  },
  watch: {
    plannedWeekNoOptions: {
      immediate: true,
      handler: 'preselectPlannedWeekNoOptions',
    },
    vehicleOptions: {
      immediate: true,
      handler: 'vehicleOptionsChanged',
    },
    frequencyOptions: {
      immediate: true,
      handler: 'frequencyOptionsChanged',
    },
  },
  async mounted() {
    // await this.fetchVehicles();
    // await this.fetchFrequencies();
    this.preselectHardcodedOptions();
  },
  methods: {
    toggleAdvancedFilters() {
      this.showAdvancedFilters = !this.showAdvancedFilters;

      if (!this.showAdvancedFilters) {
        // reset
        this.seasonsSelected = this.seasonOptions.map((x) => x.value);
      }
    },
    preselectPlannedWeekNoOptions() {
      // -1 is unplanned week
      this.plannedWeeksSelected = [-1, ...this.plannedWeekNoOptions.map((x) => x.value)];
    },
    vehicleOptionsChanged() {
      // preselect vehicles
      this.vehiclesSelected = this.vehicleOptions.map((x) => x.value);
    },
    frequencyOptionsChanged() {
      // preselect frequencies
      this.frequenciesSelected = this.frequencyOptions.map((x) => x.value);
    },
    preselectHardcodedOptions() {
      this.seasonsSelected = this.seasonOptions.map((x) => x.value);
      this.disposalDaysSelected = this.disposalDayOptions.map((x) => x.value);
    },
    onFilter() {
      const payload = {
        partition: this.partitionSelected,
        planned_week_numbers: this.plannedWeeksSelected,
        filtered_vehicles: this.vehiclesSelected,
        total_vehicles: this.vehicleOptions.length,
        filtered_frequencies: this.frequenciesSelected,
        total_frequencies: this.frequencyOptions.length,
        filtered_disposal_days: this.disposalDaysSelected,
        filtered_seasons: this.seasonsSelected,
      };

      this.$emit('filter', payload);
    },
  },
};
</script>

<style>

.overflow-overlay {
  overflow: overlay;
}
</style>

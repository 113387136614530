<template>
  <div
    class="block card-texture max-w-sm p-6 rounded-lg shadow min-w-full h-full"
    :class="containerClasses"
  >
    <h5 class="mb-2 text-5xl font-bold tracking-tight text-gray-900">{{total}}</h5>
    <p class="mb-2 text-2xl uppercase font-bold tracking-tight" :class="textColor">{{title}}</p>
    <p class="font-normal text-gray-700">{{description}}</p>
  </div>
</template>

<script>
export default {
  name: 'MergeCard',
  props: {
    total: {
      default: 0,
      type: Number,
      description: 'The stat card number',
    },
    title: {
      default: '',
      type: String,
      description: 'The stat card title',
    },
    description: {
      default: '',
      type: String,
      description: 'The stat card description',
    },
    color: {
      type: String,
      default: 'green',
    },
  },
  computed: {
    containerClasses() {
      return {
        red: 'bg-red-300 border-red-500',
        'red-light': 'bg-red-100 border-red-500',
        green: 'bg-green-300 border-green-500',
        'green-light': 'bg-green-100 border-green-500',
        blue: 'bg-blue-100 border-blue-500',
        yellow: 'bg-yellow-100 border-yellow-500',
        orange: 'bg-orange-100 border-orange-500',
        black: 'bg-white border-gray-500',
      }[this.color];
    },
    textColor() {
      return {
        red: 'text-red-900',
        'red-light': 'text-red-900',
        green: 'text-green-900',
        'green-light': 'text-green-900',
        blue: 'text-blue-900',
        yellow: 'text-yellow-900',
        orange: 'text-orange-900',
        black: 'text-black',
      }[this.color];
    },
  },
};
</script>

<style scoped>

.card-texture {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
</style>

<template>
  <div class="relative z-20 w-full">
    <button
      v-if="partitionSummary.length"
      class="h-10 w-10 bg-gray-100 flex justify-center items-center rounded-t-md absolute -top-10 inset-x-0 m-auto"
      @click="open = !open"
    >
      <icon-arrow-right
        class="h-5 w-5 text-gray-800 transform"
        :class="[open ? 'rotate-90': '-rotate-90']"
      ></icon-arrow-right>
    </button>

    <base-alert
      v-if="partitionSummary.length > 20"
      title="Uhensigtsmæssig visning"
      description="Din filtrering har givet 20+ forskellige opdelinger. Vi anbefaler at indsnævre dine filtre for at få en mere optimal kortvisning"
      type="warning"
      :dismissable="true"
    ></base-alert>

    <collapse-transition>
      <ul
        v-if="isVisible"
        key="partitionSummary"
        v-loading="loading"
        class="flex justify-center flex-wrap border overflow-y-auto slim-scrollbar"
        style="max-height: 240px"
      >
        <partition-summary-box
          v-for="(partitionInstance, index) in partitionSummary"
          :key="index"
          :instance="partitionInstance"
        ></partition-summary-box>
      </ul>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';

import PartitionSummaryBox from '@/components/PartitionSummaryBox.vue';

export default {
  name: 'ThePartitionSummary',
  components: {
    CollapseTransition,
    PartitionSummaryBox,
  },
  props: {
    partitionSummary: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    isVisible() {
      return this.partitionSummary.length && this.open;
    },
  },
};
</script>

// vue-good-table
export default [
  {
    label: 'Adresse',
    field: 'address',
    type: 'string',
  },
  {
    label: 'Vej',
    field: 'address_road',
    type: 'string',
  },
  {
    label: 'Nr',
    field: 'address_number',
    type: 'string',
  },
  {
    label: 'Dør',
    field: 'address_door_letter',
    type: 'string',
  },
  {
    label: 'Postnr',
    field: 'zip_code',
    type: 'string',
  },
  {
    label: 'By',
    field: 'city',
    type: 'string',
  },
  {
    label: 'Længdegrad',
    field: 'longitude',
    type: 'string',
  },
  {
    label: 'Breddegrad',
    field: 'latitude',
    type: 'string',
  },
  {
    label: 'DAWA',
    field: 'dawa',
  },
  {
    label: 'Google',
    field: 'google',
  },
];

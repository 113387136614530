<template>
  <secondary-nav-bar-content
    title="Materiel"
    description="Her kan du angive et visningsnavn eller forkortelse til alt materiel som er indlæst. Bemærk at materiel eksisterer på tværs af forskellige workspaces"
  >
    <base-alert
      v-if="hasMaterielWithoutAbbreviation"
      type="warning"
      class="my-4"
      title="Du har materiel uden forkortelser"
    ></base-alert>

    <div v-if="!materielLoading && materiels.length === 0">
      <image-container asset-name="empty" title="Ingen materiel indlæst i datasættet"></image-container>
    </div>

    <div v-else v-loading="materielLoading" class="min-h-384px">
      <vue-good-table
        :columns="columns"
        :rows="materiels"
        style-class="vgt-table striped custom-vgt-table"
        @on-row-click="onRowClick"
      ></vue-good-table>
    </div>

    <materiel-edit-slider
      v-model="materielEditSliderOpen"
      :materiel="selectedMateriel"
      @editted="onEdit"
    ></materiel-edit-slider>
  </secondary-nav-bar-content>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import MaterielService from '@/services/materiel.service';

import MaterielEditSlider from '@/components/MaterielEditSlider.vue';

import columns from '@/components/table/configs/materiel';
import { mapGetters, mapActions } from 'vuex';
import { CHECK_MATERIEL_STATUS } from '@/store/actions.type';

export default {
  name: 'ConfigurationMaterielView',
  components: {
    SecondaryNavBarContent,
    ImageContainer,
    VueGoodTable,
    MaterielEditSlider,
  },
  data() {
    return {
      materielLoading: true,
      materiels: [],
      selectedMateriel: null,
      materielEditSliderOpen: false,
      columns,
    };
  },
  computed: {
    ...mapGetters('status', ['hasMaterielWithoutAbbreviation']),
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const { data } = await MaterielService.getAll();
        this.materiels = data;
      } catch (error) {
        this.$unhandledError(error);
      }
      this.materielLoading = false;
    },
    ...mapActions('status', {
      checkMaterielStatus: CHECK_MATERIEL_STATUS,
    }),
    async onEdit() {
      this.materielLoading = true;
      await this.fetch();
      await this.checkMaterielStatus();
      this.materielEditSliderOpen = false;
    },
    onRowClick(params) {
      this.selectedMateriel = params.row;
      this.materielEditSliderOpen = true;
    },
  },
};
</script>

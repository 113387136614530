<template>
  <div class="autocomplete-container relative">
    <button
      class="absolute z-50 cursor-pointer hover:text-gray-700 text-gray-500"
      :class="[valid || invalid ? 'right-8' : 'right-2']"
      style="top: 2.15rem"
    >
      <icon-x-circle v-if="value" classes="h-5 w-5" @click.native.stop="clear"></icon-x-circle>
    </button>

    <base-input
      type="text"
      :value="value"
      placeholder
      v-bind="$attrs"
      :valid="valid"
      :invalid="invalid"
      :input-classes="inputClasses"
      v-on="listeners"
    >
      <span slot="helpBlock">Feltet er påkrævet</span>
    </base-input>
  </div>
</template>

<script>
import { generateId } from '@/util/id';

const DAWA = require('dawa-autocomplete2/dist/js/dawa-autocomplete2');

export default {
  name: 'InputDawa',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoCompleter: null,
      id: generateId(),
      focused: false,
    };
  },
  computed: {
    inputClasses() {
      return `${this.id}`;
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  mounted() {
    const self = this;
    this.$nextTick(() => {
      const input = document.querySelector(`.${this.id}`);
      this.autoCompleter = DAWA.dawaAutocomplete(input, {
        select(selected) {
          self.$emit('selected', selected.data);
          self.$emit('input', selected.tekst);
        },
        adgangsadresserOnly: true,
      });
    });

    this.$once('hook:beforeDestroy', () => {
      this.autoCompleter.destroy();
    });
  },
  methods: {
    clear() {
      this.$emit('input', '');
    },
  },
};
</script>

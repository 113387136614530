import ApiService from '@/common/api.service';

const DisposalCategoryService = {
  getAll() {
    const resource = 'disposal-categories/';
    return ApiService.get(resource);
  },

  create(category) {
    const resource = 'disposal-categories/';
    return ApiService.post(resource, category);
  },

  update(payload) {
    const resource = 'disposal-categories/';
    return ApiService.put(resource, payload.id, payload);
  },

  delete(id) {
    const resource = 'disposal-categories/';
    return ApiService.delete(resource, id);
  },
};

export default DisposalCategoryService;

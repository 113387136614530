/* eslint-disable no-underscore-dangle */
const remove = (map, editHandler, editableLayers) => {
  editHandler._featureGroup.eachLayer((editableLayer) => {
    // if it is the currently editing layer delete it

    if (editableLayer.editing._enabled) {
      editableLayers.removeLayer(editableLayer);
      map.removeLayer(editableLayer);
    }
  });
};

const create = () => {
  // programatically click the polygon draw tool in the toolbar
  document.querySelector('.leaflet-draw-draw-polygon').click();
};

const open = () => {
  const popupMenuButton = document.querySelector('.open-grid-button');

  if (popupMenuButton) {
    document.querySelector('.open-grid-button').click();
  }
};

export default {
  remove,
  create,
  open,
};

<template>
  <div class="mt-4">
    <job-poller
      v-if="geoCodingJobId"
      title="Geokoder automatisk"
      description="Dette kan tage nogle minutter, hvis der er mange adresser."
      :job-id="geoCodingJobId"
      :type="jobTypes.GEOCODING"
      @result="onResult"
    >
      <the-geocode-progress-poller v-if="!hasResult"></the-geocode-progress-poller>
    </job-poller>

    <base-card v-if="hasResult" class="mt-4">
      <image-container
        v-if="notGeocodableCount > 0"
        asset-name="qa_engineer"
        title="Resultat"
        :subtitle="`${notGeocodableCount} opgaver kunne ikke geokodes`"
      >
        <base-button
          :outline="true"
          color="gray"
          class="mt-4 block"
          @click="$emit('changeView', views.OVERVIEW)"
        >Tilbage til metodevalg</base-button>
      </image-container>
      <image-container v-else asset-name="success" title="Alt data er geokodet!"></image-container>
    </base-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { SET_HAS_NON_GEOCODED } from '@/store/mutations.type';
import JobPoller from '@/components/JobPoller.vue';
import TheGeocodeProgressPoller from '@/components/geocode/TheGeocodeProgressPoller.vue';
import * as jobTypes from '@/util/jobTypes';

import ImageContainer from '@/components/ImageContainer.vue';
import views from '@/util/geocodeViews';

export default {
  name: 'TheGeocodeAutomatic',
  components: {
    ImageContainer,
    JobPoller,
    TheGeocodeProgressPoller,
  },
  data() {
    return {
      notGeocodableCount: 0,
      hasResult: false,
      views,
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('jobs', ['geoCodingJobId']),
  },
  methods: {
    ...mapMutations('status', {
      setHasNonGeocoded: SET_HAS_NON_GEOCODED,
    }),
    onResult(res) {
      const { not_geocodable_count } = res;
      this.notGeocodableCount = not_geocodable_count;
      this.hasResult = true;

      if (this.notGeocodableCount === 0) {
        this.setHasNonGeocoded(false);
      }
    },
  },
};
</script>

const isBetween = (value, min, max) => value >= min && value <= max;

// Latitude from 54.76906 to 57.72093 and longitude from 8.24402 to 14.70664.

export const mustBeLongitude = (value) => {
  if (!value) return true;

  const cleaned = value.replace('.', ',');
  const splitResult = cleaned.split(',');
  if (splitResult.length !== 2) {
    return false;
  }

  const firstDigit = Number(splitResult[0]);

  return isBetween(firstDigit, 8, 14);
};

export const mustBeLatitude = (value) => {
  if (!value) return true;

  const cleaned = value.replace('.', ',');
  const splitResult = cleaned.split(',');
  if (splitResult.length !== 2) {
    return false;
  }

  const firstDigit = Number(splitResult[0]);

  return isBetween(firstDigit, 54, 57);
};

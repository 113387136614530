<template>
  <div>
    <job-poller
      v-if="importExtraTasksJobId || isUploadingForImport"
      title="Importerer og opretter opgaver"
      description="Dette kan tage nogle minutter, hvis der er mange opgaver"
      :job-id="importExtraTasksJobId"
      :type="jobTypes.IMPORT_EXTRA_TASKS"
      @closed="onJobPollerClose"
      @result="onTaskImportResult"
    >
      <image-container v-if="summary" asset-name="success">
        <dl class="sm:divide-y sm:divide-gray-200 mt-4">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Indlæste opgaver</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.totalTasks}}</span>
              </base-badge>
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Indlæste spande</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.totalQuantity}}</span>
              </base-badge>
            </dd>
          </div>
        </dl>
      </image-container>
    </job-poller>

    <base-alert
      v-if="hasAnyTasks"
      title="Du har allerede importeret opgaver"
      description="I ekstrakørsler, kan der kun importeres opgaver én gang."
      type="info"
      class="mt-4"
    ></base-alert>

    <template v-else>
      <template v-if="!importExtraTasksJobId && !isUploadingForImport">
        <div v-show="!hasLoadedCSVHeaders" class="mt-8">
          <file-pond
            ref="pond"
            name="csvfile"
            class="col-span-2"
            :allow-multiple="false"
            label-idle="Træk CSV fil her eller <span class='underline'>browse</span>"
            accepted-file-types=".csv, text/csv"
            :file-validate-type-detect-type="getCustomMIMEType"
          />
        </div>

        <div v-if="hasLoadedCSVHeaders" class="mt-2">
          <p class="sub-headline">Felter</p>

          <base-alert type="info" class="mt-2 mb-4" :align-center="true">
            <div slot="title" class="flex items-center justify-between w-full">
              <p>Tip: Se den første række data, så du nemmere kan vælge de rigtige kolonner</p>
              <base-button
                size="sm"
                color="indigo"
                :inverted="true"
                @click="rowPeakSliderOpen = !rowPeakSliderOpen"
              >Åben vindue (Alt+M)</base-button>
            </div>
          </base-alert>

          <h3 v-if="filename" class="text-gray-500 mb-2">{{filename}}</h3>

          <import-extra-tasks-mapping-list
            ref="configMappings"
            :headers="csvHeaders"
            :first-row-peak-map="csvFirstRowPeakMap"
          ></import-extra-tasks-mapping-list>

          <div class="flex justify-end my-4">
            <base-button color="indigo" :disabled="loading" @click="submit">Importer</base-button>
          </div>
        </div>
      </template>

      <task-import-peak-slider
        v-model="rowPeakSliderOpen"
        :filename="filename"
        :key-value-pairs="csvFirstRowPeakMap"
      ></task-import-peak-slider>
    </template>
  </div>
</template>

<script>
import vueFilePond, { setOptions } from 'vue-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import ImportExtraTasksMappingList from '@/components/import/ImportExtraTasksMappingList.vue';
import ImageContainer from '@/components/ImageContainer.vue';
import JobPoller from '@/components/JobPoller.vue';
import TaskImportPeakSlider from '@/components/import/TaskImportPeakSlider.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CHECK_STATUS_ALL, IMPORT_EXTRA_TASKS, GET_CSV_HEADERS, GET_MOST_IRREGULAR_FREQUENCY } from '@/store/actions.type';

import { SET_FILENAME } from '@/store/mutations.type';
import filePondLocale from '@/locales/da-DK/filePond';
import * as jobTypes from '@/util/jobTypes';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: 'ImportExtraView',
  components: {
    FilePond,
    ImportExtraTasksMappingList,
    ImageContainer,
    JobPoller,
    TaskImportPeakSlider,
  },
  data() {
    return {
      loading: false,
      summary: null,
      hasLoadedCSVHeaders: false,
      csvHeaders: [],
      csvFirstRowPeakMap: {},
      currentFile: null,
      jobTypes,
      rowPeakSliderOpen: false,
    };
  },
  computed: {
    ...mapGetters('jobs', ['importExtraTasksJobId']),
    ...mapGetters('status', ['hasAnyTasks']),
    ...mapGetters('import', ['isUploadingForImport', 'filename']),
    isNewImport() {
      return this.$route.query.new;
    },
  },
  created() {
    setOptions({
      ...filePondLocale,
      // https://pqina.nl/filepond/docs/patterns/api/server/
      server: {
        // eslint-disable-next-line no-unused-vars
        process: (fieldName, file, _metadata, load, _error, progress, abort, _transfer, _options) => {
          this.loading = true;

          this.setFilename(file.name);

          if (!this.hasLoadedCSVHeaders) {
            this.sniffHeaders(file, abort, load);
          }
        },
      },
    });
  },
  async mounted() {
    document.addEventListener('keydown', this.onSliderHotkey);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.onSliderHotkey);
    });
  },

  methods: {
    ...mapActions('status', {
      checkStatusAll: CHECK_STATUS_ALL,
    }),
    ...mapMutations('import', {
      setFilename: SET_FILENAME,
    }),
    ...mapActions('import', {
      importExtraTasks: IMPORT_EXTRA_TASKS,
      getCsvHeaders: GET_CSV_HEADERS,
    }),
    ...mapActions('frequencies', {
      getMostIrregularFrequency: GET_MOST_IRREGULAR_FREQUENCY,
    }),
    onSliderHotkey(event) {
      if (event.altKey && event.key === 'm' && this.hasLoadedCSVHeaders) {
        this.rowPeakSliderOpen = !this.rowPeakSliderOpen;
      }
    },
    async onJobPollerClose() {
      // reset view completely
      this.loading = false;
      this.summary = null;
      this.hasLoadedCSVHeaders = false;
      this.csvHeaders = [];
      this.csvFirstRowPeakMap = {};
      this.currentFile = null;
    },
    getCustomMIMEType(source, type) {
      if (window.navigator.platform.indexOf('Win') > -1) {
        if (type === 'application/vnd.ms-excel') {
          // eslint-disable-next-line no-param-reassign
          type = 'text/csv';
        }
      }
      return Promise.resolve(type);
    },
    async onTaskImportResult(result) {
      this.summary = result;
      this.checkStatusAll();
      await this.getMostIrregularFrequency();
    },
    async uploadFile(configuration) {
      try {
        this.$ntf.info('Begynder importering af ekstrakørsler');
        await this.importExtraTasks({ file: this.currentFile, config: configuration, filename: this.filename });
      } catch (error) {
        this.$unhandledError(error, false);
      }
      this.currentFile = null;
    },
    sniffHeaders(file, abort) {
      this.getCsvHeaders({ file })
        .then((data) => {
          this.currentFile = file;
          this.csvHeaders = data.headers;
          this.csvFirstRowPeakMap = data.peak;
          this.hasLoadedCSVHeaders = true;
        })
        .catch((err) => {
          if (err?.data?.error?.file) {
            this.$ntf({
              type: 'error',
              subtitle: this.$ntf.error(err?.data?.error?.file),
              duration: 7000,
            });
          } else {
            this.$unhandledError(err);
          }
          abort();
        })
        .finally(() => {
          this.loading = false;
          this.$refs.pond.removeFiles();
        });
    },
    async submit() {
      const configuration = this.$refs.configMappings.getConfiguration();
      await this.uploadFile(configuration);
    },
  },
};
</script>

<style>
/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  @apply h-32;
}

.filepond--hopper {
  @apply border-2 border-dashed rounded-md bg-white border-gray-200;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: white;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: red;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: black;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

.filepond--panel-bottom {
  box-shadow: none !important;
}

/* the text color of the file status and info labels */
.filepond--file {
  color: black;
}

.filepond--file-wrapper {
  border: 1px solid rgb(226, 232, 240) !important;
  @apply rounded-md;
}

/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  @apply bg-red-700;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  @apply bg-green-50;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
}
</style>

<template>
  <div
    class="p-4 flex flex-col justify-between absolute top-0 shadow-md rounded-md bg-white left-12"
    @click.stop
  >
    <h6 class="text-gray-700 text-sm font-medium">{{column.label}}</h6>
    <div class="space-y-4">
      <fieldset v-if="column.type === 'select'" class="space-y-2">
        <base-radio
          tabindex="0"
          :value="filterType"
          name="Filter"
          radio-value="exact"
          :inline="true"
          @change="filterType = $event"
        >
          <span class="text-gray-800 text-sm ml-2">Lig med</span>
        </base-radio>
        <base-select v-model="value" :options="column.options()" tabindex="1" :clearable="true"></base-select>
      </fieldset>

      <fieldset v-else-if="column.type === 'boolean'" class="w-64">
        <base-radio
          :value="filterType"
          name="Filter"
          tabindex="2"
          radio-value="exact"
          :inline="true"
          @change="filterType = $event"
        >
          <span class="text-gray-800 text-sm ml-2">Lig med</span>
        </base-radio>
        <div id="boolOptions" class="flex items-center space-x-4 mt-2">
          <base-radio
            :value="value"
            name="bool"
            tabindex="3"
            radio-value="true"
            :inline="true"
            @change="value = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Ja</span>
          </base-radio>
          <base-radio
            :value="value"
            name="bool"
            radio-value="false"
            :inline="true"
            tabindex="4"
            @change="value = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Nej</span>
          </base-radio>
        </div>
      </fieldset>

      <fieldset v-else-if="column.type === 'number'" class="space-y-2">
        <base-radio
          tabindex="5"
          :value="filterType"
          name="Filter"
          radio-value="exact"
          :inline="true"
          @change="filterType = $event"
        >
          <span class="text-gray-800 text-sm ml-2">Lig med</span>
        </base-radio>
        <base-input
          v-if="filterType == 'exact'"
          v-model="value"
          tabindex="6"
          class="w-64"
          type="number"
        />
      </fieldset>

      <template v-else>
        <fieldset class="space-y-2">
          <base-radio
            :value="filterType"
            name="Filter"
            tabindex="7"
            radio-value="contains"
            :inline="true"
            @change="filterType = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Indeholder</span>
          </base-radio>
          <base-input
            v-if="filterType == 'contains'"
            v-model="value"
            tabindex="8"
            class="w-64"
            type="text"
          />
        </fieldset>

        <fieldset class="space-y-2">
          <base-radio
            :value="filterType"
            name="Filter"
            tabindex="9"
            radio-value="notcontains"
            :inline="true"
            @change="filterType = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Indeholder ikke</span>
          </base-radio>
          <base-input
            v-if="filterType == 'notcontains'"
            v-model="value"
            tabindex="10"
            class="w-64"
            type="text"
          />
        </fieldset>

        <fieldset class="space-y-2">
          <base-radio
            :value="filterType"
            name="Filter"
            tabindex="11"
            radio-value="exact"
            :inline="true"
            @change="filterType = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Lig med</span>
          </base-radio>
          <base-input
            v-if="filterType == 'exact'"
            v-model="value"
            tabindex="12"
            class="w-64"
            type="text"
          />
        </fieldset>

        <fieldset class="space-y-2">
          <base-radio
            :value="filterType"
            name="Filter"
            radio-value="not"
            tabindex="13"
            :inline="true"
            @change="filterType = $event"
          >
            <span class="text-gray-800 text-sm ml-2">Ikke lig med</span>
          </base-radio>
          <base-input
            v-if="filterType == 'not'"
            v-model="value"
            tabindex="14"
            class="w-64"
            type="text"
          />
        </fieldset>
      </template>
    </div>

    <div class="flex justify-end space-x-2 mt-6">
      <base-flat-button color="gray" size="xs" text="Luk" tabindex="15" @click="$emit('close')"></base-flat-button>
      <base-flat-button
        color="indigo"
        tabindex="16"
        size="xs"
        :disabled="!canApply"
        text="Anvend filter"
        @click="onApply"
      ></base-flat-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterForm',
  props: {
    column: {
      type: Object,
      required: true,
    },
    selectedFilter: {
      type: Object,
      required: false,
      default: null,
      description: 'A previous selected filter that should be re-opened.',
    },
  },
  data() {
    return {
      filterType: 'contains',
      value: '',
    };
  },
  computed: {
    canApply() {
      const requiredValueTypes = ['select', 'boolean'];
      if (requiredValueTypes.includes(this.column.type) && !this.value) {
        return false;
      }

      return true;
    },
  },
  watch: {
    column: {
      handler: 'onColumnChange',
    },
    selectedFilter: {
      handler: 'onSelectedFilterChange',
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.column.type === 'select' && !this.column.options) {
      console.error("Columns of type 'select' must have options provided", this.column);
    }

    this.applyFocusToFirstInputEl();
  },
  methods: {
    applyFocusToFirstInputEl() {
      if (this.column.type === 'boolean') {
        const input = this.$el.querySelector('#boolOptions input');
        input.focus();
        return;
      }

      const input = this.$el.querySelector('input:not([type="radio"])');
      input.focus();
    },
    onApply() {
      // if the value is a non-humanreadable value such as an GUID from a dropdown
      // an object is returned with a humanreadable label
      let { value } = this;
      if (this.column.type === 'select') {
        value = {
          label: this.column.options().find((x) => x.value === value).label,
          innerValue: this.value,
        };
      } else if (this.column.type === 'boolean') {
        value = {
          label: value === 'true' ? 'Ja' : 'Nej',
          innerValue: value === 'true',
        };
      } else if (value === '') {
        value = {
          label: "''",
          innerValue: value,
        };
      }

      this.$emit('filter', {
        filterType: this.filterType,
        value,
        column: this.column,
      });
    },
    onColumnChange() {
      const exactOnly = ['select', 'number', 'boolean'];
      this.filterType = exactOnly.includes(this.column.type) ? 'exact' : 'contains';
      this.value = '';

      this.$nextTick(this.applyFocusToFirstInputEl);
    },
    onSelectedFilterChange(selectedFilter) {
      if (!selectedFilter) {
        this.onColumnChange();
        return;
      }

      const { type, value } = selectedFilter;
      this.filterType = type;
      this.value = typeof value === 'string' ? value : value.innerValue;
    },
  },
};
</script>

<style>

</style>

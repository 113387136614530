<template>
  <slider v-model="model" title="Manuel geokodning med dawa" :close-on-overlay-click="true">
    <p v-if="dawaResult.results.length" class="my-4 text-gray-400">
      Fundet {{dawaResult.results.length}}
      i match kategori {{dawaResult.category}}
    </p>
    <ul
      v-if="dawaResult.results.length || loading"
      v-loading="loading"
      class="divide divide-gray-100 mt-2"
    >
      <li
        v-for="(dawa, index) in dawaResult.results"
        :key="dawa.id"
        :class="{ 'bg-gray-50': index % 2 === 0}"
        class="flex items-center py-1.5 px-2"
      >
        <div>{{buildAddress(dawa)}}</div>

        <div class="ml-auto">
          <base-button ref="selectable" @click="submitAddress(dawa.id)">Vælg</base-button>
        </div>
      </li>
    </ul>
    <image-container v-else asset-name="empty" title="Ingen matches fundet">
      <base-button color="indigo" class="mt-6" @click="model = false">Luk</base-button>
    </image-container>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';
import GeocodeService from '@/services/geocode_service';
import buildAddress from '@/util/address';
import ImageContainer from '@/components/ImageContainer.vue';

export default {
  name: 'ManualGeocodeSliderDawa',
  components: {
    Slider,
    ImageContainer,
  },
  props: {
    value: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dawaResult: {
        category: 'A',
        results: [],
      },
      loading: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
  watch: {
    value: {
      handler: 'getDawaResults',
    },
  },
  methods: {
    buildAddress,
    async getDawaResults(isVisible) {
      if (!isVisible) {
        this.dawaResult = {
          category: 'A',
          results: [],
        };
        return;
      }

      this.loading = true;
      const address = buildAddress(this.row);
      try {
        const results = await GeocodeService.getGeocodeOptions(address);
        this.dawaResult = results;

        if (this.dawaResult.results.length) {
          this.$nextTick(() => {
            this.$refs.selectable[0].$el.querySelector('button').focus();
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.loading = false;
      }
    },
    async submitAddress(id) {
      try {
        const coordinates = await GeocodeService.geocode(id);
        this.$emit('onCoordinate', {
          coordinates,
          id: this.row.id,
        });
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

<template>
  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2 border-b">
    <dt class="text-sm font-medium text-gray-600">{{label}}</dt>
    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-start">
      <base-badge v-if="showBadge" color="green">
        <span class="text-gray-800 font-bold">{{value}}</span>
      </base-badge>
      <span v-else class="text-gray-800 font-bold">{{value}}</span>
    </dd>
  </div>
</template>

<script>
export default {
  name: 'KeyValuePair',
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>

</style>

import axios from 'axios';

const GeocodeService = {
  async getGeocodeOptionsGoogle(address) {
    const baseUrl = process.env.VUE_APP_GOOGLE_GEOCODE_URL;
    const apiKey = process.env.VUE_APP_GOOGLE_GEOCODE_API_KEY;

    const resource = `${baseUrl}?address=${address}&components=country:DK&key=${apiKey}`;
    const { data } = await axios.get(resource);

    // https://developers.google.com/maps/documentation/geocoding/overview?hl=en_US#component-filtering
    // only interested in these types:
    const validTypes = ['street_address', 'locality', 'premise', 'subpremise'];
    return data?.results
      .filter((r) => r.types.some((t) => validTypes.includes(t)))
      .map((res) => ({
        address: res.formatted_address,
        coordinates: [res?.geometry?.location?.lng, res?.geometry?.location?.lat],
      }));
  },

  async getGeocodeOptions(address) {
    const resource = `https://api.dataforsyningen.dk/datavask/adgangsadresser?betegnelse=${address}/`;

    const { data } = await axios.get(resource);

    const { kategori } = data;

    const results = data.resultater.map((res) => ({
      address_road: res.adresse.vejnavn,
      zip_code: res.adresse.postnr,
      city: res.adresse.postnrnavn,
      address_door_letter: res.adresse.dør,
      address_number: res.adresse.husnr,
      id: res.adresse.id,
    }));

    return {
      category: kategori,
      results,
    };
  },

  async geocode(id) {
    const resource = `https://api.dataforsyningen.dk/adgangsadresser/${id}?medtagnedlagte=true&srid=4326&format=geojson&struktur=mini`;

    const { data } = await axios.get(resource);

    return data?.geometry?.coordinates;
  },
};

export default GeocodeService;

<template>
  <secondary-nav-bar-content
    title="Tømmegrupper"
    description="Her kan du forbinde indlæste affaldstyper med en tømmegruppe. Start med at oprette de tømmegrupper du ønsker."
  >
    <base-button slot="actions" color="indigo" @click="disposalCategoryCreateModal = true">Opret</base-button>

    <div class="my-8 grid grid-cols-2 gap-12">
      <div>
        <h5 class="sub-headline mb-4">Tømmegrupper</h5>

        <div v-if="!disposalCategoriesLoading && !disposalCategories.length">
          <image-container asset-name="empty" title="Ingen tømmegrupper oprettet">
            <base-button
              color="indigo"
              class="mt-8"
              @click="disposalCategoryCreateModal = true"
            >Opret første tømmegruppe</base-button>
          </image-container>
        </div>
        <ul v-loading="disposalCategoriesLoading">
          <li
            v-for="dc in disposalCategories"
            :key="dc.name"
            class="border border-gray-200 rounded-md px-6 py-4 flex flex-col mb-4 bg-white"
          >
            <div class="flex justify-between">
              <span class="text-gray-700 font-semibold text-lg">{{dc.name}}</span>

              <base-button
                color="red"
                :inverted="true"
                :rounded="true"
                @click="onDelete(dc.id, dc.name)"
              >Slet</base-button>
            </div>

            <span v-if="!dc.waste_fractions.length" class="text-gray-700 italic">Ingen affaldstyper</span>

            <div v-else class="flex space-x-2">
              <span v-for="(wc, index) in dc.waste_fractions" :key="wc" class="flex items-center">
                {{wc}}
                <span
                  v-if="index !== dc.waste_fractions.length -1"
                  class="h-1 w-1 rounded-full bg-gray-500 ml-2 block"
                ></span>
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div class="flex flex-col">
        <h5 class="sub-headline mb-4">Affaldstyper</h5>

        <div v-if="!wasteFractionsLoading && !wasteFractions.length">
          <image-container asset-name="empty" title="Ingen affaldstyper indlæst i datasættet"></image-container>
        </div>

        <div v-else>
          <ul>
            <li v-for="wc in wasteFractions" :key="wc.id">
              <mapping-line
                :options="disposalCategoryOptions"
                :title="wc.name"
                placeholder="Vælg tømmegruppe"
                :value="wc.disposal_category || ''"
                :use-value="true"
                @selected="onMapping($event, wc)"
              ></mapping-line>
            </li>
          </ul>

          <div class="flex justify-end">
            <base-button
              color="indigo"
              :disabled="!hasFractionChanges"
              @click="onSaveMappings"
            >Gem nye tildelinger</base-button>
          </div>
        </div>
      </div>
    </div>

    <disposal-category-create-modal v-model="disposalCategoryCreateModal" @created="onCreate"></disposal-category-create-modal>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import MappingLine from '@/components/MappingLine.vue';

import WasteFractionService from '@/services/wasteFraction.service';
import DisposalCategoryService from '@/services/disposalCategory.service';
import ImageContainer from '@/components/ImageContainer.vue';
import DisposalCategoryCreateModal from '@/components/DisposalCategoryCreateModal.vue';

export default {
  name: 'ConfigurationDisposalCategoryView',
  components: {
    SecondaryNavBarContent,
    MappingLine,
    ImageContainer,
    DisposalCategoryCreateModal,
  },
  data() {
    return {
      wasteFractions: [],
      wasteFractionsLoading: false,
      disposalCategories: [],
      disposalCategoriesLoading: false,
      disposalCategoryCreateModal: false,
      updatedWasteFractionMap: {},
    };
  },
  computed: {
    disposalCategoryOptions() {
      return this.disposalCategories.map((cat) => ({
        label: cat.name,
        value: cat.id,
      }));
    },
    hasFractionChanges() {
      return Object.keys(this.updatedWasteFractionMap).length;
    },
  },
  async mounted() {
    await this.fetchWasteFractions();
    await this.fetchDisposalCategories();
  },
  methods: {
    async fetchWasteFractions() {
      try {
        this.wasteFractionsLoading = true;
        const { data } = await WasteFractionService.getAll();
        this.wasteFractions = data;
        this.wasteFractionsLoading = false;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchDisposalCategories() {
      try {
        this.disposalCategoriesLoading = true;
        const { data } = await DisposalCategoryService.getAll();
        this.disposalCategories = data;
        this.disposalCategoriesLoading = false;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async onCreate() {
      this.disposalCategoryCreateModal = false;
      await this.fetchDisposalCategories();
    },
    async onSaveMappings() {
      this.$ntf.info('Gemmer nye tildelinger');
      this.wasteFractionsLoading = true;
      this.disposalCategoriesLoading = true;

      try {
        await WasteFractionService.assignDisposalCategories({
          mappings: Object.values(this.updatedWasteFractionMap),
        });
        await this.fetchWasteFractions();
        await this.fetchDisposalCategories();
        this.updatedWasteFractionMap = {};
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.wasteFractionsLoading = false;
        this.disposalCategoriesLoading = false;
      }
    },
    async onDelete(id, name) {
      try {
        await this.$confirm({
          title: 'Slet tømmegruppe',
          bodyText: `Vil du slette ${name}?`,
        });
        await this.deleteConfirmed(id);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async deleteConfirmed(id) {
      this.wasteFractionsLoading = true;
      this.disposalCategoriesLoading = true;
      try {
        await DisposalCategoryService.delete(id);
        await this.fetchWasteFractions();
        await this.fetchDisposalCategories();
        this.$ntf.success('Tømmegruppen blev slettet');
      } catch (err) {
        this.$unhandledError(err);
      } finally {
        this.wasteFractionsLoading = false;
        this.disposalCategoriesLoading = false;
      }
    },
    onMapping(evt, wc) {
      // eslint-disable-next-line no-param-reassign
      wc.disposal_category = evt.value;
      this.updatedWasteFractionMap[wc.id] = {
        waste_fraction_id: wc.id,
        disposal_category_id: evt.value,
      };

      // since vue is not reactivy on object property changes
      // we trigger a reactivy change by assigning a new object with all the old entries
      this.updatedWasteFractionMap = { ...this.updatedWasteFractionMap };
    },
  },
};
</script>

<template>
  <div class="w-full relative">
    <secondary-nav-bar>
      <secondary-nav-bar-item
        v-for="tab in tabs"
        :key="tab.destination"
        :label="tab.label"
        :hidden="tab.hidden"
        :show-warning="tab.showWarning"
        :show-new="tab.showNew"
        :destination="tab.destination"
      ></secondary-nav-bar-item>
      <button
        v-if="hasPlanned && !isLightWorkspace"
        slot="extra"
        class="font-semibold"
        @click="setDistributionModalOpen(true)"
      >
        <span>Optælling</span>
      </button>
    </secondary-nav-bar>

    <no-tasks-view v-if="!hasAnyTasks"></no-tasks-view>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import SecondaryNavBar from '@/layout/SecondaryNavBar.vue';
import SecondaryNavBarItem from '@/layout/SecondaryNavBarItem.vue';
import NoTasksView from '@/components/NoTasksView.vue';

import { mapMutations, mapGetters } from 'vuex';
import { SET_DISTRIBUTION_MODAL_OPEN } from '@/store/mutations.type';

export default {
  name: 'PlanView',
  components: {
    SecondaryNavBar,
    SecondaryNavBarItem,
    NoTasksView,
  },
  computed: {
    ...mapGetters('status', ['hasPlanned', 'hasUnplannedTasks', 'hasAnyTasks']),
    ...mapGetters('departments', ['isInTransitionPeriod', 'isLightWorkspace']),
    tabs() {
      return [
        {
          label: 'Rutebygning',
          destination: 'build-routes',
          hidden: this.isInTransitionPeriod || this.isLightWorkspace,
          showWarning: this.hasUnplannedTasks,
        },

        {
          label: 'Detaljer',
          destination: 'transition-period-details',
          hidden: !this.isInTransitionPeriod,
        },
        {
          label: 'Kort',
          destination: 'map',
        },
        {
          label: 'Mellemtømningsopgaver',
          destination: 'transition-period-tasks',
          hidden: !this.isInTransitionPeriod,
        },
        {
          label: 'Opgaver',
          destination: 'tasks',
          hidden: this.isInTransitionPeriod,
        },
        {
          label: 'Ruteviser',
          destination: 'route-viewer',
          hidden: false,
          showWarning: this.hasUnplannedTasks,
          showNew: true,
        },
      ];
    },
  },
  methods: {
    ...mapMutations('planning', {
      setDistributionModalOpen: SET_DISTRIBUTION_MODAL_OPEN,
    }),
  },
};
</script>

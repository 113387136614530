<template>
  <router-link v-if="!hidden" :to="{ name: destination }" class="flex">
    <span>{{ label }}</span>
    <icon-exclamation v-if="showWarning" class="h-6 w-6 text-yellow-700 ml-1.5"></icon-exclamation>
    <base-badge v-if="showNew" class="ml-2" color="green"></base-badge>
  </router-link>
</template>

<script>
export default {
  name: 'SecondaryNavBarItem',
  props: {
    destination: {
      type: String,
      default: '',
      description: 'The named route of the link',
    },
    label: {
      type: String,
      default: '',
      description: 'The text label for the tab',
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    showNew: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.tab-item {
  &.router-link-exact-active {
    @apply text-gray-900 bg-white;

    span {
      @apply block;
    }
  }
}
</style>

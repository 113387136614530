<template>
  <div class="mt-4">
    <the-zonecode-mapping @done="onDoneMapping"></the-zonecode-mapping>

    <div v-if="hasNonGeocoded">
      <base-alert
        type="info"
        title="Mangler geokodning"
        description="Du skal geokode inden du kan anvende automatisk zonekodking, da opgavernes koordinater benyttes til at placere dem i zoner"
        :dismissable="false"
      ></base-alert>
    </div>

    <template v-else>
      <job-poller
        v-if="zoneCodingJobId"
        title="Zonekoder automatisk"
        description="Dette kan tage nogle minutter, hvis der er mange adresser."
        :job-id="zoneCodingJobId"
        :type="jobTypes.ZONECODING"
        @result="onResult"
      ></job-poller>

      <!-- Still some tasks missing -->
      <div v-if="!hasResult && hasNonZonecoded && hasMapped">
        <h5 class="sub-headline mb-4">Kortlæg zonekoder automatisk i dit workspace</h5>
        <base-alert
          v-if="notZonecodedCount > 0"
          type="info"
          :title="`${notZonecodedCount} opgaver mangler zonekodning`"
          description="Vælg en af mulighederne for at zonekode"
          :dismissable="false"
        ></base-alert>

        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-8">
          <geocode-card
            color="green"
            title="Kun manglende"
            description="Zonekoder kun opgaver, som ikke allerede har en zonekode."
            @click.native="startZoneCoding(false)"
          />
          <geocode-card
            color="yellow"
            title="Overskriv alle"
            description="Overskriv alle opgavers zonekoder. Dette overskriver de eksisterende zonekoder du har kortlagt og som stammede fra dit eksisterende datasæt."
            @click.native="startZoneCoding(true)"
          />
        </div>
      </div>

      <!-- Everything is zonecoded -->
      <div v-if="!hasResult && !hasNonZonecoded && hasMapped">
        <image-container asset-name="success" title="Alt data er zonekodet!"></image-container>
      </div>

      <!-- Display result -->
      <image-container v-if="hasResult" asset-name="success" class="mt-4">
        <dl class="sm:divide-y sm:divide-gray-200 mt-4">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Zonekodede opgaver</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.total_tasks}}</span>
              </base-badge>
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">By</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.total_city}}</span>
              </base-badge>
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Land</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.total_land}}</span>
              </base-badge>
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Sommerhus</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.total_summer}}</span>
              </base-badge>
            </dd>
          </div>
        </dl>
      </image-container>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import GeocodeCard from '@/components/geocode/GeocodeCard.vue';

import { ZONECODE_ALL_TASKS, CHECK_ZONECODE_STATUS } from '@/store/actions.type';
import { SET_HAS_NON_ZONECODED } from '@/store/mutations.type';
import JobPoller from '@/components/JobPoller.vue';
import * as jobTypes from '@/util/jobTypes';

import ImageContainer from '@/components/ImageContainer.vue';
import TheZonecodeMapping from '@/components/zonecode/TheZonecodeMapping.vue';

export default {
  name: 'TheZonecodeAutomatic',
  components: {
    ImageContainer,
    GeocodeCard,
    TheZonecodeMapping,
    JobPoller,
  },
  data() {
    return {
      hasResult: false,
      notZonecodedCount: 0,
      hasMapped: false,
      summary: null,
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('jobs', ['zoneCodingJobId']),
    ...mapGetters('status', ['hasNonZonecoded', 'hasNonGeocoded']),
  },
  methods: {
    ...mapMutations('status', {
      setHasNonZonecoded: SET_HAS_NON_ZONECODED,
    }),
    ...mapActions('tasks', {
      zonecodeAllTasks: ZONECODE_ALL_TASKS,
    }),
    ...mapActions('status', {
      checkZonecodeStatus: CHECK_ZONECODE_STATUS,
    }),
    startZoneCoding(overwriteAll) {
      this.$ntf.info('Påbegynder zonekodning..');

      this.zonecodeAllTasks(overwriteAll);
    },
    async onDoneMapping() {
      this.hasMapped = true;
      this.checkZonecodeStatusCount();
    },
    async checkZonecodeStatusCount() {
      try {
        this.loading = true;
        const { not_zonecoded } = await this.checkZonecodeStatus();
        this.notZonecodedCount = not_zonecoded;
        this.loading = false;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onResult(res) {
      this.summary = res;
      this.hasResult = true;

      this.setHasNonZonecoded(false);
    },
  },
};
</script>

<template>
  <div v-loading="loading" class="pb-8 mt-4">
    <div v-if="propertyZonesToMap.length" class="grid-cols-1">
      <base-alert
        title="OBS"
        description="Hvis du kortlægger efter at du har benyttet automatisk zonekodning, skal du også køre automatisk zonekodning igen, da nogle opgaver ellers kan være overskrevet"
        type="info"
        class="my-4"
      ></base-alert>

      <div class="flex font-semibold text-gray-800 mb-2">
        <p class="flex-1 pl-6">Tekstværdi i datasæt</p>
        <p class="flex-1 pl-14">Oversat værdi i NorthArc</p>
      </div>

      <ul>
        <li v-for="zonecode in propertyZonesToMap" :key="zonecode">
          <mapping-line
            :options="propertyZoneOptions"
            :title="zonecode"
            :value="propertyZonesMapped[zonecode]"
            :use-value="true"
            placeholder="Vælg zonekode"
            @selected="onMapping"
          >
            <p
              v-if="!zonecode || zonecode == 'None'"
              slot="title"
              class="text-blue-700"
            >Tomme ejendomsstatusser i datasættet</p>
          </mapping-line>
        </li>
      </ul>

      <div class="flex justify-end mt-4">
        <base-button color="indigo" :disabled="loading" @click="onSubmit">Sæt zonekoder på opgaver</base-button>
      </div>
    </div>

    <dl
      v-if="previouslyMapped.length"
      class="sm:divide-y sm:divide-gray-200 rounded-md pb-8 grid-cols-1"
    >
      <h5 class="sub-headline mb-4">Kortlagte zonekoder i dit workspace</h5>

      <key-value-pair
        value="Oversat værdi i NorthArc"
        label="Tekstværdi i datasæt"
        :show-badge="false"
      ></key-value-pair>
      <key-value-pair
        v-for="pair in previouslyMapped"
        :key="pair.label"
        :value="pair.value"
        :label="`${pair.label == 'None' ? 'Tomme ejendomsstatusser i datasættet' : pair.label}`"
      ></key-value-pair>
    </dl>
  </div>
</template>

<script>
import TaskService from '@/services/tasks.service';
import MappingLine from '@/components/MappingLine.vue';
import KeyValuePair from '@/components/KeyValuePair.vue';
import { mapActions } from 'vuex';
import { SUBMIT_PROPERTYZONE_MAPPINGS } from '@/store/actions.type';

import propertyZoneOptions from '@/tables/combo/property_zone_options';

export default {
  name: 'TheZonecodeMapping',
  components: {
    MappingLine,
    KeyValuePair,
  },
  data() {
    return {
      propertyZoneOptions,
      propertyZonesToMap: [],
      propertyZonesMapped: {},
      loading: false,
      previouslyMapped: [],
    };
  },
  computed: {
    submitDisabled() {
      return this.propertyZonesToMap.length !== Object.keys(this.propertyZonesMapped).length - this.previouslyMapped.length;
    },
  },
  async mounted() {
    await this.fetchPropertyZonesToMap();
    await this.fetchPreviouslyMapped();
  },
  methods: {
    ...mapActions('tasks', {
      submitPropertyZoneMappings: SUBMIT_PROPERTYZONE_MAPPINGS,
    }),

    async fetchPreviouslyMapped() {
      try {
        const { data } = await TaskService.getPreviouslyMappedPropertyZones();

        if (data.previously_mapped_property_zones) {
          Object.keys(data.previously_mapped_property_zones).forEach((key) => {
            // eslint-disable-next-line no-unused-vars
            this.previouslyMapped.push({
              label: key === 'null' ? 'Tomme ejendomsstatusser i datasættet' : key,
              value: this.propertyZoneOptions.find((zone) => zone.value === data.previously_mapped_property_zones[key]).label,
            });
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchPropertyZonesToMap() {
      try {
        const { data } = await TaskService.getDistinctPropertyZones();

        this.propertyZonesToMap = data.property_zones;

        if (!this.propertyZonesToMap.length) {
          this.$emit('done');
        }

        if (data.previously_mapped_property_zones) {
          Object.keys(data.previously_mapped_property_zones).forEach((key) => {
            this.onMapping({ label: key, value: data.previously_mapped_property_zones[key] });
          });
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onMapping({ label, value }) {
      delete this.propertyZonesMapped[label];

      if (value) {
        this.propertyZonesMapped[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.propertyZonesMapped = { ...this.propertyZonesMapped };
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.submitPropertyZoneMappings({ property_zone_mappings: this.propertyZonesMapped });
        this.$ntf.success('Zonerne er nu kortlagt til Northarcs værdier');
        this.propertyZonesToMap = [];
        this.previouslyMapped = [];
        await this.fetchPreviouslyMapped();
        this.$emit('done');
      } catch (error) {
        this.$unhandledError(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style>

</style>

<template>
  <div>
    <div
      v-if="showScrollHelperRight && show"
      class="fixed z-50 top-1/2 transform right-4 -translate-y-1/2"
    >
      <button
        class="bg-indigo-500 text-indigo-900 h-12 w-6 rounded-full flex-center"
        @click="scroll('right', 300)"
      >
        <icon-chev-right class="h-4 w-4 text-white"></icon-chev-right>
      </button>

      <button
        class="bg-indigo-500 text-indigo-900 h-12 w-6 rounded-full flex-center mt-2"
        @click="scroll('right', 1000)"
      >
        <icon-chev-double-right class="h-4 w-4 text-white"></icon-chev-double-right>
      </button>
    </div>

    <div
      v-if="showScrollHelperLeft && show"
      class="fixed z-50 top-1/2 transform left-1 -translate-y-1/2"
    >
      <button
        class="bg-indigo-500 text-indigo-900 h-12 w-6 rounded-full flex-center"
        @click="scroll('left', 300)"
      >
        <icon-chev-right class="h-4 w-4 transform rotate-180 text-white"></icon-chev-right>
      </button>

      <button
        class="bg-indigo-500 text-indigo-900 h-12 w-6 rounded-full flex-center mt-2"
        @click="scroll('left', 1000)"
      >
        <icon-chev-double-left class="h-4 w-4 text-white"></icon-chev-double-left>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableScrollHandles',
  props: {
    id: {
      type: String,
      description: 'Unique DOM identifier the table the scrol handles are attached to',
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // on load the view will be scrolled to the left already
      showScrollHelperLeft: false,
      showScrollHelperRight: true,
    };
  },
  mounted() {
    const el = document.querySelector(`#${this.id}`);

    el.addEventListener('scroll', this.reEvaluateVisibility);

    this.$once('hook:beforeDestroy', () => {
      el.removeEventListener('scroll', this.reEvaluateVisibility);
    });
  },
  methods: {
    scroll(dir, len) {
      const scrollContainer = document.querySelector(`#${this.id}`);
      if (dir === 'right') {
        scrollContainer.scrollTo({
          left: scrollContainer.scrollLeft + len,
          behavior: 'smooth',
        });
      } else {
        scrollContainer.scrollTo({
          left: scrollContainer.scrollLeft - len,
          behavior: 'smooth',
        });
      }

      setTimeout(this.reEvaluateVisibility, 200);
    },
    reEvaluateVisibility() {
      const updatedScrollContainer = document.querySelector(`#${this.id}`);
      this.showScrollHelperLeft = updatedScrollContainer.scrollLeft > 0;
      this.showScrollHelperRight = updatedScrollContainer.offsetWidth + updatedScrollContainer.scrollLeft !== updatedScrollContainer.scrollWidth;
    },
  },
};
</script>

<style>

</style>

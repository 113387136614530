<template>
  <div
    class="inset-0 overflow-hidden pointer-events-none z-auto transition-colors duration-500"
    style="z-index: 9999"
    :class="[
      fixed ? 'fixed' : 'absolute',
      { 'bg-gray-700 bg-opacity-40': closeOnOverlayClick && model },
    ]"
  >
    <div
      class="absolute inset-0 overflow-hidden"
      :class="[
        closeOnOverlayClick && model
          ? 'pointer-events-auto'
          : 'pointer-events-none',
      ]"
      @click="overlayClicked"
    >
      <section class="absolute inset-y-0 right-0 pl-10 flex z-40 opacity-100">
        <transition
          enter-active-class="transform transition ease-in-out duration-500"
          enter-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500"
          leave-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            v-if="model"
            class="w-screen flex ml-24 pointer-events-auto bg-backdrop"
            :class="width"
            @click.stop
          >
            <focus-trap v-model="model">
              <div
                class="flex flex-1 flex-col m-4 mb-4 rounded-md bg-white shadow-xl overflow-y-auto slim-scrollbar"
              >
                <div class="relative py-6 flex-1">
                  <div class="px-4 sm:px-6 h-full flex flex-col">
                    <div class="flex items-start justify-between">
                      <h2 class="text-lg font-medium text-gray-900">
                        <slot name="title">{{ title }}</slot>
                      </h2>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          @click="model = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <!-- Heroicon name: outline/x -->
                          <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <slot />
                  </div>
                </div>
              </div>
            </focus-trap>
          </div>
        </transition>
      </section>
    </div>
  </div>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';

export default {
  name: 'Slider',
  components: {
    FocusTrap,
  },
  props: {
    value: {
      type: Boolean,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'max-w-2xl',
      description: 'Tailwind class to control width of slider',
    },
  },
  data() {
    return {
      focusTrap: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        if (!val) {
          this.$emit('closed');
        }
      },
    },
  },
  mounted() {
    document.addEventListener('keydown', this.hideOnEsc);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.hideOnEsc);
    });
  },
  methods: {
    hideOnEsc(e) {
      if (e.keyCode === 27) {
        this.model = false;
      }
    },
    overlayClicked() {
      if (this.closeOnOverlayClick) {
        this.model = false;
      }
    },
  },
};
</script>

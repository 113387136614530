export const polygon = (latLngList) => ({
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [latLngList.map((latLng) => [latLng.lat, latLng.lng])],
  },
  properties: {},
});

export const point = (latLngList) => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: latLngList,
  },
  properties: {},
});

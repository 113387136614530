<template>
  <div class="border-t border-gray-200">
    <h3 class="flow-root sticky top-0 bg-gray-100 z-10">
      <div
        class="py-4 w-full flex items-center justify-between cursor-pointer text-gray-400 hover:text-gray-500 px-4"
        @click="open = !open"
      >
        <div class="flex items-center w-full">
          <base-checkbox v-model="checkAll" @click.stop></base-checkbox>
          <span class="font-medium flex items-center justify-between text-gray-900 ml-2 w-full">
            <span>{{label}}</span>
            <span
              v-if="value.length"
              class="bg-gray-300 text-xs ml-auto inline-flex justify-center items-center text-gray-700 rounded-md h-5 w-5"
            >{{value.length}}</span>
          </span>
        </div>
        <button type="button" class="ml-4 flex items-center">
          <icon-plus v-if="!open" class="h-5 w-5"></icon-plus>
          <icon-minus v-else class="h-5 w-5"></icon-minus>
        </button>
      </div>
    </h3>

    <div v-if="open">
      <ul class="grid grid-cols-2 px-4">
        <li
          v-for="option in optionsWithoutUnknown"
          :key="option.value"
          class="flex relative items-center py-2"
          @click.stop="toggleValue(option.value)"
        >
          <div class>
            <base-checkbox :value="value.includes(option.value)" @input="toggleValue(option.value)"></base-checkbox>
          </div>
          <span class="text-sm text-gray-500 pl-2">{{option.label}}</span>
        </li>
      </ul>
      <div
        v-if="unknownOption"
        class="py-2 px-2 cursor-pointer focus:outline-none"
        @click.stop="toggleValue(unknownOption.value)"
      >
        <div
          class="flex relative items-center border-t border-indigo-50 rounded-md pt-4 pb-2 px-2 mr-2"
        >
          <base-checkbox
            :value="value.includes(unknownOption.value)"
            @input="toggleValue(unknownOption.value)"
          ></base-checkbox>

          <span class="text-sm text-indigo-900 pl-2">Ukendt</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanningFilterBox',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
      description: 'List of selected values',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    checkAll: {
      get() {
        return this.value.length === this.options.length;
      },
      set(isChecked) {
        const values = isChecked ? this.options.map((x) => x.value) : [];
        this.$emit('input', values);
      },
    },
    optionsWithoutUnknown() {
      return this.options.filter((x) => x.label !== 'Ukendt');
    },
    unknownOption() {
      return this.options.find((x) => x.label === 'Ukendt');
    },
  },
  methods: {
    toggleValue(optionValue) {
      if (this.value.includes(optionValue)) {
        this.$emit(
          'input',
          this.value.filter((option) => option !== optionValue),
        );
      } else {
        this.$emit('input', [...this.value, optionValue]);
      }
    },
  },
};
</script>

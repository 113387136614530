<template>
  <div class="z-max">
    <base-modal
      v-model="model"
      :width="800"
      :is-full-screen="false"
      :close-on-click-outside="true"
      title="Del adgang til tredjepart"
      @closed="$emit('closed')"
    >
      <div>
        <div class="text-gray-800 border-b pb-4">
          <p>Giv læserettigheder til filtre, kort og detaljer for en opgave på kortet. Linket vil ikke give adgang til at rette data eller andre områder af workspacet.</p>

          <p
            class="mt-2"
          >Hvis der er genereret kørelister, vil disse også kunne downloades som en samlet zip-fil</p>
        </div>

        <base-alert
          v-if="workspaceShareToken"
          title="Dette workspace har en aktiv deling"
          type="warning"
          class="mt-4"
        >
          <div class="flex justify-between items-center border bg-gray-100 rounded-md">
            <a :href="link" class="text-gray-900 underline px-4" target="_blank">{{link}}</a>
            <base-button tabindex="1" :inverted="true" color="gray" @click="copyLink">
              <icon-clipboard class="h-5 w-5"></icon-clipboard>
            </base-button>
          </div>
        </base-alert>
        <base-alert v-else title="Dette workspace er endnu ikke delt" type="info" class="mt-4"></base-alert>
      </div>

      <div slot="footer" class="flex justify-end space-x-4">
        <base-button
          tabindex="2"
          color="red"
          :disabled="isDeleteDisabled"
          @click="deleteLinkClicked"
        >Slet link</base-button>
        <base-button
          tabindex="3"
          color="indigo"
          :disabled="isCreateDisabled"
          @click="createLinkClicked"
        >Opret link</base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { CREATE_SHARED_WORKSPACE_LINK, DELETE_SHARED_WORKSPACE_LINK } from '@/store/actions.type';

export default {
  name: 'TheShareMapModal',
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('departments', ['workspaceShareToken']),
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    link() {
      if (!this.workspaceShareToken) {
        return null;
      }

      return `${window.location.origin}/shared/${this.workspaceShareToken}`;
    },
    isDeleteDisabled() {
      return !this.workspaceShareToken;
    },
    isCreateDisabled() {
      return !!this.workspaceShareToken;
    },
  },
  methods: {
    ...mapActions('departments', {
      createLink: CREATE_SHARED_WORKSPACE_LINK,
      deleteLink: DELETE_SHARED_WORKSPACE_LINK,
    }),
    async createLinkClicked() {
      try {
        await this.createLink();
        document.querySelector('.share-control').classList.add('warning');
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async deleteLinkClicked() {
      try {
        await this.deleteLink();
        document.querySelector('.share-control').classList.remove('warning');
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
      this.$ntf.info('Link kopieret');
    },
  },
};
</script>

<template>
  <div
    class="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 bg-gray-100"
  >
    <div class="sm:mx-auto sm:max-w-xs w-full">
      <img class="mx-auto h-24 w-auto mb-8" src="img/brand/logo_small.png" alt="NorthArc" />
      <div class="text-center mt-4 text-gray-800 font-medium">v. {{ version || "2.0.0" }}</div>
    </div>

    <div class="mt-8 sm:mx-auto max-w-xs w-full">
      <form role="form">
        <base-alert
          v-if="invalidLoginMsg"
          class="my-6"
          title="Fejl"
          type="error"
          :description="invalidLoginMsg"
        ></base-alert>

        <base-input
          v-model="$v.loginForm.email.$model"
          label="Email"
          type="email"
          :valid="valid($v.loginForm.email)"
          :invalid="invalid($v.loginForm.email)"
          tabindex="1"
        >
          <div v-if="invalid($v.loginForm.email)" slot="helpBlock">
            <span v-if="!$v.loginForm.email.required">Feltet er påkrævet</span>
            <span v-if="!$v.loginForm.email.email">Ugyldig email</span>
          </div>
        </base-input>

        <div class="mt-6">
          <base-input
            v-model="$v.loginForm.password.$model"
            label="Password"
            type="password"
            :valid="valid($v.loginForm.password)"
            :invalid="invalid($v.loginForm.password)"
            tabindex="2"
            @keyup.enter="submit"
          >
            <div v-if="invalid($v.loginForm.password)" slot="helpBlock">
              <span v-if="!$v.loginForm.password.required">Feltet er påkrævet</span>
            </div>
          </base-input>
        </div>

        <div class="mt-6">
          <base-button
            v-loading="loading"
            color="indigo"
            :block="true"
            :disabled="$v.loginForm.$invalid || loading"
            @click.prevent="submit"
          >Log ind</base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import vuelidateMixin from '@/mixins/vuelidateMixin';

import { LOGIN } from '@/store/actions.type';

export default {
  name: 'Login',
  mixins: [vuelidateMixin],
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      version: process.env.VUE_APP_BUILD_TARGET === 'demo' ? process.env.VUE_APP_DEV_VERSION : process.env.VUE_APP_VERSION,
      year: new Date().getFullYear(),
      invalidLoginMsg: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['loading']),
  },
  methods: {
    submit() {
      this.invalidLoginMsg = '';
      this.$v.$touch();

      if (this.$v.loginForm.$invalid) {
        this.$ntf.error('Ret valideringsfejl i formen og prøv igen');
      } else {
        this.login(this.loginForm)
          .then(({ data }) => {
            if (!data.has_created_password) {
              this.$router.push({ name: 'login-first' });
            } else {
              this.$router.push(this.$router.currentRoute.query.redirect || '/');
            }
          })
          .catch((err) => {
            this.invalidLoginMsg = err === 401 ? 'Forkert e-mailadresse eller password' : 'Hov.. der skete en serverfejl';

            this.$v.$reset();
            this.loginForm.password = '';
          });
      }
    },
    ...mapActions('auth', {
      login: LOGIN,
    }),
  },
  validations: {
    loginForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
};
</script>

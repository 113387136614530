import daLocale from '@/locales/da-DK';

/* eslint-disable import/prefer-default-export */
export const createShapePopupContent = (L, items, count, totalQuantity, singlePolygonHandler, allPolygonHandler, isSharedView) => {
  const container = L.DomUtil.create('div', 'flex flex-col items-start space-y-4');

  const headline = L.DomUtil.create('span', 'text-gray-900 text-lg w-60', container);

  const getPluralTask = (taskCount) => (taskCount === 1 ? 'opgave' : 'opgaver');

  headline.innerHTML = count ? `${count} ${getPluralTask(count)} med ${totalQuantity} spande` : 'Ingen opgaver';

  if (items.length && !isSharedView) {
    const buttonClasses = 'w-full text-center text-gray-900 hover:bg-gray-100 text-xs font-semibold py-2 px-3 border border-gray-300 rounded-md flex justify-between items-center';
    const iconClasses = 'px-1.5 py-1  bg-gray-300 rounded-md flex items-center justify-center text-xs';

    const button = L.DomUtil.create('button', `${buttonClasses} open-grid-button`, container);

    const singleSpan = L.DomUtil.create('span', '', button);
    singleSpan.innerHTML = 'Åben polygon';

    const singleIcon = L.DomUtil.create('div', `${iconClasses} w-16`, button);
    singleIcon.innerHTML = 'Enter';

    L.DomEvent.on(button, 'click', () => {
      singlePolygonHandler(items, count, totalQuantity);
    });

    const allPolygonsButton = L.DomUtil.create('button', buttonClasses, container);

    const allSpan = L.DomUtil.create('span', '', allPolygonsButton);
    allSpan.innerHTML = 'Åben alle polygoner';

    const allIcon = L.DomUtil.create('div', `${iconClasses} w-22`, allPolygonsButton);
    allIcon.innerHTML = 'Ctrl + Enter';

    L.DomEvent.on(allPolygonsButton, 'click', () => {
      allPolygonHandler(items, count, totalQuantity);
    });
  }

  return container;
};

export const createMarkerPopupContent = (L, meta, tasks, isLightWorkSpace) => {
  const container = L.DomUtil.create('div', 'flex flex-col items-start');

  const headline = L.DomUtil.create('span', 'text-gray-900 font-semibold w-56 truncate', container);

  headline.innerHTML = `${meta.address} (${meta.count})`;

  const createKeyValue = (key, value, wrapper) => {
    const div = L.DomUtil.create('div', 'py-2 grid grid-cols-3 gap-4 relative', wrapper);

    const dt = L.DomUtil.create('dt', 'text-xs font-medium text-gray-500', div);
    dt.innerHTML = key;
    const dd = L.DomUtil.create('dd', 'text-xs text-gray-900 col-span-2', div);
    dd.innerHTML = value;
    return div;
  };

  const firstTask = tasks[0];

  const wrapper = L.DomUtil.create('dl', 'bg-white', container);

  const weekValue = firstTask.planned_week_no === -1 ? 'Ukendt' : firstTask.planned_week_no;

  createKeyValue('Køretøj', firstTask.vehicle, wrapper);

  if (!isLightWorkSpace) {
    createKeyValue('Tømmedag', daLocale.disposalDayList(firstTask.planned_disposal_days), wrapper, null);
    createKeyValue('Frekvens', firstTask.frequency, wrapper, null);
    createKeyValue('Startuge', weekValue, wrapper, null);
    createKeyValue('Materiel', firstTask.materiel, wrapper, null);
  }

  if (tasks.length > 1) {
    const extra = L.DomUtil.create('div', '', wrapper);
    extra.innerHTML = `<span class="text-blue-500"> Og ${tasks.length - 1} mere på samme koordinat </span>`;
  }

  return container;
};

export const createMarkerPopupStartLocationContent = (L, startLocation) => {
  const container = L.DomUtil.create('div', 'flex flex-col items-start');

  const headline = L.DomUtil.create('span', 'text-gray-900 font-semibold w-56 truncate', container);

  headline.innerHTML = 'Start Lokation';

  const createKeyValue = (key, value, wrapper) => {
    const div = L.DomUtil.create('div', 'py-2 grid grid-cols-3 gap-4 relative', wrapper);

    const dt = L.DomUtil.create('dt', 'text-xs font-medium text-gray-500', div);
    dt.innerHTML = key;
    const dd = L.DomUtil.create('dd', 'text-xs text-gray-900 col-span-2', div);
    dd.innerHTML = value;
    return div;
  };

  const wrapper = L.DomUtil.create('dl', 'bg-white', container);
  createKeyValue('Adresse', startLocation.address, wrapper);

  return container;
};

export const createMarkerPopupDisposalPointContent = (L, disposalLocation) => {
  const container = L.DomUtil.create('div', 'flex flex-col items-start');

  const headline = L.DomUtil.create('span', 'text-gray-900 font-semibold w-56 truncate', container);

  headline.innerHTML = 'Aflæsningslokation';

  const createKeyValue = (key, value, wrapper) => {
    const div = L.DomUtil.create('div', 'py-2 grid grid-cols-3 gap-4 relative', wrapper);

    const dt = L.DomUtil.create('dt', 'text-xs font-medium text-gray-500', div);
    dt.innerHTML = key;
    const dd = L.DomUtil.create('dd', 'text-xs text-gray-900 col-span-2', div);
    dd.innerHTML = value;
    return div;
  };

  const wrapper = L.DomUtil.create('dl', 'bg-white', container);
  createKeyValue('Adresse', disposalLocation.address, wrapper);

  return container;
};

export const createInfoCommand = (L, self, clickHandler) => {
  const div = L.DomUtil.create('div');
  div.classList.add('custom-leaflet-control');

  const anchor = L.DomUtil.create('a');

  // svg: assets/images/hotkeys_icon.svg
  anchor.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-5 h-5 text-gray-900" fill="currentColor">
                        <path style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans"
                         d="M 3 7 C 1.9069372 7 1 7.9069372 1 9 L 1 23 C 1 24.093063 1.9069372 25 3 25 L 29 25 C 30.093063 25 31 24.093063 31 23 L 31 9 C 31 7.9069372 30.093063 7
                          29 7 L 3 7 z M 3 9 L 29 9 L 29 23 L 3 23 L 3 9 z M 5 11 L 5 13 L 7 13 L 7 11 L 5 11 z M 9 11 L 9 13 L 11 13 L 11 11 L 9 11 z M 13 11 L 13 13 L 15 13 L 15 11
                           L 13 11 z M 17 11 L 17 13 L 19 13 L 19 11 L 17 11 z M 21 11 L 21 13 L 23 13 L 23 11 L 21 11 z M 25 11 L 25 13 L 27 13 L 27 11 L 25 11 z M 5 15 L 5 17 L 9 17 L 9 15 L 5 15 z M 11 15 L 11 17 
                           L 13 17 L 13 15 L 11 15 z M 15 15 L 15 17 L 17 17 L 17 15 L 15 15 z M 19 15 L 19 17 L 21 17 L 21 15 L 19 15 z M 23 15 L 23 17 L 27 17 L 27 15 L 23 15 z M 5 19 L 5 21 L 9 21 L 9 19 L 5 19 z 
                           M 11 19 L 11 21 L 21 21 L 21 19 L 11 19 z M 23 19 L 23 21 L 27 21 L 27 19 L 23 19 z"/>
                      </svg>`;

  div.appendChild(anchor);

  L.DomEvent.on(div, 'click', clickHandler, self);

  return div;
};

export const createShareCommand = (L, self, clickHandler) => {
  const div = L.DomUtil.create('div');
  div.classList.add('custom-leaflet-control');
  div.classList.add('share-control');
  const anchor = L.DomUtil.create('a');

  // const isActiveColor = isActive ? 'text-green-500' : 'text-yellow-500';

  // svg: assets/images/share_icon.svg
  anchor.innerHTML = `<svg class="w-5 h-5 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0
                       2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path></svg>`;

  // svg: assets/images/hotkeys_icon.svg

  div.appendChild(anchor);

  L.DomEvent.on(div, 'click', clickHandler, self);

  return div;
};

export const createLayerTogglerCommand = (L, self, clickHandler) => {
  const div = L.DomUtil.create('div');
  div.classList.add('custom-leaflet-control');

  const anchor = L.DomUtil.create('a');

  // heroicons: 'map'
  anchor.innerHTML = '<svg fill="none" class="w-5 h-5 text-gray-900"  stroke="#464646" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"></path></svg>';

  div.appendChild(anchor);

  L.DomEvent.on(div, 'click', clickHandler, self);

  return div;
};

<template>
  <base-modal
      v-model="isOpen"
      :title="'Versioner'"
      size="md"
      @close="onClose"
  >
    <div class="p-4 space-y-6">
      <!-- Unsaved Changes Warning -->
      <div v-if="hasUnsavedChanges" class="p-4 rounded-md bg-yellow-50 border border-yellow-200 text-yellow-700">
        Du har ikke gemt ændringer i den nuværende rute. Ved at skifte version mister du de ændringer.
        <span class="font-medium">Er du sikker på, at du vil fortsætte?</span>
      </div>

      <!-- Version List -->
      <div v-if="versions.length" class="space-y-4">
        <div class="text-sm text-gray-600 mb-4">
          Vælg en version af ruten for at skifte til den eller oprette en kopi af den.
        </div>

        <div class="space-y-2">
          <div
              v-for="version in versions"
              :key="version.id"
              class="flex items-center justify-between p-3 rounded-lg border hover:bg-gray-50 cursor-pointer"
              :class="{
                'border-indigo-500 bg-indigo-50': selectedVersion === version,
                'bg-green-50 hover:bg-green-100': version.id === route.id
              }"
              @click="selectVersion(version)"
          >
            <div class="flex items-center space-x-3">
              <div class="flex-shrink-0">
                <svg v-if="version.isOriginal" class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                <svg v-else class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </div>
              <div>
                <div class="font-medium text-gray-900">{{ version.label }}</div>
                <div class="text-sm text-gray-500">Oprettet {{ formatDate(version.createdAt) }}</div>
              </div>
            </div>

            <button
                v-if="!version.isOriginal"
                class="text-gray-400 hover:text-gray-500"
                @click.stop="confirmDeleteVersion(version)"
            >
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- No Versions Message -->
      <div v-else class="text-center py-6">
        <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">Ingen versioner fundet</h3>
        <p class="mt-1 text-sm text-gray-500">Der er ikke oprettet nogen versioner af denne rute.</p>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between px-4 py-3 bg-gray-50 sm:px-6 rounded-b-lg space-x-4">

          <base-button
              v-if="selectedVersion"
              type="button"
              color="indigo"
              @click="applyVersion"
          >
            Anvend version
          </base-button>

          <base-button
              v-if="selectedVersion"
              type="button"
              color="indigo"
              @click="createVersion"
          >
            Gem ny version
          </base-button>

      </div>
    </template>

    <!-- Delete Confirmation -->
    <div v-if="showDeleteConfirmation" class="mx-4 p-4 w-[432px] rounded-lg bg-red-50 border border-red-200 flex items-center space-x-2">
      <p class="text-sm text-gray-600">
        Er du sikker på, at du vil slette denne version?
      </p>
      <div class="flex justify-end space-x-3">
        <base-button
          type="button"
          color="gray"
          class="border rounded-md text-gray-700 hover:bg-gray-100"
          @click="showDeleteConfirmation = false"
        >
          Annuller
        </base-button>
        <base-button
            type="button"
            color="red"
            @click="deleteVersion"
        >
          Slet
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { CHANGE_VERSION, CREATE_VERSION, DELETE_VERSION, LOAD_VERSIONS } from '@/store/actions.type';
import { mapState } from 'vuex';

export default {
  name: 'RouteVersionsModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVersion: null,
      loading: false,
      showDeleteConfirmation: false,
      versionToDelete: null,
    };
  },
  computed: {
    ...mapState('route', ['route', 'versions', 'hasUnsavedChanges']),
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    isOpen(open) {
      if (open) {
        this.loadVersions();
      }
    },
  },
  methods: {
    async loadVersions() {
      try {
        this.loading = true;
        await this.$store.dispatch(`route/${LOAD_VERSIONS}`, this.route.id);
      } catch (error) {
        this.$ntf.error('Kunne ikke hente ruteversionerne');
        console.error('Error loading versions:', error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('da-DK', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    selectVersion(version) {
      this.selectedVersion = version;
    },
    async applyVersion() {
      if (!this.selectedVersion) return;

      try {
        // Update current version
        await this.$store.dispatch(`route/${CHANGE_VERSION}`, this.selectedVersion.id);

        this.$ntf.success(`Du har skiftet version til ${this.route.label}`);

        this.onClose();
      } catch (error) {
        console.error('Error applying version:', error);
        this.$ntf.error('Kunne ikke skifte til den valgte version');
      }
    },
    confirmDeleteVersion(version) {
      this.versionToDelete = version;
      this.showDeleteConfirmation = true;
    },
    async deleteVersion() {
      await this.$store.dispatch(`route/${DELETE_VERSION}`, this.versionToDelete.id);
      this.showDeleteConfirmation = false;
      this.versionToDelete = null;
      await this.loadVersions();
      this.$ntf.success('Rute blev slettet');
    },
    async createVersion() {
      if (!this.selectedVersion.id) {
        this.$ntf.error('Ingen rute at gemme');
        return;
      }

      try {
        this.saving = true;
        await this.$store.dispatch(`route/${CREATE_VERSION}`, this.selectedVersion.id);

        this.$ntf.success(`Ny version af ${this.selectedVersion.label} blev gemt`);

        this.$emit('version-saved');
        this.onClose();
      } catch (error) {
          const errorMessage = error?.data?.error || 'Kunne ikke gemme versionen';

          if (errorMessage.includes('max have 3 versioner')) {
            this.$ntf.error('Du kan maksimalt have 3 versioner af en rute. Slet en eksisterende version for at gemme en ny.');
          } else {
            this.$ntf.error(errorMessage);
          }

          console.error('Error saving version:', error);
      } finally {
        this.saving = false;
      }
    },
    onClose() {
      this.selectedVersion = null;
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.version-card {
  transition: all 0.2s ease;
}

.version-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}
</style>

<template>
  <slider v-model="model" width="max-w-lg" title="Opret frekvens" :close-on-overlay-click="true">
    <div class="my-8">
      <h1>Ønsker du at oprette en frekvens kan du kontakte os på en af følgende emails:</h1>

      <ul class="list-disc list-inside mt-6">
        <li>kontakt@northarc.dk</li>
        <li>nbs@northarc.dk</li>
        <li>clh@northarc.dk</li>
        <li>jhk@northarc.dk</li>
      </ul>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';

import vuelidateMixin from '@/mixins/vuelidateMixin';

export default {
  name: 'FrequencyCreateSlider',
  components: {
    Slider,
  },
  mixins: [vuelidateMixin],
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
  },
};
</script>

<template>
  <div>
    <h2
      v-if="missingMappings.length"
      class="leading-6 font-medium text-gray-800 mt-6 mb-2"
    >Vigtige felter uden opsætning</h2>

    <ul>
      <li v-for="missing in missingMappings" :key="missing" class="space-y-2">
        <base-badge color="blue" :text="missing"></base-badge>
      </li>
    </ul>

    <h2
      v-if="missingFirstRowValues.length"
      class="leading-6 font-medium text-gray-800 mt-6 mb-2"
    >Felter med tomme værdier i første række i filen</h2>

    <ul>
      <li
        v-for="missingFirstRowVal in missingFirstRowValues"
        :key="missingFirstRowVal"
        class="space-y-2"
      >
        <base-badge color="yellow" :text="missingFirstRowVal"></base-badge>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ImportConfigurationValidation',
  props: {
    missingMappings: {
      type: Array,
      default: () => [],
    },
    missingFirstRowValues: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>

</style>

<template>
  <div id="taskTable" class="pb-4">
    <table-action-bar
      :title="tableTitle"
      :show-next="pagination.hasNext"
      :show-previous="pagination.hasPrevious"
      :loading="loading"
      :show-download="!isSelectionMode"
      @next="onNext"
      @previous="onPrevious"
      @download="downloadAll"
    >
      <filter-menu
        v-model="activeFilters"
        :config="config"
        :loading="loading"
        @update="() => onCursor(null, true)"
      ></filter-menu>

      <div slot="right-buttons" class="flex-center mr-4">
        <button-selection-list
          :value="pageSize"
          class="ml-4"
          :is-disabled="loading"
          :buttons="pageSizeOptions"
          @input="setPageSize"
        ></button-selection-list>
      </div>
    </table-action-bar>

    <base-alert
      v-if="bulkUpdateError"
      class="my-4"
      type="error"
      title="Fejl under opdatering"
      :description="bulkUpdateError"
      :dismissable="true"
    ></base-alert>

    <!--bulk select-->
    <template v-if="loading || containsItems">
      <div
        v-if="bulkSelected"
        class="flex space-x-1 mb-2 text-gray-700 border rounded-md bg-white p-4"
      >
        <span>Alle</span>
        <span class="font-medium">{{pagination.total}}</span>
        <span>opgaver i denne søgning er valgt.</span>
        <button class="underline" @click="resetSelection">Ryd valgte</button>
      </div>
      <div
        v-else-if="pagination.data && selectedData.length === pagination.data.length && containsItems && pagination.total > pageSize"
        class="flex space-x-1 mb-2 text-gray-700 border rounded-md bg-white p-4"
      >
        <!-- This one does not load after new selection -->
        <span>Alle</span>
        <span class="font-medium">{{pagination.data.length}}</span>
        <span>opgaver på denne side er valgt.</span>
        <button
          class="underline"
          @click="bulkSelected = true"
        >Vælg alle {{pagination.total}} i denne søgning</button>
      </div>

      <div id="theTable" v-loading="loading" class="relative overflow-auto rounded-md">
        <table-scroll-handles id="theTable" :show="(!!containsItems && !hideScrollHelpers)"></table-scroll-handles>

        <table v-if="containsItems" class="min-w-full rounded-md over">
          <thead class="bg-gray-50 sticky -top-1 border-t z-50">
            <tr v-if="pagination.data">
              <table-checkbox v-if="!isSharedView"
                :is-th="true"
                class="bg-gray-50 rounded-tl-md"
                :is-checked="indeterminate || selectedData.length === pagination.data.length"
                :indeterminate="indeterminate"
                @change="onSelectAll($event)"
              ></table-checkbox>

              <table-header
                v-for="(column, index) in config.columns"
                :key="column.field"
                :is-sticky="index === 0"
                :bulk-count="bulkEditCount"
                :sort-field="sortField"
                :sort-dir="sortDir"
                :column="column"
                :is-read-only="isSharedView"
                :show-bulk-fields="selectedData.length > 0 && index === 0"
                @sort="onSort($event)"
                @showBulkEdit="bulkEditActive = !bulkEditActive"
                @deleteBulk="onDeleteBulk"
              ></table-header>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(data, rowIndex) in pagination.data" :key="data.id">
              <table-checkbox
                  v-if="!isSharedView"
                :is-th="false"
                :class="{'bg-gray-50': rowIndex % 2 != 0, 'bg-white': rowIndex % 2 == 0}"
                :is-checked="selectedData.includes(data.id)"
                @change="onSelectedData($event, data.id)"
              ></table-checkbox>

              <td
                v-for="(column, index) in config.columns"
                :key="column.field"
                :title="data[column.field]"
                class="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-left font-medium text-gray-900 sm:pl-6 overflow-ellipsis overflow-hidden"
                :class="{'sticky-col second-col': (!isSharedView && index === 0), 'sticky-col shared-first-col': (isSharedView && index === 0), 'cursor-pointer hover:bg-gray-200': column.editor, 'bg-gray-50': rowIndex % 2 != 0, 'bg-white': rowIndex % 2 == 0}"
                @click="(evt) => onStartCellEdit(column, data[column.field], data.id, evt)"
              >{{ renderValue(data, column) }}</td>
            </tr>
          </tbody>
        </table>
        <cell-editor :editing-cell="editingCell" @updated="onCellUpdate"></cell-editor>
      </div>
    </template>

    <empty-table-view v-else :show-clear-filters="true" @clearFilter="clearFilters"></empty-table-view>

    <bulk-edit-form
      v-model="bulkEditActive"
      :loading="loading"
      :vehicle-options="vehicleOptions"
      :planned-week-no-options="plannedWeekNoFilterOptions"
      :property-zone-options="propertyZoneOptions"
      :planned-disposal-day-options="plannedDisposalDayOptions"
      :count="bulkEditCount"
      :is-updating-all="(!isSelectionMode && bulkEditCount == pagination.total) && !activeFilters.length"
      @submit="onEditBulk"
    ></bulk-edit-form>
  </div>
</template>

<script>
import TaskService from '@/services/tasks.service';

import BulkEditForm from '@/components/table/BulkEditForm.vue';

import {
  ALL_VEHICLES,
  DELETE_TASKS_BULK,
  DOWNLOAD_TASKS,
  GET_FREQUENCY_OPTIONS,
  GET_MOST_IRREGULAR_FREQUENCY,
  SHARED_ALL_VEHICLES, SHARED_GET_FREQUENCY_OPTIONS, SHARED_GET_MOST_IRREGULAR_FREQUENCY,
  SUBMIT_BULK_UPDATE,
} from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

import ButtonSelectionList from '@/components/ButtonSelectionList.vue';
import baseTableMixin from '@/components/table/baseTableMixin';
import plannedDisposalDayOptions from '@/tables/combo/disposal_day_options';
import propertyZoneOptions from '@/tables/combo/property_zone_options';

import taskConfig from '@/components/table/configs/taskList';
import lightWorkspaceConfig from '@/components/table/configs/lightWorkspaceTaskList';
import transitionPeriodConfig from '@/components/table/configs/transitionPeriodTaskList';

import SharedService from '@/services/shared.service';

export default {
  name: 'TaskTable',
  components: {
    BulkEditForm,
    ButtonSelectionList,
  },
  mixins: [baseTableMixin],
  props: {
    selectedTasks: {
      type: Array,
      default: () => [],
    },
    isSelectionMode: {
      type: Boolean,
      default: false,
    },
    isTransitionPeriod: {
      type: Boolean,
      default: false,
    },
    isSharedView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {},
      plannedDisposalDayOptions,
      propertyZoneOptions,
      bulkUpdateError: '',
    };
  },
  computed: {
    ...mapGetters('vehicles', ['vehicleOptions']),
    ...mapGetters('frequencies', ['plannedWeekNoFilterOptions', 'frequencyOptions']),
    ...mapGetters('departments', ['isLightWorkspace']),
  },
  watch: {
    selectedTasks: {
      handler: 'fetchData',
    },
  },
  created() {
  if (this.isLightWorkspace) {
    this.config = lightWorkspaceConfig;
  } else {
    this.config = this.isTransitionPeriod ? transitionPeriodConfig : taskConfig;
  }
  if (this.isSharedView) {
    this.config.asReadOnly();
  }
},

  async mounted() {
    if (!this.isSelectionMode) {
      await this.fetchData();
    }
    await this.loadFilterData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const data = await this.loadData(null, [`page_size=${this.pageSize}`]);
      this.pagination = data;
      this.loading = false;

      this.onSelectAll(true);
      this.bulkSelected = true;
    },
    ...mapActions('vehicles', {
      getAllVehicles: ALL_VEHICLES,
    }),
    ...mapActions('frequencies', {
      getFrequencies: GET_FREQUENCY_OPTIONS,
      getMostIrregularFrequency: GET_MOST_IRREGULAR_FREQUENCY,
    }),
    ...mapActions('shared', {
      sharedGetAllVehicles: SHARED_ALL_VEHICLES,
      sharedGetMostIrregularFrequency: SHARED_GET_MOST_IRREGULAR_FREQUENCY,
      sharedGetFrequencyOptions: SHARED_GET_FREQUENCY_OPTIONS,
    }),
    ...mapActions('tasks', {
      submitBulkUpdate: SUBMIT_BULK_UPDATE,
      submitBulkDelete: DELETE_TASKS_BULK,
      downloadTasks: DOWNLOAD_TASKS,
    }),
    async downloadAll() {
      this.$ntf.info('Påbegynder download af opgaver');
      this.loading = true;
      try {
        this.downloadTasks();
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.loading = false;
      }
    },
    async loadFilterData() {
      try {
        if (this.isSharedView) {
          await this.sharedGetAllVehicles();
          await this.sharedGetMostIrregularFrequency();
          await this.sharedGetFrequencyOptions();
        } else {
          await this.getAllVehicles();
          await this.getMostIrregularFrequency();
          await this.getFrequencies();
        }
        this.config.vehicleOptions = this.vehicleOptions;
        this.config.plannedWeekNoOptions = this.plannedWeekNoFilterOptions;
        this.config.frequencyOptions = this.frequencyOptions;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async loadData(cursor, params) {
      this.bulkUpdateError = '';
      if (this.isSharedView) {
          const { data } = await SharedService.getCursor(cursor, params, this.selectedTasks);
          return data;
      }
      const { data } = await TaskService.getCursor(cursor, params, this.selectedTasks);
      return data;
    },
    async onEditBulk({ payload, isMultiDisposalDay }) {
      this.loading = true;

      const formatPlannedDisposalDays = () => {
        if (isMultiDisposalDay) {
          return payload.multi_planned_disposal_day || [];
        }
        return payload.planned_disposal_day ? [payload.planned_disposal_day] : [];
      };

      const formattedPayload = {
        property_zone: payload.property_zone || null,
        vehicle_id: payload.vehicle || null,
        planned_week_no: payload.planned_week_no || null,
        planned_disposal_days: formatPlannedDisposalDays(),
      };

      try {
        await this.submitBulkUpdate({
          queryParams: this.getFilterParams(),
          taskIds: this.getTaskIdsForBulkOperation(),
          isBulk: this.getIsBulkForBulkOperation(),
          payload: formattedPayload,
          updateType: isMultiDisposalDay ? 'multi' : 'single',
        });

        this.$ntf.success('Opgaverne blev opdateret!');
        await this.onCursor(null, true, true);
      } catch (error) {
        this.bulkUpdateError = error?.data?.error || error?.data || error;
        this.$ntf.error('Rækkerne kunne ikke opdateres');
      } finally {
        this.loading = false;
        this.bulkEditActive = false;
        this.emitHasUpdated();
      }
    },
    getTaskIdsForBulkOperation() {
      if (this.isSelectionMode) {
        // In selectionMode we always send a subset of the tasks
        return this.bulkSelected ? this.selectedTasks : this.selectedData;
      }

      // In none-selection mode we send the isBulk flag so here we can send an empty array
      // because we are not working with a subset
      return this.bulkSelected ? [] : this.selectedData;
    },
    getIsBulkForBulkOperation() {
      // In selectionMode on the we never pass isBulk because we are working on a subset of all the tasks
      // So if we set isBulk we might accidently update tasks outside of the selection
      // If selectionMode always false else just check the setting
      return this.isSelectionMode ? false : this.bulkSelected;
    },
    async onDeleteBulk() {
      const selectedLen = this.bulkEditCount;

      try {
        await this.$confirm({
          htmlContainerId: 'taskTable',
          title: 'Slet valgte opgaver',
          bodyText: `Vil du slette ${selectedLen} opgaver?`,
        });
        await this.deleteBulkConfirmed();
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async deleteBulkConfirmed() {
      this.$ntf.info('Påbegynder sletning');
      this.loading = true;

      try {
        await this.submitBulkDelete({
          queryParams: this.getFilterParams(),
          taskIds: this.getTaskIdsForBulkOperation(),
          isBulk: this.getIsBulkForBulkOperation(),
        });

        this.$ntf.success('Opgaverne blev slettet');
        this.resetSelection();
        await this.onCursor(null, true, true);
      } catch (err) {
        this.$unhandledError(err);
      } finally {
        this.emitHasUpdated();
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
}

.first-col {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  left: 0px;
  z-index: 20;
}

.shared-first-col {
  width: 300px;
  min-width: 300px;
  max-width:300px;
  left: 0px;
  z-index: 20;
}

.second-col {
  width: 300px;
  min-width: 300px;
  max-width:300px;
  left: 60px;
  z-index: 20;
}

table {
  width: 100%;
  text-align: center;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

table th {
  /* Apply both top and bottom borders to the <th> */
  border-top: 1px solid rgb(226, 232, 240);
  border-bottom: 1px solid rgb(226, 232, 240);
  border-right: 1px solid rgb(226, 232, 240);
  /*padding:4px;*/
}

table td {
  /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
  border-bottom: 1px solid rgb(226, 232, 240);
  border-right: 1px solid rgb(226, 232, 240);
}

table th:first-child,
table td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid rgb(226, 232, 240);
}

table thead th {
  position: sticky;
  top: 0;
}

/*https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
https://jsfiddle.net/Abeeee/83cfbu7d/3/*/

</style>

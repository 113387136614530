<template>
  <image-container asset-name="empty" title="Ingen resultater fundet">
    <filter-clear-button v-if="showClearFilter" class="mt-4" @click.native="$emit('clearFilter')"></filter-clear-button>
  </image-container>
</template>

<script>
import ImageContainer from '@/components/ImageContainer.vue';
import FilterClearButton from '@/components/table/FilterClearButton.vue';

export default {
  name: 'EmptyTableView',
  components: {
    ImageContainer,
    FilterClearButton,
  },
  props: {
    showClearFilter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>

</style>

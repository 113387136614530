<template>
  <div class="pt-6 relative w-full">
    <div class="flex items-center justify-between">
      <span class="text-sm text-indigo-600">{{geocoded}} / {{total}}</span>
      <div class="text-right">
        <span class="text-xs font-semibold inline-block text-indigo-600">{{progressPercent}}%</span>
      </div>
    </div>
    <div class="overflow-hidden h-2 mb-4 mt-2 text-xs flex rounded bg-indigo-200">
      <div
        :style="{ 'width': `${progressPercent}%`}"
        class="shadow-none flex flex-col transition-w text-center whitespace-nowrap text-white justify-center bg-indigo-500"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { CHECK_GEOCODE_STATUS } from '@/store/actions.type';

export default {
  name: 'TheGeocodeProgressPoller',
  data() {
    return {
      progressPercent: 0,
      total: 0,
      geocoded: 0,
    };
  },
  async mounted() {
    this.queryGeocodeStatus();

    const interval = setInterval(this.queryGeocodeStatus, 5000);

    this.$once('hook:beforeDestroy', () => {
      clearInterval(interval);
    });
  },
  methods: {
    ...mapActions('status', {
      checkGeocodeStatus: CHECK_GEOCODE_STATUS,
    }),
    async queryGeocodeStatus() {
      try {
        const { geocoded, total } = await this.checkGeocodeStatus();

        if (total === 0) {
          this.progressPercent = 0;
        } else {
          this.progressPercent = ((geocoded / total) * 100).toFixed(2);
        }

        this.total = total;
        this.geocoded = geocoded;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

<style>

.transition-w {
  /* Set this duration to the same as our polling time */
  transition: width 5s;
}

</style>

<template>
  <div
    class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col items-start space-x-3"
  >
    <div class="flex items-center">
      <icon-truck class="h-6 w-6 text-gray-700 mr-4"></icon-truck>

      <h3 class="font-medium text-indigo-600">{{vehicleDrivingLists.name}}</h3>
    </div>

    <ul class="block mt-4 w-full">
      <div class="overflow-hidden bg-white w-full">
        <div v-if="!vehicleDrivingLists.driving_lists.length">
          <p class="text-gray-800 font-medium">Ingen kørelister oprettet</p>
        </div>

        <ul role="list" class="divide-y divide-gray-200">
          <li
            v-for="drivingListItem in vehicleDrivingLists.driving_lists"
            :key="drivingListItem.id"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex-1 flex items-start flex-col">
                <p
                  class="text-gray-800"
                >{{getDrivingListName(vehicleDrivingLists.name, drivingListItem)}}</p>
                <p
                  class="text-sm text-gray-500"
                >Oprettet {{drivingListItem.updated_at | longDateTime }}</p>
              </div>
              <div class="w-32">
                <span
                  :class="drivingListItem.format == 'PDF' ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'"
                  class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium"
                >{{drivingListItem.format}}</span>
              </div>
              <div v-if="!isLightWorkspace" class="w-48">
                <span
                  :class="drivingListItem.type == DRIVING_LIST_TYPES.ONLY_FULL_YEAR ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'"
                  class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium"
                >{{drivingListItem.type == 'ONLY_FULL_YEAR' ? 'Kun helårs' : 'Alle inkl. kun sommer'}}</span>
              </div>
              <div class="ml-5 flex-shrink-0">
                <button
                  class="h-10 w-10 rounded-full bg-gray-100 flex-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  @click="$emit('on-download', drivingListItem.id)"
                >
                  <icon-download class="text-gray-600 h-4 w-4"></icon-download>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</template>

<script>
import DRIVING_LIST_TYPES from '@/util/drivingListTypes';

import { mapGetters } from 'vuex';

export default {
  name: 'DrivingListItem',
  props: {
    vehicleDrivingLists: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DRIVING_LIST_TYPES,
    };
  },
  computed: {
    ...mapGetters('departments', ['isLightWorkspace']),
  },
  methods: {
    getDrivingListName(name, drivingList) {
      const fileExtension = drivingList.format === 'PDF' ? '.pdf' : '.zip';

      if (this.isLightWorkspace) {
        return `${name.toLowerCase()}${fileExtension}`;
      }

      const type = drivingList.type === DRIVING_LIST_TYPES.ONLY_FULL_YEAR ? 'kun_helårs' : 'alle_inkl_kun_summer';

      return `${name.toLowerCase()}_${type}${fileExtension}`;
    },
  },
};
</script>

<style>

</style>

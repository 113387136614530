/* eslint-disable no-underscore-dangle */
class LightWorkspaceTaskListConfig {
  _vehicleOptions = [];

  set vehicleOptions(options) {
    this._vehicleOptions = options;
  }

  get vehicleOptions() {
    return this._vehicleOptions;
  }

  columns = [
    {
      label: 'Adresse',
      field: 'address',
      type: 'string',
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '300px',
    },
    {
      label: 'Antal',
      field: 'quantity',
      type: 'number',
      sortable: true,
      filterable: true,
      editor: false,
      minWidth: '100px',
    },
    {
      label: 'Køretøj',
      field: 'vehicle',
      type: 'select',
      options: () => this.vehicleOptions,
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: false,
      minWidth: '150px',
    },
    {
      label: 'Postnr.',
      field: 'zip_code',
      type: 'string',
      sortable: true,
      filterable: true,
      commonFilter: true,
      editor: true,
      minWidth: '120px',
    },
    {
      label: 'By',
      field: 'city',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Vej',
      field: 'address_road',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Nr',
      field: 'address_number',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '60px',
    },
    {
      label: 'Dør',
      field: 'address_door_letter',
      type: 'string',
      sortable: true,
      filterable: true,
      editor: true,
      minWidth: '60px',
    },
    {
      label: 'Bemærkning',
      field: 'comment',
      sortable: true,
      filterable: false,
      editor: true,
      minWidth: '200px',
    },
    {
      label: 'Længdegrad',
      field: 'longitude',
      type: 'string',
      sortable: false,
      filterable: false,
      editor: true,
      minWidth: '160px',
    },
    {
      label: 'Breddegrad',
      field: 'latitude',
      type: 'string',
      sortable: false,
      filterable: false,
      editor: true,
      minWidth: '160px',
    },
  ];
}

export default new LightWorkspaceTaskListConfig();

<template>
  <div
    class="relative flex cursor-pointer items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
  >
    <div class="flex-shrink-0">
      <div class="h-8 w-8 rounded-lg shadow" :class="backgroundColor" />
    </div>
    <div class="min-w-0 flex-1">
      <div>
        <span class="absolute inset-0" aria-hidden="true"></span>
        <p class="text-sm font-medium text-gray-900">{{title}}</p>
        <p class="truncate text-sm text-gray-500">{{description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeocodeCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundColor() {
      return {
        green: 'bg-green-600',
        indigo: 'bg-indigo-600',
        blue: 'bg-blue-600',
        yellow: 'bg-yellow-600',
      }[this.color];
    },
  },
};
</script>

<style>

</style>

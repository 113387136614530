<template>
  <div class="flex flex-col h-full">
    <nav aria-label="Progress">
      <ol class="mt-2">
        <planning-validation-step
          v-for="(step, index) in steps"
          :key="step.title"
          :title="step.title"
          :subtitle="step.subtitle"
          :route="step.route"
          :status="step.status"
          :is-last="index === steps.length - 1"
        ></planning-validation-step>
      </ol>
    </nav>
  </div>
</template>

<script>
import PlanningValidationStep from '@/components/PlanningValidationStep.vue';
import VALIDATION_STATE from '@/util/validationState';

import { mapGetters } from 'vuex';

export default {
  name: 'MergeValidationArea',
  components: {
    PlanningValidationStep,
  },
  props: {
    extraSteps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      VALIDATION_STATE,
    };
  },
  computed: {
    ...mapGetters('import', ['userChosenId', 'newFrequencyMappings', 'mergePlan', 'hasExecuteMergePlan']),
    ...mapGetters('jobs', ['nonInitialImportJobId', 'executeMergePlanJobId']),
    steps() {
      return [
        {
          title: 'ID',
          subtitle: 'Valg af felter',
          status: this.userChosenId ? VALIDATION_STATE.SUCCESS : VALIDATION_STATE.NOT_STARTED,
        },
        {
          title: 'Frekvenskodning',
          subtitle: 'Nye frekvenser',
          status: this.newFrequencyMappings ? VALIDATION_STATE.SUCCESS : VALIDATION_STATE.NOT_STARTED,
        },
        {
          title: 'Plan',
          subtitle: 'Generér plan',
          status: this.planStepStatus,
        },
        {
          title: 'Udfør',
          subtitle: 'Planen udføres',
          status: this.executeStepStatus,
        },
      ];
    },
    isValid() {
      return this.steps.every((step) => step.status === VALIDATION_STATE.SUCCESS);
    },
    planStepStatus() {
      if (this.mergePlan) {
        return VALIDATION_STATE.SUCCESS;
      }

      if (this.nonInitialImportJobId) {
        return VALIDATION_STATE.PENDING;
      }

      return VALIDATION_STATE.NOT_STARTED;
    },
    executeStepStatus() {
      if (this.hasExecuteMergePlan) {
        return VALIDATION_STATE.SUCCESS;
      }

      if (this.executeMergePlanJobId) {
        return VALIDATION_STATE.PENDING;
      }

      return VALIDATION_STATE.NOT_STARTED;
    },
  },
  watch: {
    isValid(newIsValid) {
      this.$emit('on-is-valid-change', newIsValid);
    },
  },
};
</script>

<template>
  <div class="flex items-center justify-between my-6">
    <slot />

    <div class="divide-x-2 flex items-center">
      <span class="font-medium pr-2">{{ title}}</span>

      <div v-if="showPrevious || showNext" class="flex items-center px-4 space-x-2">
        <base-button v-if="showPrevious" :disabled="loading" @click="$emit('previous')">Forrige</base-button>
        <base-button v-if="showNext" :disabled="loading" @click="$emit('next')">Næste</base-button>
      </div>

      <slot name="right-buttons" />

      <span v-if="showDownload" class="pl-4">
        <base-button :disabled="loading" @click="$emit('download')">Download alle</base-button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableActionBar',
  props: {
    title: {
      type: String,
      required: true,
    },
    showNext: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showPrevious: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>

<template>
  <div class="max-w-md">
    <div class="sub-headline mt-4">Først skal du vælge en unik nøgle i dit datasæt</div>
    <p class="text-gray-500 mb-8">Vælg ét eller flere felter som skal udgøre din nøgle</p>

    <ul class="grid grid-cols-2 gap-4">
      <li v-for="option in possibleIdFields" :key="option.field">
        <base-checkbox
          :label="option.label"
          :value="idFields.includes(option.field)"
          @input="toggleValue(option.field)"
        ></base-checkbox>
      </li>
    </ul>

    <div class="mt-4 flex justify-end">
      <base-button color="indigo" :disabled="!idFields.length" @click="nextClicked">Næste</base-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import mergeFlowSteps from '@/util/mergeFlowSteps';

import { SET_MERGE_USER_CHOSEN_ID, ADD_COMPLETED_MERGE_FLOW_STEP } from '@/store/mutations.type';

export default {
  name: 'MergeFlowMergeId',
  data() {
    return {
      idFields: [],
      possibleIdFields: [
        {
          label: 'Adresse',
          field: 'address',
        },
        {
          label: 'Materiel Id',
          field: 'ext_materiel_id',
        },
        {
          label: 'Affaldstype',
          field: 'imported_waste_fraction',
        },
        {
          label: 'Fraktion',
          field: 'imported_fraction',
        },
        {
          label: 'Adresse Link',
          field: 'address_link',
        },
        {
          label: 'Materiel',
          field: 'imported_materiel',
        },
        {
          label: 'ContainerNr',
          field: 'container_no',
        },
        {
          label: 'EjendomsNr',
          field: 'property_no',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('import', ['completedSteps']),
  },
  mounted() {
    const hasCompletedPreviousStep = this.completedSteps.includes(mergeFlowSteps.IMPORT);
    if (!hasCompletedPreviousStep) {
      this.$router.push({ name: 'task-import-overview' });
    }
  },
  methods: {
    ...mapMutations('import', {
      setId: SET_MERGE_USER_CHOSEN_ID,
      addCompletedMergeFlowStep: ADD_COMPLETED_MERGE_FLOW_STEP,
    }),
    toggleValue(optionValue) {
      if (this.idFields.includes(optionValue)) {
        this.idFields = this.idFields.filter((option) => option !== optionValue);
      } else {
        this.idFields.push(optionValue);
      }
    },
    nextClicked() {
      this.setId(this.idFields);
      this.addCompletedMergeFlowStep(mergeFlowSteps.CONFIGURE_ID);
      this.$router.push({ name: 'task-import-merge-frequencies' });
    },
  },
};
</script>

<style>

</style>

<template>
  <secondary-nav-bar-content
    title="Køretøjer"
    description="Her kan du justere de køretøjer, som skal indgå i planlægningen. Kun aktive køretøjer indgår i planlægningen, så hvis der ønskes et køretøj til mellemflytning af opgaver, kan der oprettes et inaktivt køretøj. "
  >
    <base-button slot="actions" color="indigo" @click="vehicleSliderOpen = true">Opret</base-button>

    <base-card v-if="vehicles.length === 0 && !loading" class="mt-4">
      <image-container asset-name="empty" title="Ingen køretøjer fundet">
        <base-button
          color="indigo"
          class="mt-8"
          @click="vehicleSliderOpen = true"
        >Opret første køretøj</base-button>
      </image-container>
    </base-card>

    <div v-else v-loading="loading" class="min-h-384px">
      <vue-good-table
        :columns="columns"
        :rows="vehicles"
        style-class="vgt-table striped custom-vgt-table"
        @on-row-click="onRowClick"
      ></vue-good-table>
    </div>

    <vehicle-create-slider v-model="vehicleSliderOpen" @created="onCreate"></vehicle-create-slider>
    <vehicle-edit-slider
      v-model="vehicleEditSliderOpen"
      :vehicle="selectedVehicle"
      @editted="onEdit"
      @onDeleteRequest="onRemoveRow"
    ></vehicle-edit-slider>
  </secondary-nav-bar-content>
</template>

<script>
import { mapActions } from 'vuex';

import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import VehicleCreateSlider from '@/components/VehicleCreateSlider.vue';
import VehicleEditSlider from '@/components/VehicleEditSlider.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import { ALL_VEHICLES, DELETE_VEHICLE } from '@/store/actions.type';

import columns from '@/components/table/configs/vehicles';

import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

export default {
  name: 'ConfigurationVehicleView',
  components: {
    SecondaryNavBarContent,
    VehicleCreateSlider,
    VehicleEditSlider,
    VueGoodTable,
    ImageContainer,
  },
  data() {
    return {
      loading: true,
      vehicleSliderOpen: false,
      vehicles: [],
      selectedVehicle: null,
      vehicleEditSliderOpen: false,
      columns,
    };
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    ...mapActions('vehicles', {
      getAll: ALL_VEHICLES,
      delete: DELETE_VEHICLE,
    }),
    async onCreate() {
      this.loading = true;
      await this.fetch();
      this.vehicleSliderOpen = false;
    },
    async onEdit() {
      this.loading = true;
      await this.fetch();
      this.vehicleEditSliderOpen = false;
    },
    onRowClick(params) {
      if (params.row.name === 'Ukendt') {
        this.$ntf.info('Ukendt køretøj kan ikke rettes');
        return;
      }

      this.selectedVehicle = params.row;
      this.vehicleEditSliderOpen = true;
    },
    async fetch() {
      try {
        const vehicles = await this.getAll();
        this.vehicles = vehicles;
      } catch (error) {
        this.$unhandledError(error);
      }
      this.loading = false;
    },
    async onRemoveRow(record) {
      this.vehicleEditSliderOpen = false;
      try {
        await this.$confirm({
          title: `Slet køretøj ${record.name}`,
          bodyText: 'Ruter som ligger på køretøjer vil blive flyttet til "Ukendt" køretøj.',
        });
        await this.deleteConfirmed(record);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async deleteConfirmed(record) {
      try {
        await this.delete(record.id);

        this.vehicles = this.vehicles.filter((vehicle) => vehicle.id !== record.id);

        this.$ntf.success('Køretøjet blev slettet');
      } catch (err) {
        this.$unhandledError(err);
      }
    },
  },
};
</script>

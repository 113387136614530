<template>
  <div class="mt-4">
    <div v-if="notGeocodedCount > 0 || loading">
      <base-alert
        type="info"
        :title="`${notGeocodedCount} opgaver mangler geokodning`"
        description="Vælg en af mulighederne for at geokode"
        :dismissable="false"
      ></base-alert>

      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-8">
        <geocode-card
          color="green"
          title="DAWA"
          description="Standard geokodning med DAWA. Det anbefales altid at starte med denne."
          @click.native="onSelection('DAWA')"
        />
        <geocode-card
          color="indigo"
          title="DAWA uden bogstav"
          description="Geokodning med DAWA, hvor bogstavet i adressen er fjernet."
          @click.native="onSelection('DAWA_NO_LETTER')"
        />
        <geocode-card
          color="blue"
          title="Google"
          description="Standard geokodning med Google. Denne tjeneste kan koste penge"
          @click.native="onSelection('GOOGLE')"
        />
        <geocode-card
          color="yellow"
          title="Manuelt"
          description="Geokod opgaver manuelt"
          @click.native="onSelection('MANUAL')"
        />
      </div>
    </div>

    <image-container v-else asset-name="success" title="Alt data er geokodet!"></image-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { CHECK_GEOCODE_STATUS, GEOCODE_ALL_TASKS, GET_GEOCODE_AUDIT_COUNT } from '@/store/actions.type';

import ImageContainer from '@/components/ImageContainer.vue';
import GeocodeCard from '@/components/geocode/GeocodeCard.vue';

import views from '@/util/geocodeViews';

export default {
  name: 'TheGeocodeOverview',
  components: {
    ImageContainer,
    GeocodeCard,
  },
  data() {
    return {
      notGeocodedCount: 0,
      loading: true,
      views,
    };
  },
  async mounted() {
    try {
      const { not_geocoded } = await this.checkGeocodeStatus();
      this.notGeocodedCount = not_geocoded;
      this.loading = false;
    } catch (error) {
      this.$unhandledError(error);
    }
  },

  methods: {
    ...mapActions('status', {
      checkGeocodeStatus: CHECK_GEOCODE_STATUS,
    }),
    ...mapActions('tasks', {
      geocodeAllTasks: GEOCODE_ALL_TASKS,
      geocodeAuditCount: GET_GEOCODE_AUDIT_COUNT,
    }),
    async onSelection(method) {
      if (method === 'MANUAL') {
        this.$emit('changeView', views.MANUAL);
        return;
      }

      try {
        if (method === 'GOOGLE') {
          const count = await this.geocodeAuditCount({ geocode_method: 'GOOGLE' });

          await this.$confirm({
            isHTML: true,
            type: 'warning',
            title: 'Geokodning med google',
            bodyText: `<div>Du har i denne måned forsøgt at geokode <span class="font-bold">${count}</span> opgaver med Google. <br> <br> Geokodning er gratis op til omkring 40.000 opgaver/måneden med Google. <br> <br>
             Læs mere på <a class="text-underline text-blue-500" href='https://developers.google.com/maps/documentation/geocoding/usage-and-billing' target='_blank'>How the Geocoding API is billed </a>
             <br> <br>
             Såfremt det månedlige antal geokodninger med Google overskrider det gratis loft, vil dette blive medfaktureret på den månedelige licens til NorthArc.
             </div>`,
            confirmText: 'OK, forstået',
          });
        }
        this.$ntf.info('Påbegynder geokodning..');
        this.geocodeAllTasks({ geocode_method: method });
        this.$emit('changeView', views.AUTOMATIC);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    onGeocodeAutomatic() {},
  },
};
</script>

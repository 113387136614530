<template>
  <div>
    <import-mapping
      v-for="(mapping, index) in mappings"
      :key="mapping.title"
      :column="mapping.column"
      :title="mapping.title"
      :helper-text="mapping.helperText"
      :description="mapping.description"
      :is-first="index === 0"
      :is-new="mapping.isNew"
      :is-last="index === mappings.length - 1"
      :required="mapping.required"
      :value="mapping.mapped"
      :show-info="mapping.showInfo"
      :options="headerOptions"
      @mapped="onMapping(mapping.field, $event)"
    ></import-mapping>
  </div>
</template>

<script>
import ImportMapping from '@/components/import/ImportMapping.vue';

export default {
  name: 'ImportMappingList',
  components: {
    ImportMapping,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configurationName: '',
      mappings: [
        {
          column: 'A',
          title: 'Vej',
          field: 'ROAD',
          description: 'Adressens vej',
          mapped: '',
          required: true,
        },
        {
          column: 'B',
          title: 'Nr.',
          field: 'NUMBER',
          description: 'Adressens nr',
          mapped: '',
          required: true,
        },
        {
          column: 'C',
          title: 'Bogstav',
          field: 'DOOR_LETTER',
          description: 'Adressens bogstav',
          mapped: '',
          required: true,
        },
        {
          column: 'D',
          title: 'Postnr.',
          field: 'ZIP_CODE',
          description: 'Adressens postnr.',
          mapped: '',
          required: true,
        },
        {
          column: 'E',
          title: 'By',
          field: 'CITY',
          description: 'Adressens by',
          mapped: '',
          required: true,
        },
        {
          column: 'F',
          title: 'Materiel',
          field: 'MATERIEL',
          description: 'Der oprettes automatisk ét materiel for unikke værdier i denne celle',
          helperText: 'Systemet vil selv komme med et kvalificeret gæt på en forkortelse',
          mapped: '',
          required: true,
        },
        {
          column: 'G',
          title: 'Antal',
          field: 'QUANTITY',
          description: 'Antal spande for en opgave',
          helperText: 'Under automatisk planlægning vil der blive taget højde for antallet af spande på en opgave',
          mapped: '',
          required: true,
        },
        {
          column: 'H',
          title: 'Affaldstype',
          field: 'WASTE_CATEGORY',
          description: 'Der oprettes automatisk én affaldstype for unikke værdier i denne celle',
          helperText: "Affaldstyper bruges tilat opretter tømmegrupper under 'tømmegrupper",
          mapped: '',
          required: true,
        },
        {
          column: 'I',
          title: 'Frekvens',
          field: 'FREQUENCY',
          description: 'Opgavens nuværende frekvens',
          helperText: "Opgavens frekvens, der skal kortlægges under 'frekvenser'. Frekvens bruges også, hvis du vil bygge eksisterende ruter",
          mapped: '',
          required: true,
        },
        {
          column: 'J',
          title: 'Køretøj',
          field: 'ROUTE',
          showInfo: true,
          description: 'Opgavens eksisterende køretøj eller rute.',
          helperText: 'Der oprettes automatisk ét køretøj for unikke værdier i denne celle. Denne kolonne skal bruges, hvis du vil bygge eksisterende ruter',
          mapped: '',
        },
        {
          column: 'K',
          title: 'Tømmedag',
          field: 'DISPOSAL_DAY',
          showInfo: true,
          description: 'Opgavens eksisterende tømmedag',
          helperText: 'Denne kolonne skal bruges, hvis du vil bygge eksisterende ruter',
          mapped: '',
        },
        {
          column: 'L',
          title: 'Startuge',
          field: 'WEEK_NO',
          showInfo: true,
          description: 'Opgavens startuge',
          helperText: 'Denne kolonne skal bruges, hvis du vil bygge eksisterende ruter. Hvis uge-information er indlejret i frekvensen, skal du vælge samme felt som under "Frekvens"',
          mapped: '',
        },
        {
          column: 'M',
          title: 'Ejendomsstatus',
          field: 'PROPERTY_ZONE',
          showInfo: true,
          description: 'Adressens Ejendomsstatus',
          helperText: 'Dette felt bruges i zonekodning',
          mapped: '',
        },
        {
          column: 'N',
          title: 'Materiel ID',
          field: 'MATERIEL_ID',
          showInfo: true,
          description: 'ID på opgavens materiel i et eksternt system',
          mapped: '',
        },
        {
          column: 'O',
          title: 'Gammel frekvens',
          field: 'TRANSITION_PERIOD_FREQUENCY',
          description: 'Opgavens gamle frekvens',
          helperText: 'Opgavens gamle frekvens, der bruges til at beregne mellemtømninger',
          mapped: '',
          showInfo: true,
          isNew: true,
        },
        {
          column: 'P',
          title: 'Gammel startuge',
          field: 'TRANSITION_WEEK_NO',
          description: 'Opgavens gamle startuge',
          helperText: 'Opgavens gamle startuge, der bruges til at beregne mellemtømninger',
          mapped: '',
          showInfo: true,
          isNew: true,
        },
        {
          column: 'R',
          title: 'Længdegrad / Longitude',
          field: 'LONGITUDE',
          description: 'Adressens længdegrad (11,39457153)',
          helperText: 'Hvis både længdegrad og breddegrad findes, antages den som geokodet',
          mapped: '',
        },
        {
          column: 'S',
          title: 'Breddegrad / Latitude',
          field: 'LATITUDE',
          description: 'Adressens breddegrad (55,65935558)',
          mapped: '',
        },
        {
          column: 'T',
          title: 'Adresse Link',
          field: 'ADDRESS_LINK',
          description: 'Adressens link, hvis den allerede er geokodet',
          mapped: '',
        },
        {
          column: 'U',
          title: 'Vejkode',
          field: 'ROAD_CODE',
          description: 'Adressens vejkode, hvis den allerede er geokodet',
          mapped: '',
        },
        {
          column: 'V',
          title: 'Ejendomsnr.',
          field: 'PROPERTY_NUMBER',
          description: 'Adressens Ejendomsnr.',
          mapped: '',
        },
        {
          column: 'W',
          title: 'Ejendomsforhold',
          field: 'PROPERTY_RESERVATIONS',
          description: 'Adressens Ejendomsforhold',
          mapped: '',
        },
        {
          column: 'X',
          title: 'Container Nr.',
          field: 'CONTAINER_NUMBER',
          description: '',
          mapped: '',
        },
        {
          column: 'Y',
          title: 'Container placering',
          field: 'CONTAINER_LOCATION',
          description: '',
          mapped: '',
        },
        {
          column: 'Z',
          title: 'Bemærkning',
          field: 'COMMENT',
          description: '',
          mapped: '',
        },
        {
          column: 'AA',
          title: 'Fraktion',
          field: 'FRACTION',
          description: '',
          mapped: '',
        },
      ],
    };
  },
  computed: {
    headerOptions() {
      return [...this.headers].sort().map((h) => ({
        value: h,
        label: h,
      }));
    },
  },
  watch: {
    mappings: {
      deep: true,
      handler: 'onConfigurationChange',
    },
  },
  methods: {
    onMapping(field, value) {
      this.mappings.find((x) => x.field === field).mapped = value;
    },
    onConfigurationChange() {
      const payload = {
        valid: !this.mappings.some((m) => m.required && !m.mapped),
      };

      this.$emit('configurationChange', payload);
    },
    getConfiguration() {
      return this.mappings.map((x) => ({
        field: x.field,
        header: x.mapped,
      }));
    },
    getImportantMappings() {
      return this.mappings.filter((field) => field.required || field.showInfo);
    },
    setValuesFromConfiguration(config) {
      const dict = {};
      config.forEach((entry) => {
        dict[entry.field] = entry.header;
      });

      this.mappings = this.mappings.map((mapping) => {
        const mapped = this.headers.includes(dict[mapping.field]) ? dict[mapping.field] : mapping.mapped;

        return {
          ...mapping,
          // Ensure that the loaded configuration value also exists in the new dataset.
          // The user may attempt to use a saved configuration where some headers have different names
          mapped,
        };
      });
    },
  },
};
</script>

<template>
  <div class="w-full relative">
    <secondary-nav-bar>
      <secondary-nav-bar-item
        v-for="tab in tabs"
        :key="tab.destination"
        :label="tab.label"
        :show-warning="tab.showWarning"
        :hidden="tab.hidden"
        :destination="tab.destination"
      ></secondary-nav-bar-item>
    </secondary-nav-bar>

    <no-tasks-view v-if="!hasAnyTasks && !$route.path.includes('import')"></no-tasks-view>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import SecondaryNavBar from '@/layout/SecondaryNavBar.vue';
import SecondaryNavBarItem from '@/layout/SecondaryNavBarItem.vue';
import NoTasksView from '@/components/NoTasksView.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'ConfigurationView',
  components: {
    SecondaryNavBar,
    SecondaryNavBarItem,
    NoTasksView,
  },
  computed: {
    ...mapGetters('status', ['hasNonGeocoded', 'hasNonZonecoded', 'hasNonFrequencyCoded', 'hasAnyTasks', 'hasMaterielWithoutAbbreviation']),
    ...mapGetters('departments', ['isLightWorkspace']),
    tabs() {
      return [
        {
          label: 'Import',
          destination: 'task-import',
          hidden: this.isLightWorkspace,
        },
        {
          label: 'Import',
          destination: 'task-import-extra',
          hidden: !this.isLightWorkspace || this.hasAnyTasks,
        },
        {
          label: 'Geokodning',
          showWarning: this.hasNonGeocoded,
          destination: 'geocode',
        },
        {
          label: 'Zonekodning',
          showWarning: this.hasNonZonecoded,
          hidden: this.isLightWorkspace,
          destination: 'zonecode',
        },
        {
          label: 'Køretøjer',
          destination: 'vehicles',
        },
        {
          label: 'Frekvenser',
          showWarning: this.hasNonFrequencyCoded,
          hidden: this.isLightWorkspace,
          destination: 'frequencies',
        },
        {
          label: 'Materiel',
          hidden: this.isLightWorkspace,
          showWarning: this.hasMaterielWithoutAbbreviation,
          destination: 'materiel',
        },
        {
          label: 'Tømmegrupper',
          hidden: this.isLightWorkspace,
          destination: 'disposal-categories',
        },
      ];
    },
  },
};
</script>

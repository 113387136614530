<template>
  <div>
    <div v-if="!isEmptyObject(startLocation)">
      <div class="flex py-2 px-4 space-x-2 rounded-lg items-center">
        <div class="text-gray-800 h-4 w-4">
          <svg class="text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
          </svg>
        </div>
        <div class="text-gray-600">{{ startLocation.address }}</div>
      </div>
    </div>
    <draggable
        v-model="draggableStops"
        :item-key="'planned_instance_id'"
        :animation="100"
        :selected-class="'is-selected'"
        @end="onDrop"
    >
      <div
          v-for="(stop, index) in draggableStops"
          :key="stop.planned_instance_id"
          :ref="`stop-${stop.planned_instance_id}`"
          :data-id="stop.planned_instance_id"
          class="flex py-2 px-4 space-x-2 hover:bg-blue-100 rounded-lg hover:shadow-lg cursor-move"
          :class="getStopClass(stop)"
          @click="selectStop(stop, $event)"
      >
        <div class="flex justify-between items-center w-full">

          <div class="flex space-x-2 items-center">
            <div class="text-gray-800 w-4">{{ index + 1 }}</div>
            <div class="flex flex-col space-y-0.5">
              <div class="text-gray-600">{{ stop.address }}</div>
            </div>

          </div>
          <button class="h-4 w-4 bg-transparent" @click.stop="onZoomToPoint(stop)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
          </button>
        </div>
      </div>
    </draggable>
    <div v-if="disposalPoint">
      <div class="flex py-2 px-4 space-x-2 rounded-lg">
        <div class="text-gray-800">{{ disposalPoint.order }}</div>
        <div class="text-gray-600">{{ disposalPoint.address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import { UPDATE_SORTED_STOPS } from '@/store/actions.type';

export default {
  name: 'DragnDropRouteItems',
  components: {
    draggable,
  },
  data() {
    return {
      draggableStops: [], // We have to have a mutatable list (not computed) for drag and drop
    };
  },
  computed: {
    ...mapState('route', ['stops', 'sortedStops', 'selectedStops', 'startLocation', 'disposalPoint']),
    getStopClass() {
      return (stop) => {
        const isSelected = this.selectedStops.some((selectedStop) => selectedStop.planned_instance_id === stop.planned_instance_id);
        return {
          'is-selected': isSelected,
        };
      };
    },
  },
  watch: {
    sortedStops: {
      immediate: true,
      handler(newStops) {
        this.draggableStops = [...newStops];
      },
    },
  },
  methods: {
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    onZoomToPoint(stop) {
      this.$emit('zoomToPoint', { x: stop.x, y: stop.y, zoom: 20 });
    },
    selectStop(stop, event) {
      let selectedStops = [];
      if (event.ctrlKey) {
        if (this.selectedStops.includes(stop)) {
          selectedStops = this.selectedStops.filter((existingStop) => existingStop.planned_instance_id !== stop.planned_instance_id);
        } else {
          selectedStops = [...this.selectedStops, stop];
        }
        // Handle Shift key for range selection
      } else if (event.shiftKey) {
        if (this.lastSelectedStop) {
          const start = this.sortedStops.indexOf(this.lastSelectedStop);
          const end = this.sortedStops.indexOf(stop);
          const [rangeStart, rangeEnd] = [Math.min(start, end), Math.max(start, end)];
          selectedStops = [...this.selectedStops];
          for (let i = rangeStart; i <= rangeEnd; i++) {
            selectedStops.push(this.sortedStops[i]);
          }
        } else {
          selectedStops = [stop];
        }
      } else if (this.selectedStops.length === 0) {
        selectedStops = [stop];
      } else if (this.selectedStops.length === 1) {
        if (this.selectedStops[0].planned_instance_id === stop.planned_instance_id) {
          selectedStops = [];
        } else {
          selectedStops = [stop];
        }
      } else if (this.selectedStops.some((s) => s.planned_instance_id === stop.planned_instance_id)) {
        selectedStops = this.selectedStops.filter((s) => s.planned_instance_id !== stop.planned_instance_id);
      } else {
        selectedStops = [stop];
      }

      // Update last selected stop
      this.lastSelectedStop = stop;
      this.$emit('updateSelectedStops', { selectedStops });
      this.scrollToStop(stop);
    },
    async onDrop(event) {
      // Debugging: log the child elements and their data-id attributes
      const sortedStops = Array.from(event.to.children).map((child) => {
        const id = child.getAttribute('data-id');
        return this.stops.find((sortedStop) => sortedStop.planned_instance_id === id);
      });

      // Dispatch the action to update sortedStops in the store
      await this.$store.dispatch(`route/${ UPDATE_SORTED_STOPS}`, sortedStops);

      // Notify the user of success
      this.$ntf.success('Rækkefølgen på opgaverne blev opdateret!');
    },
    scrollToStop(stop) {
      this.$nextTick(() => {
        const element = this.$refs[`stop-${stop.planned_instance_id}`];
        if (element && element[0]) {
          const container = this.$el.closest('.overflow-y-auto');
          if (container) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element[0].getBoundingClientRect();
            const scrollTop = elementRect.top - containerRect.top + container.scrollTop - (containerRect.height / 2) + (elementRect.height / 2);
            container.scrollTo({
              top: scrollTop,
              behavior: 'smooth',
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.is-selected {
  background-color: #e0f7fa;
}
</style>

import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { polygon, point } from '@/map/shapes';

export default (L) => {
  L.Polygon.include({
    contains(feature, poly) {
      const { lat, lng } = feature.getLatLng();
      const asGeoJsonPoint = point([lat, lng]);
      return booleanPointInPolygon(asGeoJsonPoint, poly);
    },
    extractFeatures(allFeatures, alreadySeen = new Set()) {
      const uniqueTaskIds = [];
      let quantity = 0;

      const layerLatLngs = this.getLatLngs()[0];

      const poly = polygon(layerLatLngs);

      allFeatures.forEach((feature) => {
        if (this.contains(feature, poly)) {
          if (!alreadySeen.has(feature.options.task_id)) {
            quantity += feature.options.quantity;
            quantity += feature.options.sibling_quantity;

            uniqueTaskIds.push(feature.options.task_id);
            uniqueTaskIds.push(...feature.options.sibling_tasks);
          }
        }
      });

      const uniqueTasks = new Set(uniqueTaskIds);

      return [Array.from(uniqueTasks), uniqueTasks.size, quantity];
    },
  });
};

<template>
  <secondary-nav-bar-content title="Zonekodning" description="Zonekodning">
    <p slot="description" class="text-gray-500 mt-1.5" style="max-width: 800px">
      Her kan du zonekode datasættet.
      Først skal du kortlægge de ejendomsstatusser, som du har importeret, så de ikke bliver blandet sammen med Northarcs.
      <br />
    </p>

    <div class="h-full w-full">
      <the-zonecode-automatic></the-zonecode-automatic>
    </div>
  </secondary-nav-bar-content>
</template>

<script>
import TheZonecodeAutomatic from '@/components/zonecode/TheZonecodeAutomatic.vue';

import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';

export default {
  name: 'ConfigurationZonecodeView',
  components: {
    SecondaryNavBarContent,
    TheZonecodeAutomatic,
  },
};
</script>

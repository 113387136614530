<template>
  <button class="text-gray-700 hover:text-gray-500 flex items-center">
    <span>Ryd filtre</span>
    <icon-x class="w-4 h-4 ml-1 text-gray-800"></icon-x>
  </button>
</template>

<script>
export default {
  name: 'FilterClearButton',
};
</script>

<style>

</style>

const vuelidateMixin = {
  methods: {
    valid(validator) {
      return !validator.$error && validator.$dirty;
    },
    invalid(validator) {
      return validator.$error && validator.$dirty;
    },
  },
};

export default vuelidateMixin;

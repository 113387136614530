<template>
  <div>
    <job-poller
      v-if="nonInitialImportJobId"
      ref="planGeneratePoller"
      title="Bygger plan for sammenfletning"
      description="Dette kan tage nogle minutter, hvis der er mange opgaver"
      :job-id="nonInitialImportJobId"
      :type="jobTypes.NONINITIALIMPORT"
      class="mb-6"
      @result="onMergePlanGeneratedResult"
    ></job-poller>

    <template v-if="mergePlan">
      <h3 class="mt-4 mb-6 headline">Opsummering af plan</h3>

      <base-alert
        type="info"
        title="Hvad er en duplikat?"
        class="mb-4"
        :dismissable="true"
        description="En duplikat betyder at Northarc ikke kan finde et præcis match på baggrund af det ID du har valgt. F.eks. kan en adresse i det nye datasæt pege på 2 opgaver i det eksisterende datasæt"
      ></base-alert>

      <ul class="grid grid-cols-3 gap-4 auto-rows-fr">
        <li v-for="mergePlanReason in mergePlanReasons" :key="mergePlanReason.title" class="w-full">
          <merge-card
            :total="mergePlanReason.count"
            :color="mergePlanReason.color"
            :title="mergePlanReason.title"
            :description="mergePlanReason.description"
          ></merge-card>
        </li>
      </ul>

      <div class="bg-gray-200 mt-12 py-6">
        <div class="flex flex-col items-center justify-center mt-4">
          <img :src="spreadSheetImage" class="h-auto w-80" alt />

          <div class="flex items-center mt-6">
            <a
              class="text-underline text-blue-500 text-xl"
              :href="mergePlan.details_url"
              target="_blank"
            >Hent detaljeret oversigt</a>
            <icon-download class="h-4 w-4 text-blue-700 ml-2"></icon-download>
          </div>
        </div>
      </div>

      <div class="h-0.5 w-full bg-gray-200 my-24"></div>

      <div class="flex justify-center">
        <base-button size="xl" color="red" :inverted="true" class="mr-4" @click="onRegret">Fortryd</base-button>
        <base-button size="xl" color="indigo" @click="executePlan">Udfør plan</base-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import JobPoller from '@/components/JobPoller.vue';

import MergeCard from '@/components/import/MergeCard.vue';
import { SUBMIT_MERGE_PLAN_CONFIRM, BUILD_MERGE_PLAN } from '@/store/actions.type';
import { SET_MERGE_PLAN, SET_JOB_ID_BY_TYPE, RESET_FLOW, ADD_COMPLETED_MERGE_FLOW_STEP } from '@/store/mutations.type';
import mergeFlowSteps from '@/util/mergeFlowSteps';
import MERGE_REASONS_ENUM from '@/util/mergeReasons';

import * as jobTypes from '@/util/jobTypes';

/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export default {
  name: 'MergeFlowPlan',
  components: {
    MergeCard,
    JobPoller,
  },
  data() {
    return {
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('import', ['mergePlan', 'completedSteps']),
    ...mapGetters('jobs', ['nonInitialImportJobId']),
    mergePlanReasons() {
      if (!this.mergePlan) {
        return [];
      }

      const reasonMap = {};
      reasonMap[MERGE_REASONS_ENUM.FREQUENCY_CHANGE] = {
        title: 'Frekvensændringer',
        description: 'Frekvensen er ændret, hvilket udløser en særlig opdatering af opgaven',
        color: 'blue',
      };
      reasonMap[MERGE_REASONS_ENUM.UPDATE] = {
        title: 'Opdater',
        description: 'Opdatering af et eller flere felter',
        color: 'yellow',
      };
      reasonMap[MERGE_REASONS_ENUM.UPDATE_DUPLICATE] = {
        title: 'Opdater (duplikat)',
        description: "Der blev fundet et match, men antallet af eksisterende og nye opgaver stemmer ikke. Her opdateres de opgaver, som ikke enten falder i 'Opret duplikat' eller 'Slet duplikat'",
        color: 'orange',
      };
      reasonMap[MERGE_REASONS_ENUM.CREATE] = {
        title: 'Opret',
        description: 'Opgaven er ikke tidligere set og oprettes som en uplanlagt opgave',
        color: 'green',
      };
      reasonMap[MERGE_REASONS_ENUM.CREATE_DUPLICATE] = {
        title: 'Opret (duplikat)',
        description: 'Der blev fundet færre eksisterende opgaver end nye opgaver. Derfor oprettes de overskydende som nye, på trods af at de har et match',
        color: 'green-light',
      };
      reasonMap[MERGE_REASONS_ENUM.DELETE] = {
        title: 'Slet',
        description: 'Ingen match fundet i det nye datasæt',
        color: 'red',
      };
      reasonMap[MERGE_REASONS_ENUM.DELETE_DUPLICATE] = {
        title: 'Slet (duplikat)',
        description: 'Der blev fundet flere eksisterende opgaver end nye opgaver. Derfor slettes de overskydende, på trods af at de har et match',
        color: 'red-light',
      };
      reasonMap[MERGE_REASONS_ENUM.NO_CHANGES_FOR_CLIENT] = {
        title: 'Ingen ændringer',
        description: 'Ingen ændringer til opgaven',
        color: 'black',
      };

      return this.mergePlan.reasons.map((reasonSummary) => ({
        ...reasonMap[reasonSummary.reason],
        count: reasonSummary.count,
      }));
    },
    spreadSheetImage() {
      return require('@/assets/images/spreadsheet.svg');
    },
  },
  async mounted() {
    const hasCompletedPreviousStep = this.completedSteps.includes(mergeFlowSteps.MAP_FREQUENCIES);

    if (!hasCompletedPreviousStep) {
      this.$router.push({ name: 'task-import-overview' });
      return;
    }

    if (!this.mergePlan && !this.nonInitialImportJobId) {
      await this.buildMergePlan();
    }
  },
  methods: {
    ...mapActions('import', {
      buildMergePlan: BUILD_MERGE_PLAN,
      submitMergePlanConfirm: SUBMIT_MERGE_PLAN_CONFIRM,
    }),
    ...mapMutations('import', {
      setMergePlan: SET_MERGE_PLAN,
      resetMergeFlow: RESET_FLOW,
      addCompletedMergeFlowStep: ADD_COMPLETED_MERGE_FLOW_STEP,
    }),
    ...mapMutations('jobs', {
      setJobTypeIdByType: SET_JOB_ID_BY_TYPE,
    }),
    onMergePlanGeneratedResult(result) {
      this.setMergePlan(result);
      this.$refs.planGeneratePoller.closeMenu();
    },
    async onRegret() {
      try {
        await this.$confirm({
          title: 'Advarsel!',
          bodyText: 'Du vil ikke kunne tilgå plannen længere',
          confirmText: 'OK, jeg forstår',
          cancelText: 'Ikke alligevel',
          type: 'warning',
        });
      } catch (error) {
        return;
      }

      try {
        this.$ntf.info('Plan fortrudt');
        this.resetMergeFlow();
        this.$router.push({ name: 'task-import-overview' });
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async executePlan() {
      try {
        // reset the original import job
        this.setJobTypeIdByType({
          jobId: null,
          type: this.jobTypes.NONINITIALIMPORT,
        });

        await this.submitMergePlanConfirm();

        this.addCompletedMergeFlowStep(mergeFlowSteps.MAP_FREQUENCIES);

        this.$router.push({ name: 'task-import-merge-execute' });
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="m-4 flex flex-col route-controller-height route-controller-width shadow-lg rounded-md absolute right-0 top-0 z-10">
    <div class="bg-white px-4 py-5 sm:px-6 rounded-t-md">
      <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap border-b border-gray-200 pb-2 rounded-md">
        <div class="ml-4 mt-4">
          <h3 class="text-base font-semibold leading-6 text-gray-900">{{ route.label }} ({{ stops.length }} stops)</h3>
          <p class="mt-1 text-sm text-gray-500">Tilpas ruten ved at ændre rækkefølgen på opgaverne.</p>
        </div>
      </div>
    </div>

    <div class="bg-white flex-grow overflow-y-auto px-4 space-y-1 route-controller-height">
      <dragn-drop-route-items
          ref="dragnDropRouteItems"
          @zoomToPoint="onZoomToPoint"
          @updateSelectedStops="updateSelectedStops"
      ></dragn-drop-route-items>
    </div>

    <div class="border-t px-4 border-gray-200 bg-white py-5 sm:px-6 rounded-b-md">
      <div class="flex flex-shrink-0 justify-between">
        <move-selected-stops></move-selected-stops>
        <div class="flex space-x-2">
          <base-button
              type="button"
              color="indigo"
              :disabled="isSaving"
              @click="showVersionsModal = true"
          >
            Versioner
          </base-button>
          <!-- Calculate button -->
          <base-button
              type="button"
              color="indigo"
              :disabled="isSaving"
              @click="saveStopsToBackend( {start: startLocation, ordered_stops: sortedStops, disposal_point: disposalPoint})"
          >
            Gem
          </base-button>
        </div>
      </div>
    </div>

    <route-versions-modal
        v-if="route.id"
        v-model="showVersionsModal"
    />
  </div>
</template>

<script>

import { SAVE_STOPS_TO_BACKEND } from '@/store/actions.type';
import DragnDropRouteItems from '@/components/route-viewer/route-controller/DragnDropRouteItems.vue';
import MoveSelectedStops from '@/components/route-viewer/route-controller/MoveSelectedStops.vue';
import { mapState } from 'vuex';
import RouteVersionsModal from '@/components/route-viewer/route-controller/RouteVersionsModal.vue';

export default {
  name: 'TheRouteController',
  components: {
    DragnDropRouteItems,
    MoveSelectedStops,
    RouteVersionsModal,
  },
  data() {
    return {
      showVersionsModal: false,
      currentDrivingListId: null,
      showSaveModal: false,
      originalOrder: [], // Track original order for comparison
      versionOrders: new Map(), // Cache version orders
    };
  },
  computed: {
    ...mapState('route', ['stops', 'sortedStops', 'startLocation', 'disposalPoint', 'route', 'isSaving']),
  },
  watch: {
    sortedStops: {
      deep: true,
      handler(newStops) {
        if (this.originalOrder.length) {
          // Compare current order with original order
          this.hasChanges = !this.originalOrder.every((originalStop, index) => originalStop.planned_instance_id === newStops[index]?.planned_instance_id);
        }
      },
    },
  },
  methods: {
    onZoomToPoint(payload) {
      this.$emit('zoomToPoint', payload);
    },
    updateSelectedStops(payload) {
      this.$emit('updateSelectedStops', payload);
    },
    scrollToStop(stop) {
      if (this.$refs.dragnDropRouteItems) {
        this.$refs.dragnDropRouteItems.scrollToStop(stop);
      }
    },
    async saveStopsToBackend(input) {
      const payload = {
        start: {
          id: input.start.id,
          coordinate: {
            x: parseFloat(input.start.x),
            y: parseFloat(input.start.y),
          },
        },
        ordered_stops: input.ordered_stops.map((stop) => ({
          id: stop.id,
          coordinate: {
            x: parseFloat(stop.x),
            y: parseFloat(stop.y),
          },
        })),
        disposal_point: input.disposal_point ? {
          id: input.disposal_point.id,
          coordinate: {
            x: parseFloat(input.disposal_point.x),
            y: parseFloat(input.disposal_point.y),
          },
        } : null,
      };

      try {
        this.$ntf.info('Starter opdatering af stops..');
        await this.$store.dispatch(`route/${SAVE_STOPS_TO_BACKEND}`, payload);
        this.$ntf.success('Stops er blevet opdateret');
      } catch (error) {
        this.$ntf.error('Kunne ikke opdatere stops');
        this.$unhandledError(error);
      }
    },
  },
};
</script>

<style>
.route-controller-height {
  height: calc(100% - 32px);
  max-height: calc(100% - 32px);
}
.route-controller-width {
  width: 456px;
  max-width: 456px;
}
</style>

<template>
  <div>
    <import-mapping
      v-for="(mapping, index) in mappings"
      :key="mapping.title"
      :column="mapping.column"
      :title="mapping.title"
      :helper-text="mapping.helperText"
      :description="mapping.description"
      :is-first="index === 0"
      :is-new="mapping.isNew"
      :is-last="index === mappings.length - 1"
      :required="mapping.required"
      :value="mapping.mapped"
      :show-info="mapping.showInfo"
      :options="headerOptions"
      @mapped="onMapping(mapping.field, $event)"
    ></import-mapping>
  </div>
</template>

<script>
import ImportMapping from '@/components/import/ImportMapping.vue';

export default {
  name: 'ImportMappingList',
  components: {
    ImportMapping,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configurationName: '',
      mappings: [
        {
          column: 'A',
          title: 'Vej',
          field: 'ROAD',
          description: 'Adressens vej',
          mapped: '',
          required: true,
        },
        {
          column: 'B',
          title: 'Nr.',
          field: 'NUMBER',
          description: 'Adressens nr',
          mapped: '',
          required: true,
        },
        {
          column: 'C',
          title: 'Bogstav',
          field: 'DOOR_LETTER',
          description: 'Adressens bogstav',
          mapped: '',
          required: true,
        },
        {
          column: 'D',
          title: 'Postnr.',
          field: 'ZIP_CODE',
          description: 'Adressens postnr.',
          mapped: '',
          required: true,
        },
        {
          column: 'E',
          title: 'By',
          field: 'CITY',
          description: 'Adressens by',
          mapped: '',
          required: true,
        },
        {
          column: 'F',
          title: 'Antal',
          field: 'QUANTITY',
          description: 'Antal spande for en opgave',
          helperText: 'Hvis kolonnen ikke vælges, sættes antal til 1',
          mapped: '',
          required: false,
        },
        {
          column: 'G',
          title: 'Køretøj',
          field: 'ROUTE',
          description: 'Opgavens eksisterende køretøj eller rute.',
          helperText: 'Der oprettes automatisk ét køretøj for unikke værdier i denne celle.',
          mapped: '',
        },
        {
          column: 'H',
          title: 'Længdegrad / Longitude',
          field: 'LONGITUDE',
          description: 'Adressens længdegrad (11,39457153)',
          helperText: 'Hvis både længdegrad og breddegrad findes, antages den som geokodet',
          mapped: '',
        },
        {
          column: 'I',
          title: 'Breddegrad / Latitude',
          field: 'LATITUDE',
          description: 'Adressens breddegrad (55,65935558)',
          mapped: '',
        },
        {
          column: 'J',
          title: 'Bemærkning',
          field: 'COMMENT',
          description: '',
          mapped: '',
        },
      ],
    };
  },
  computed: {
    headerOptions() {
      return [...this.headers].sort().map((h) => ({
        value: h,
        label: h,
      }));
    },
  },
  watch: {
    mappings: {
      deep: true,
      handler: 'onConfigurationChange',
    },
  },
  methods: {
    onMapping(field, value) {
      this.mappings.find((x) => x.field === field).mapped = value;
    },
    onConfigurationChange() {
      const payload = {
        valid: !this.mappings.some((m) => m.required && !m.mapped),
      };

      this.$emit('configurationChange', payload);
    },
    getConfiguration() {
      return this.mappings.map((x) => ({
        field: x.field,
        header: x.mapped,
      }));
    },
    setValuesFromConfiguration(config) {
      const dict = {};
      config.forEach((entry) => {
        dict[entry.field] = entry.header;
      });

      this.mappings = this.mappings.map((mapping) => {
        const mapped = this.headers.includes(dict[mapping.field]) ? dict[mapping.field] : mapping.mapped;

        return {
          ...mapping,
          // Ensure that the loaded configuration value also exists in the new dataset.
          // The user may attempt to use a saved configuration where some headers have different names
          mapped,
        };
      });
    },
  },
};
</script>

<template>
  <div class="flex -space-x-1.5">
    <base-button
      v-for="button in buttons"
      ref="btn"
      :key="button.value"
      :disabled="isDisabled"
      :color="value === button.value ? 'indigo' : ''"
      @click="selected(button.value)"
    >{{button.value}}</base-button>
  </div>
</template>
<script>
export default {
  name: 'ButtonSelectionList',
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    selected(val) {
      if (val === this.value) {
        return;
      }

      this.$emit('input', val);
    },
  },
};
</script>

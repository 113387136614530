<template>
  <secondary-nav-bar-content title="Mellemtømninger" description>
    <base-alert
      v-if="hasUnplannedTasks"
      type="warning"
      title="Du har uplanlagte opgaver"
      class="mt-4"
      description="Hvis du vil oprette mellemtømninger, skal du planlægge alle opgaver først."
    ></base-alert>

    <template v-else>
      <base-alert v-if="!!activeWorkspace.transitionPeriodId" class="mt-8" type="warning">
        <div slot="title" class="flex justify-between w-full">
          <span
            class="text-sm leading-5 font-medium flex justify-between text-yellow-800"
          >Du har allerede oprettet mellemtømninger for dette workspace</span>

          <button
            class="flex items-center space-x-2 relative"
            @click="onSetTransitionPeriodWorkspace(activeWorkspace.transitionPeriodId)"
          >
            <span
              class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
            >Skift til mellemtømninger</span>

            <icon-arrow-right class="text-indigo-700 h-4 w-4"></icon-arrow-right>
          </button>
        </div>
      </base-alert>

      <base-alert
        v-else-if="!isInMainExperiment"
        type="warning"
        title="Du er ikke i dit Main eksperiment"
        class="mt-4"
        description="Du kan kun oprette mellemtømninger fra dit Main eksperiment. Tryk på tandhjulet øverst i højre hjørne"
      ></base-alert>

      <template v-else>
        <base-alert
          :dismissable="true"
          type="info"
          title="Sådan fungerer mellemtømninger"
          class="mt-4"
          description="Step 1"
        >
          <ol class="mt-2 space-y-1.5">
            <li class="flex items-center">
              <span class="h-1.5 w-1.5 mr-2 bg-gray-600 rounded-full"></span>
              <p>Du vælger maks antal dage frekvensen må overskride</p>
            </li>
            <li class="flex items-center">
              <span class="h-1.5 w-1.5 mr-2 bg-gray-600 rounded-full"></span>
              <p>Du kortlægger gamle frekvenser og deres startuge. Dette bruges til at beregne mellemtømninger hvis en opgave f.eks. er gået fra hver 4. uge til hver 3. uge</p>
            </li>
            <li class="flex items-center">
              <span class="h-1.5 w-1.5 mr-2 bg-gray-600 rounded-full"></span>
              <p>Der oprettes et dedikeret workspace, hvor du kan beregne dine mellemtømninger</p>
            </li>
            <li class="flex items-center">
              <span class="h-1.5 w-1.5 mr-2 bg-gray-600 rounded-full"></span>
              <p>Når du er færdig med at planlægge, vil du kunne eksportere en fil med alle mellemtømninger.</p>
            </li>
          </ol>
        </base-alert>

        <base-alert v-if="createdTransitionPeriod" class="mt-8" type="warning">
          <div slot="title" class="flex justify-between w-full">
            <span
              class="text-sm leading-5 font-medium flex flex-col justify-between text-yellow-800"
            >
              Du kan nu skifte til dine mellemtømninger
              <span
                v-if="createdTransitionPeriod.message"
                class="mt-4"
              >{{createdTransitionPeriod.message}}</span>
            </span>

            <button
              class="flex items-center space-x-2 relative"
              @click="onSetTransitionPeriodWorkspace(createdTransitionPeriod.workspace_id)"
            >
              <span
                class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
              >Skift til mellemtømninger</span>

              <icon-arrow-right class="text-indigo-700 h-4 w-4"></icon-arrow-right>
            </button>
          </div>
        </base-alert>

        <job-poller
          v-if="transitionPeriodWorkspaceId"
          title="Klargører mellemtømninger.."
          description="Dette kan tage nogle minutter, hvis det er et stort workspace"
          :job-id="transitionPeriodWorkspaceId"
          :type="jobTypes.TRANSITIONPERIODWORKSPACE"
          @result="onCreateTransitonPeriodResult"
          @closed="closedPoller = true"
        ></job-poller>

        <!-- no running job -->
        <div v-if="!transitionPeriodWorkspaceId && !closedPoller" class="mt-4">
          <h3 class="text-gray-900 text-xl mt-4 mb-6 font-medium">Mellemtømnings information</h3>

          <div class="max-w-lg">
            <base-input
              v-model="form.startDate"
              type="date"
              class="w-full mt-4"
              label="Startdato for den nye plan"
              @input="startDateChanged"
            ></base-input>

            <base-input
              :value="startDateWeekNo"
              :disabled="true"
              type="number"
              class="w-full mt-4"
              label="Startuge for den nye plan"
            ></base-input>

            <base-input
              v-model="form.lastEvenWeekNo"
              type="number"
              :disabled="true"
              class="w-full mt-4"
              label="Sidste ugenr. for lige tømning i gamle plan"
            ></base-input>

            <base-input
              v-model="form.lastOddWeekNo"
              type="number"
              class="w-full mt-4"
              :disabled="true"
              label="Sidste ugenr. for ulige tømning i gamle plan"
            ></base-input>

            <base-input
              v-model="form.maxOverstep"
              type="number"
              class="w-full mt-4"
              min="0"
              label="Maks antal dage som hver opgave må overskride"
            ></base-input>
          </div>

          <!-- MAP OLD FREQUNECIES AND WEEKS -->
          <h3 class="text-gray-900 text-xl mt-4 font-medium">Gamle frekvenser</h3>

          <div v-if="!hasOldFrequencies">
            <base-alert
              title="Ingen gamle frekvenser"
              description="Du har ikke importeret nogen gamle frekvenser i feltet 'Importeret gl. frekvens'. Det antages derfor at de gamle frekvenser er som de nuværende"
              type="info"
              class="mb-8 mt-4"
            ></base-alert>
          </div>
          <template v-else>
            <p
              class="mb-6 text-gray-700"
            >Dette er værdierne fra kolonnen 'Gammel frekvens' under import</p>
            <!-- transition period frequencies -->
            <div class="pb-8">
              <div v-if="oldFrequenciesToMap.length" class="grid-cols-1">
                <div class="flex font-semibold text-gray-800 mb-2">
                  <p class="flex-1 pl-6">Tekstværdi i datasæt</p>
                  <p class="flex-1 pl-14">Oversat værdi i NorthArc</p>
                </div>

                <ul>
                  <li v-for="freq in oldFrequenciesToMap" :key="freq">
                    <mapping-line
                      :options="frequencies"
                      :title="freq"
                      :value="form.oldFrequenciesMapped[freq]"
                      :use-value="true"
                      placeholder="Vælg frekvens"
                      @selected="onOldFrequencyMapping"
                    >
                      <p
                        v-if="!freq"
                        slot="title"
                        class="text-blue-700"
                      >Tomme frekvenser i datasættet</p>
                    </mapping-line>
                  </li>
                </ul>
              </div>
            </div>

            <h3 class="text-gray-900 text-xl mt-4 font-medium">Gamle startuger</h3>

            <p
              class="mb-6 text-gray-700"
            >Dette er værdierne fra kolonnen 'Gammel startuge' under import</p>

            <!-- transition period planned_week_nos -->
            <ul v-if="hasMappedFrequencies">
              <li v-for="plannedWeekNo in plannedWeekNosToMap" :key="plannedWeekNo.title">
                <mapping-line
                  :options="plannedWeekNo.options"
                  :title="plannedWeekNo.title"
                  placeholder="Vælg uge"
                  @selected="onWeekNoMapping"
                ></mapping-line>
              </li>
            </ul>

            <div v-else>
              <base-alert
                title="Du vil kunne kortlægge gamle startuger, når du har kortlagt alle frekvenser ovenfor"
                type="info"
                class="my-12"
              ></base-alert>
            </div>
          </template>

          <!-- Sidste tømmedag for nye frekvenser i den sidste uge (når der er gamle) -->
          <div v-if="uniqueIntervals.length > 0 && hasOldFrequencies">
            <label
              class="block text-sm font-medium leading-5 text-blue-700 mt-4"
            >Sidste tømmedag for intervaller større end hver 3. uge</label>
            <ul v-for="interval in uniqueIntervals" :key="interval">
              <li class="flex flex-col mt-2">
                <base-input
                  :label="getLabel(interval)"
                  :value="form.intervalMap[interval]"
                  class="w-full"
                  type="number"
                  :step="interval % 2 === 0 ? 2 : 1"
                  :max="interval"
                  :min="isPreviousWeekEven && interval % 2 === 0 ? 2 : 1"
                  @input="(evt) => onIntervalChange({ value: evt, key: interval })"
                ></base-input>

                <p class="text-sm text-gray-700">{{getPossibleValues(interval)}}</p>
              </li>
            </ul>
          </div>

          <!-- MAP NEW FREQUENCIES -->
          <h3 class="text-gray-900 text-xl mt-4 font-medium">Nuværende frekvenser</h3>

          <p
            class="mb-6 text-gray-700"
          >Dette er de nuværende frekvenser i workspacet. Fortæl os hvilken uge, du ønsker frekvensen skal starte i</p>
          <label
            class="block text-sm font-medium leading-5 text-blue-700 mt-4"
          >Nye startuger for intervaller større end hver 3. uge</label>
          <ul v-for="interval in uniqueNewFrequencies" :key="interval">
            <li class="flex flex-col mt-2">
              <base-input
                :label="getNewFreqLabel(interval)"
                :value="form.frequencyStartWeekMap[interval]"
                class="w-full"
                type="number"
                :step="1"
                :max="interval"
                :min="1"
                @input="(evt) => onFrequencyStartWeekChange({ value: evt, key: interval })"
              ></base-input>
            </li>
          </ul>

          <!-- Sidste tømmedag for nye frekvenser i sidste uge (når der ikke er gamle) -->
          <div v-if="uniqueIntervals.length > 0 && !hasOldFrequencies">
            <label
              class="block text-sm font-medium leading-5 text-blue-700 mt-4"
            >Sidste tømmedag for intervaller større end hver 3. uge</label>
            <ul v-for="interval in uniqueIntervals" :key="interval">
              <li class="flex flex-col mt-2">
                <base-input
                  :label="getLabel(interval)"
                  :value="form.intervalMap[interval]"
                  class="w-full"
                  type="number"
                  :step="interval % 2 === 0 ? 2 : 1"
                  :max="interval"
                  :min="isPreviousWeekEven && interval % 2 === 0 ? 2 : 1"
                  @input="(evt) => onIntervalChange({ value: evt, key: interval })"
                ></base-input>

                <p class="text-sm text-gray-700">{{getPossibleValues(interval)}}</p>
              </li>
            </ul>
          </div>

          <!-- MAP SUMMER FREQUENCIES TO BE HALFED -->
          <template v-if="summerFrequenciesToMap.length">
            <h3 class="text-gray-900 text-xl mt-4 font-medium">Sommerfrekvenser</h3>
            <label
              class="block text-sm font-medium leading-5 text-blue-700 my-4"
            >Vælg de sommerfrekvenser som skal halveres. (F.eks. hver 6. uge halveres til hver 3. uge, hvis den vinges af)</label>

            <ul v-for="summerFreq in summerFrequenciesToMap" :key="summerFreq.value" class="mb-4">
              <li class="flex flex-col mt-2">
                <base-checkbox
                  :label="summerFreq.label"
                  :value="form.summerFrequencies[summerFreq.label]"
                  @input="(evt) => onSummerChange({ value: evt, label: summerFreq.label })"
                ></base-checkbox>
              </li>
            </ul>
          </template>

          <base-button
            color="indigo"
            class="mt-4"
            :disabled="isSubmitDisabled"
            @click="onCreateTransitionPeriodWorkspace"
          >Opret område til mellemtømninger</base-button>
        </div>
      </template>
    </template>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { GENERATE_TRANSITION_WORKSPACE, ALL_DEPARTMENTS } from '@/store/actions.type';
import { SET_DEPARTMENT } from '@/store/mutations.type';
import * as jobTypes from '@/util/jobTypes';
import { getDayInPast, toHtmlDatePickerFormat } from '@/util/dates';
import { getUniqueIntervalsLargerThan } from '@/util/frequencies';

import MappingLine from '@/components/MappingLine.vue';

import JobPoller from '@/components/JobPoller.vue';
import { addWeeks, getISOWeek, parseISO, subWeeks } from 'date-fns';
import format from 'date-fns/format';

import FrequencyService from '@/services/frequencies.service';
import TransitionPeriodService from '@/services/transitionPeriods.service';

export default {
  name: 'TransitionPeriodsView',
  components: {
    SecondaryNavBarContent,
    MappingLine,
    JobPoller,
  },
  data() {
    return {
      form: {
        maxOverstep: 1,
        lastEvenWeekNo: 0,
        lastOddWeekNo: 0,
        startDate: format(new Date(), 'yyyy-MM-dd'),
        intervalMap: {},
        oldFrequenciesMapped: {},
        frequencyStartWeekMap: {},
        plannedWeekMapped: {},
        summerFrequencies: {},
      },
      jobTypes,
      createdTransitionPeriod: null,
      uniqueIntervals: [],
      uniqueNewFrequencies: [],
      closedPoller: false,
      frequencies: [],
      oldFrequenciesToMap: [],
      plannedWeekNosToMap: [],
      hasMappedFrequencies: false,
      hasOldFrequencies: false,
      allFrequencies: [],
      summerFrequenciesToMap: [],
    };
  },
  computed: {
    ...mapGetters('departments', ['isInMainExperiment', 'activeWorkspace']),
    ...mapGetters('transitionperiod', ['lastDisposalDays', 'loading']),
    ...mapGetters('jobs', ['transitionPeriodWorkspaceId']),
    ...mapGetters('status', ['hasUnplannedTasks']),
    isSubmitDisabled() {
      const hasMappedDates = Object.values(this.form.intervalMap).length === this.uniqueIntervals.length;

      const hasMappedPlannedWeekNos = Object.keys(this.form.plannedWeekMapped).length === this.plannedWeekNosToMap.length;
      const hasMappedOldFrequencies = Object.keys(this.form.oldFrequenciesMapped).length === this.oldFrequenciesToMap.length;
      const hasMappedNewFrequencies = Object.keys(this.form.frequencyStartWeekMap).length === this.uniqueNewFrequencies.length;

      if (Object.values(this.form.frequencyStartWeekMap).some((val) => val === '')) {
        return true;
      }

      if (this.hasOldFrequencies) {
        if (Object.values(this.form.oldFrequenciesMapped).some((val) => val === '')) {
          return true;
        }

        return !hasMappedDates || this.form.maxOverstep < 0 || this.form.lastEvenWeekNo <= 0 || this.form.lastOddWeekNo <= 0 || this.hasUnplannedTasks || !hasMappedPlannedWeekNos || !this.hasMappedFrequencies || !hasMappedOldFrequencies || !hasMappedNewFrequencies;
      }

      return !hasMappedDates || this.form.maxOverstep < 0 || this.form.lastEvenWeekNo <= 0 || this.form.lastOddWeekNo <= 0 || this.hasUnplannedTasks || !hasMappedNewFrequencies;
    },
    startDateWeekNo() {
      return getISOWeek(parseISO(this.form.startDate));
    },
    previousWeekNo() {
      return getISOWeek(subWeeks(parseISO(this.form.startDate), 1));
    },
    isPreviousWeekEven() {
      const prevIsoWeek = getISOWeek(subWeeks(parseISO(this.form.startDate), 1));
      return prevIsoWeek % 2 === 0;
    },
  },
  async mounted() {
    try {
      this.startDateChanged();
      this.reloadDepartments();
      const { data } = await FrequencyService.getAll();
      this.allFrequencies = data;

      const freqInUseResponse = await FrequencyService.getFrequenciesInUse();
      this.summerFrequenciesToMap = freqInUseResponse.data
        .filter((freq) => freq.interval % 2 === 0)
        .sort((a, b) => a.interval - b.interval)
        .map((freq) => ({ value: freq.id, label: freq.label }));

      await this.fetchTransitionPeriodOldFrequenciesToMap();
      await this.fetchFrequencies();

      this.uniqueNewFrequencies = getUniqueIntervalsLargerThan(freqInUseResponse.data, 3);

      if (!this.hasOldFrequencies) {
        this.uniqueIntervals = this.uniqueNewFrequencies;
      }

      this.setDefaultStartWeeks();
    } catch (error) {
      this.$unhandledError(error);
    }
  },
  methods: {
    setUniqueIntervals() {
      this.uniqueIntervals.forEach((interval) => {
        const isEvenInterval = interval % 2 === 0;

        if (isEvenInterval) {
          // If last week is odd then decrement by one
          this.form.intervalMap[interval] = this.isPreviousWeekEven ? interval : interval - 1;
        } else {
          this.form.intervalMap[interval] = interval;
        }
      });
    },
    setDefaultStartWeeks() {
      this.uniqueNewFrequencies.forEach((interval) => {
        const isStartEven = this.startDateWeekNo % 2 === 0;

        if (interval % 2 === 0) {
          // If last week is odd then decrement by one
          this.form.frequencyStartWeekMap[interval] = isStartEven ? 2 : 1;
        } else {
          this.form.frequencyStartWeekMap[interval] = isStartEven ? 1 : 2;
        }
      });
    },
    setStartWeeks() {
      const isStartWeekEven = this.startDateWeekNo % 2 === 0;
      if (isStartWeekEven) {
        this.form.lastOddWeekNo = getISOWeek(subWeeks(parseISO(this.form.startDate), 1));
        this.form.lastEvenWeekNo = getISOWeek(subWeeks(parseISO(this.form.startDate), 2));
      } else {
        this.form.lastOddWeekNo = getISOWeek(subWeeks(parseISO(this.form.startDate), 2));
        this.form.lastEvenWeekNo = getISOWeek(subWeeks(parseISO(this.form.startDate), 1));
      }
    },
    ...mapActions('transitionperiod', {
      createTransitionWorkspace: GENERATE_TRANSITION_WORKSPACE,
    }),
    ...mapActions('departments', {
      reloadDepartments: ALL_DEPARTMENTS,
    }),
    ...mapMutations('departments', {
      setWorkspace: SET_DEPARTMENT,
    }),
    startDateChanged() {
      if (this.form.lastEvenWeekNo !== 0) {
        this.$ntf({
          type: 'info',
          message: 'OBS',
          subtitle: 'Formens andre værdier blev automatisk opdateret',
          duration: 7000,
        });
      }

      this.setStartWeeks();
      this.setUniqueIntervals();
      this.setDefaultStartWeeks();
    },
    onIntervalChange({ value, key }) {
      delete this.form.intervalMap[key];

      this.form.intervalMap[key] = value;

      this.form.intervalMap = { ...this.form.intervalMap };
    },
    onFrequencyStartWeekChange({ value, key }) {
      delete this.form.frequencyStartWeekMap[key];

      this.form.frequencyStartWeekMap[key] = value;

      this.form.frequencyStartWeekMap = { ...this.form.frequencyStartWeekMap };
    },
    onCreateTransitonPeriodResult(result) {
      this.createdTransitionPeriod = result;
    },
    onSetTransitionPeriodWorkspace(id) {
      this.setWorkspace(id);

      this.$nextTick(() => {
        window.location = `${window.location.origin}/plan/transition-period-details`;
      });
    },
    getLabel(interval) {
      return `Hver ${interval}. uge - Hvilken uge er denne frekvens kommet til i uge ${this.startDateWeekNo - 1}?`;
    },
    getNewFreqLabel(interval) {
      return `Hver ${interval}. uge - Hvilken uge skal denne frekvens starte i (${this.startDateWeekNo})?`;
    },
    getPossibleValues(interval) {
      // If uneven you can select every week
      let i = 1;
      const values = [];
      while (i <= interval) {
        values.push(i);
        i += 1;
      }

      return `Dette kan være ${values.join(', ')}`;
    },
    getPossibleValuesEvenOdd(interval) {
      // If uneven you can select every week
      if (interval % 2 !== 0) {
        let i = 1;
        const values = [];
        while (i <= interval) {
          values.push(i);
          i += 1;
        }

        return `Dette kan være ${values.join(', ')}`;
      }

      // If even you can select every 2nd. If last is Even you can
      // If last week is 27 and interval is 4 you can select 1 and 3. If last week is 28 you can select 2 4

      let i = this.isPreviousWeekEven ? 2 : 1;
      const values = [];
      while (i <= interval) {
        values.push(i);
        i += 2;
      }

      return `Dette kan være ${values.join(', ')}`;
    },
    getMax() {
      const firstValidDate = this.getMaxDate();
      return toHtmlDatePickerFormat(firstValidDate);
    },
    getMaxFormatted() {
      const firstValidDate = this.getMaxDate();
      return format(firstValidDate, 'dd/MM/yyyy');
    },
    getMaxISOWeek() {
      const firstValidDate = this.getMaxDate();
      return getISOWeek(firstValidDate);
    },
    getMinDate(interval) {
      const w = parseISO(this.form.startDate);
      const firstValidWeek = addWeeks(w, -interval);
      const firstValidDate = getDayInPast('Mon', firstValidWeek);
      return firstValidDate;
    },
    getMin(interval) {
      const firstValidDate = this.getMinDate(interval);
      return toHtmlDatePickerFormat(firstValidDate);
    },
    getMinFormatted(interval) {
      const firstValidDate = this.getMinDate(interval);
      return format(firstValidDate, 'dd/MM/yyyy');
    },
    getMinISOWeek(interval) {
      const firstValidDate = this.getMinDate(interval);
      return getISOWeek(firstValidDate);
    },
    onSummerChange({ value, label }) {
      delete this.form.summerFrequencies[label];

      this.form.summerFrequencies[label] = value;

      this.form.summerFrequencies = { ...this.form.summerFrequencies };
    },
    async fetchFrequencies() {
      try {
        const { data } = await FrequencyService.getSelectOptions();
        this.frequencies = data;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchTransitionPeriodOldFrequenciesToMap() {
      try {
        const { data } = await TransitionPeriodService.getDistinctTransitionPeriodFrequencies();

        const { frequencies } = data;

        this.hasOldFrequencies = frequencies.length > 0 && !(frequencies.length === 1 && !frequencies[0]);

        this.oldFrequenciesToMap = data.frequencies;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchTransitionPeriodWeekNosToMap() {
      try {
        const { data } = await TransitionPeriodService.getDistinctTransitionPeriodPlannedWeekNumbers({ mappings: this.form.oldFrequenciesMapped });
        this.plannedWeekNosToMap = data;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async onOldFrequencyMapping({ label, value }) {
      delete this.form.oldFrequenciesMapped[label];

      if (value) {
        this.form.oldFrequenciesMapped[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.form.oldFrequenciesMapped = { ...this.form.oldFrequenciesMapped };

      const isAllMapped = Object.keys(this.form.oldFrequenciesMapped).length === this.oldFrequenciesToMap.length;

      if (isAllMapped) {
        await this.fetchTransitionPeriodWeekNosToMap();
        this.hasMappedFrequencies = true;

        const onlyUnique = (val, index, array) => array.indexOf(val) === index;

        const selectedToFrequencyObj = Object.values(this.form.oldFrequenciesMapped)
          .filter(onlyUnique)
          .map((id) => this.allFrequencies.find((freq) => freq.id === id));

        this.uniqueIntervals = getUniqueIntervalsLargerThan(selectedToFrequencyObj, 3);
        this.setUniqueIntervals();
      }
    },
    async onNewFrequencyMapping({ label, value }) {
      delete this.form.frequencyStartWeekMap[label];

      if (value) {
        this.form.frequencyStartWeekMap[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.form.frequencyStartWeekMap = { ...this.form.frequencyStartWeekMap };
    },
    onWeekNoMapping({ label, value }) {
      // ensure it is possible to change values
      delete this.form.plannedWeekMapped[label];

      if (value) {
        this.form.plannedWeekMapped[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.form.plannedWeekMapped = { ...this.form.plannedWeekMapped };
    },
    async onCreateTransitionPeriodWorkspace() {
      try {
        const keys = Object.keys(this.form.summerFrequencies);
        const onlySelected = keys.filter((key) => this.form.summerFrequencies[key] === true);
        const mappedSummerFrequencies = onlySelected.map((label) => this.allFrequencies.find((freq) => freq.label === label).id);

        this.$ntf.info('Klargører mellemtømninger..');
        await this.createTransitionWorkspace({
          start_date: this.form.startDate,
          max_overstep: this.form.maxOverstep,
          last_even_week_no: this.form.lastEvenWeekNo,
          last_odd_week_no: this.form.lastOddWeekNo,
          interval_to_last_week_number_in_old_plan: this.form.intervalMap,
          transition_period_frequency_mappings: this.form.oldFrequenciesMapped,
          new_frequency_start_week_no: this.form.frequencyStartWeekMap,
          transition_period_week_no_mappings: this.form.plannedWeekMapped,
          summer_frequencies: mappedSummerFrequencies,
        });
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error('Mellemtømninger kunne ikke oprettes');
      }
    },
  },
};
</script>

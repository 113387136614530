<template>
  <a
    class="rounded-md p-4 shadow-sm flex items-start"
    :class="disabled ? 'bg-gray-50' : 'bg-white cursor-pointer border border-gray-300 hover:border-indigo-400'"
    @click="onClick"
  >
    <div class="flex justify-between items-start p-4 w-full">
      <div class="flex flex-col w-4/5">
        <span class="flex items-center">
          <span class="text-gray-400 mr-2 mt-0.5">
            <slot name="icon" />
          </span>
          <p class="sub-headline">{{title}}</p>
        </span>

        <p class="text-muted mt-2">{{description}}</p>

        <slot name="content" />
      </div>

      <icon-chev-right v-if="!disabled" class="text-indigo-500 h-6 w-6"></icon-chev-right>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ActionCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('action');
      }
    },
  },
};
</script>

<style>

</style>

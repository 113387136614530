<template>
  <component
    :is="componentType"
    scope="col"
    class="relative w-12 px-6 sm:w-16 sm:px-8 sticky-col first-col"
  >
    <div v-if="isChecked && !indeterminate" class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"></div>

    <input
      type="checkbox"
      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
      :checked="isChecked"
      :indeterminate.prop="indeterminate"
      @change="$emit('change', $event.target.checked)"
    />
  </component>
</template>

<script>
export default {
  name: 'TableCheckbox',
  props: {
    isTh: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType() {
      return this.isTh ? 'th' : 'td';
    },
  },
};
</script>

<style>

</style>

<template>
  <secondary-nav-bar-content
    title="Kørelister"
    description="Her kan du generere kørelister med anbefalet rækkefølge. Tryk 'Opret' til højre for at påbegynde beregningen"
  >
    <create-driving-lists-slider
      v-model="showCreateDrivingListsSlider"
      :vehicle-options="vehicleCandidateOptions"
      @submit="generateForAll"
    ></create-driving-lists-slider>

    <base-button
      slot="actions"
      color="indigo"
      :disabled="isCreateDisabled"
      @click="showCreateDrivingListsSlider = true"
    >Opret</base-button>

    <job-poller
      v-if="generateDrivingListsJobId"
      title="Genererer kørelister"
      description="Dette kan tage nogle minutter, hvis der er mange opgaver"
      :job-id="generateDrivingListsJobId"
      :has-post-process="true"
      :type="jobTypes.GENERATEDRIVINGLISTS"
      @result="onGenerateResult"
    ></job-poller>

    <base-alert
      v-if="zipInfo"
      class="mt-4"
      type="info"
      description="Hvis dit workspace er delt til end tredjepart, vil disse også kunne downloade kørelisterne som en ZIP fil"
    >
      <div slot="title" class="flex items-start justify-between w-full">
        <p>Download alle kørelister som en ZIP fil</p>
        <base-button @click="downloadZIP">
          <span>Download alle</span>
          <span class="font-bold ml-2 mt-0.5 text-indigo-600" style="font-size: 10px;">.zip</span>
        </base-button>
      </div>
    </base-alert>

    <div class="relative mt-6">
      <ul v-loading="drivingListsLoading" class="space-y-4">
        <driving-list-item
          v-for="dv in drivingLists"
          :key="dv.id"
          :vehicle-driving-lists="dv"
          :disabled="isCreateDisabled"
          @on-download="download"
        ></driving-list-item>
      </ul>
    </div>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import DrivingListItem from '@/components/driving-lists/DrivingListItem.vue';
import CreateDrivingListsSlider from '@/components/driving-lists/CreateDrivingListsSlider.vue';
import DrivingListsService from '@/services/drivingLists.service';

import { mapGetters, mapActions } from 'vuex';

import { GET_DRIVING_LISTS, GENERATE_DRIVING_LISTS, GET_DRIVING_LIST_CANDIDATES, GET_ZIPPED_DRIVING_LIST_INFO } from '@/store/actions.type';
import DRIVING_LIST_TYPES from '@/util/drivingListTypes';

import * as jobTypes from '@/util/jobTypes';
import JobPoller from '@/components/JobPoller.vue';

export default {
  name: 'DrivingListsView',
  components: {
    SecondaryNavBarContent,
    CreateDrivingListsSlider,
    DrivingListItem,
    JobPoller,
  },
  data() {
    return {
      jobTypes,
      vehicleCandidateOptions: [],
      showCreateDrivingListsSlider: false,
      isDoneGenerating: false,
      zipInfo: null,
      DRIVING_LIST_TYPES,
    };
  },
  computed: {
    ...mapGetters('jobs', ['generateDrivingListsJobId']),
    ...mapGetters('drivinglists', ['drivingLists', 'drivingListsLoading']),
    isCreateDisabled() {
      if (this.isDoneGenerating) return false;
      return !!this.generateDrivingListsJobId || !!this.drivingListsLoading;
    },
  },
  async mounted() {
    await this.fetchAll();
    await this.fetchAllCandidates();
    await this.fetchZipInfo();
  },
  methods: {
    ...mapActions('drivinglists', {
      getAll: GET_DRIVING_LISTS,
      getAllCandidates: GET_DRIVING_LIST_CANDIDATES,
      getZippedInfo: GET_ZIPPED_DRIVING_LIST_INFO,
      generateDrivingLists: GENERATE_DRIVING_LISTS,
    }),
    async fetchAllCandidates() {
      try {
        this.vehicleCandidateOptions = await this.getAllCandidates();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async fetchAll() {
      try {
        this.getAll();
      } catch (error) {
        this.$unhandledError(error);
      }
    },

    async fetchZipInfo() {
      try {
        const zipInfo = await this.getZippedInfo();
        this.zipInfo = zipInfo;
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async onGenerateResult() {
      await this.fetchAll();
      await this.fetchZipInfo();
    },
    async download(id) {
      this.$ntf.info('Henter kørelister');
      await DrivingListsService.download(id);
    },
    async downloadZIP() {
      if (!this.zipInfo) {
        this.$ntf.warning('Filen blev ikke fundet');
        return;
      }

      this.$ntf.info('Henter ZIP');
      await DrivingListsService.downloadZip(this.zipInfo.id);
    },
    async generateSingle(payload) {
      try {
        await this.$confirm({
          title: 'Generér køreliste',
          bodyText: 'En eksisterende køreliste vil blive overskrevet',
          type: 'warning',
          confirmText: 'Bekræft',
        });
        await this.generateSingleConfirmed(payload);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async generateSingleConfirmed(payload) {
      this.isDoneGenerating = false;
      try {
        this.$ntf.info('Starter generering af køreliste..');
        await this.generateDrivingLists([payload]);
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async generateForAll(payload) {
      try {
        await this.$confirm({
          title: 'Generér kørelister for alle',
          bodyText: 'Alle kørelister med samme format og type vil blive overskrevet',
          type: 'warning',
          confirmText: 'Bekræft',
        });
        this.showCreateDrivingListsSlider = false;
        await this.generateForAllConfirmed(payload);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    async generateForAllConfirmed(payload) {
      this.isDoneGenerating = false;
      try {
        this.$ntf.info('Starter generering af kørelister..');
        await this.generateDrivingLists(payload);
      } catch (error) {
        this.$unhandledError(error);
      }
    },
  },
};
</script>

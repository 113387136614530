<template>
  <li
    :key="column.field"
    class="py-1.5 text-gray-700 hover:text-indigo-600 cursor-pointer text-left block text-sm"
    :class="{'text-indigo-600': isActive }"
    @click.stop="activeFilterBox = column"
  >
    <button :tab-index="tabIndex">{{column.label}}</button>
  </li>
</template>

<script>
export default {
  name: 'FilterItem',
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>

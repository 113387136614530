<template>
  <div class="w-full z-10 flex-1 relative bg-indigo-600">
    <div leaflet-browser-print-content class="print-container hidden absolute top-4 left-4 z-max">
      <ul
        v-if="partitionSummary.length"
        class="bg-white rounded-md flex flex-wrap space-x-4 px-4 py-3"
      >
        <li
          v-for="(partitionInstance, index) in partitionSummary"
          :key="index"
          class="flex justify-between items-center text-xs"
        >
          <span class="flex items-center">
            <div
              :style="{ 'backgroundColor': partitionInstance.color }"
              class="w-4 h-4 rounded-full"
            ></div>
            <span class="pl-2 py-1">
              {{partitionInstance.label}}
              <span class="font-semibold">({{partitionInstance.count}})</span>
            </span>
          </span>
        </li>
      </ul>
    </div>

    <div id="leaflet" class="absolute inset-0"></div>
  </div>
</template>

<script>
import LeafletDrawableMap from '@/map/leaflet.draw';
import { mapGetters } from 'vuex';

let map = null;

export default {
  name: 'TheLeafletRouteMap',
  props: {
    partitionSummary: {
      type: Array,
      default: () => [],
      description: 'The summary of the cuurent partition which will only be used for printing',
    },
    popupDataFetcher: {
      type: Function,
      required: true,
      description: 'An async function to load remote data for a marker`s popup content',
    },
    infoModalHandler: {
      type: Function,
      required: true,
      description: 'A function to handle clicks on the info tooltip in the map control bar',
    },
    shareModalHandler: {
      type: Function,
      required: false,
      default: () => null,
      description: 'A function to handle clicks on the share command in the map control bar',
    },
    isSharedView: {
      type: Boolean,
      required: false,
      description: 'Whether the view is from the shared access or private',
    },
  },
  computed: {
    ...mapGetters('departments', ['isLightWorkspace']),
  },
  mounted() {
    map = new LeafletDrawableMap(this.popupDataFetcher, this.onOpenGridRequest, this.infoModalHandler, this.isSharedView, this.shareModalHandler, this.isLightWorkspace);
  },
  methods: {
    clearFeatures(keepDrawings = false) {
      map.removeAllPoints();

      if (!keepDrawings) {
        map.removeAllDrawings();
      }
    },
    focusLastEditedLayer() {
      map.focusLastEditedLayer();
    },
    focus() {
      map.focus();
    },
    addFeatures(plannedInstances, zoomTo = true) {
      if (!plannedInstances.length) {
        this.$ntf.info('Ingen opgaver fundet med dit filter');
        return;
      }

      map.addMarkers(plannedInstances, zoomTo);
    },
    onOpenGridRequest(taskIdList, count, totalQuantity) {
      this.$emit('open-grid', { taskIdList, count, totalQuantity });
    },
  },
};
</script>

<template>
  <div>
    <job-poller
      v-if="importTasksJobId || isUploadingForImport"
      title="Importerer og opretter opgaver"
      description="Dette kan tage nogle minutter, hvis der er mange opgaver"
      :job-id="importTasksJobId"
      :type="jobTypes.IMPORT_TASKS"
      @closed="onJobPollerClose"
      @result="onTaskImportResult"
    >
      <image-container v-if="summary" asset-name="success">
        <dl class="sm:divide-y sm:divide-gray-200 mt-4">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Indlæste opgaver</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.totalTasks}}</span>
              </base-badge>
            </dd>
          </div>
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-2">
            <dt class="text-sm font-medium text-gray-500">Indlæste spande</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 flex justify-center">
              <base-badge color="green">
                <span class="text-gray-500 font-bold">{{summary.totalQuantity}}</span>
              </base-badge>
            </dd>
          </div>
        </dl>
      </image-container>
    </job-poller>

    <template v-if="!importTasksJobId && !isUploadingForImport">
      <div v-show="!hasLoadedCSVHeaders" class="mt-8">
        <file-pond
          ref="pond"
          name="csvfile"
          class="col-span-2"
          :allow-multiple="false"
          label-idle="Træk CSV fil her eller <span class='underline'>browse</span>"
          accepted-file-types=".csv, text/csv"
          :file-validate-type-detect-type="getCustomMIMEType"
        />
      </div>

      <base-alert
        v-if="hasLoadedCSVHeaders && configurationOptions.length"
        :align-center="true"
        type="info"
        class="my-4"
      >
        <div slot="title" class="flex items-center justify-between w-full">
          <p>Tip: Benyt en tidligere gemt opsætning</p>
          <base-select
            v-model="selectedConfig"
            :options="configurationOptions"
            width="w-56"
            placeholder="Vælg opsætning"
            @input="onInputChange"
          ></base-select>
        </div>
      </base-alert>

      <div v-if="hasLoadedCSVHeaders" class="mt-2">
        <p class="sub-headline">Felter</p>

        <base-alert type="info" class="mt-2 mb-4" :align-center="true">
          <div slot="title" class="flex items-center justify-between w-full">
            <p>Tip: Se den første række data, så du nemmere kan vælge de rigtige kolonner</p>
            <base-button
              size="sm"
              color="indigo"
              :inverted="true"
              @click="rowPeakSliderOpen = !rowPeakSliderOpen"
            >Åben vindue (Alt+M)</base-button>
          </div>
        </base-alert>

        <h3 v-if="filename" class="text-gray-500 mb-2">{{filename}}</h3>

        <base-alert v-if="duplicateHeaders.length" title="Ugyldig fil" type="error" class="mt-4">
          <p>
            Følgende kolonne navne fremgår flere gange:
            <span
              class="font-semibold"
            >{{duplicateHeadersList}}</span>
          </p>

          <br />

          <p>Ret venligst filen, så den ikke har dubletter i kolonnenavnene og genindlæs denne side.</p>

          <br />

          <p>Systemet kan ikke indlæse filen korrekt, hvis kolonnenavne ikke er unikke.</p>
        </base-alert>

        <template v-else>
          <import-mapping-list
            ref="configMappings"
            :headers="csvHeaders"
            :first-row-peak-map="csvFirstRowPeakMap"
            @configurationChange="onConfigurationChange"
          ></import-mapping-list>

          <base-alert type="info" class="my-4" :align-center="true">
            <div slot="title" class="flex items-center justify-between w-full">
              <p>
                <span>Tip: Gem din opsætning, så du kan benytte dem i fremtidige importeringer eller andre i workspaces</span>
              </p>
              <base-button
                color="indigo"
                class
                :inverted="true"
                :disabled="!configurationValid || loading"
                @click="showConfigurationModal = true"
              >Gem opsætning</base-button>
            </div>
          </base-alert>

          <div class="flex justify-end my-4">
            <base-button
              v-if="!isNewImport"
              color="indigo"
              :disabled="!configurationValid || loading"
              @click="initateMergeFlow"
            >Påbegynd flow for sammenfletning</base-button>

            <base-button
              v-else
              color="indigo"
              :disabled="!configurationValid || loading"
              @click="submitWithoutConfig"
            >Importer</base-button>
          </div>
        </template>
      </div>

      <base-modal v-model="showConfigurationModal" title="Giv din opsætning et navn">
        <base-input
          v-model="configurationName"
          label="Navn på opsætning"
          placeholder="Vælg et navn til din opsætning"
          type="text"
        ></base-input>

        <div slot="footer" class="grid grid-cols-2 gap-4 w-full">
          <base-button
            v-if="selectedConfig"
            @click="submitWithConfig(true)"
          >Opdater valgte opsætning</base-button>
          <base-button color="indigo" @click="submitWithConfig(false)">Opret en ny opsætning</base-button>
        </div>
      </base-modal>
    </template>

    <task-import-peak-slider
      v-model="rowPeakSliderOpen"
      :filename="filename"
      :key-value-pairs="csvFirstRowPeakMap"
    ></task-import-peak-slider>
  </div>
</template>

<script>
import vueFilePond, { setOptions } from 'vue-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import ImportMappingList from '@/components/import/ImportMappingList.vue';
import ImageContainer from '@/components/ImageContainer.vue';
import JobPoller from '@/components/JobPoller.vue';
import TaskImportPeakSlider from '@/components/import/TaskImportPeakSlider.vue';
import ImportConfigurationValidation from '@/components/import/ImportConfigurationValidation.vue';

import ConfigurationsService from '@/services/configurations.service';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CHECK_STATUS_ALL, UPLOAD_FOR_MERGE_PLAN, IMPORT_TASKS, GET_CSV_HEADERS } from '@/store/actions.type';

import { RESET_FLOW, SET_CURRENT_FIELDS_AFTER_IMPORT, ADD_COMPLETED_MERGE_FLOW_STEP, SET_FILENAME } from '@/store/mutations.type';
import filePondLocale from '@/locales/da-DK/filePond';
import * as jobTypes from '@/util/jobTypes';
import mergeFlowSteps from '@/util/mergeFlowSteps';
import Vue from 'vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: 'ImportUploadView',
  components: {
    FilePond,
    ImportMappingList,
    ImageContainer,
    JobPoller,
    TaskImportPeakSlider,
  },
  data() {
    return {
      loading: false,
      summary: null,
      hasLoadedCSVHeaders: false,
      csvHeaders: [],
      csvFirstRowPeakMap: {},
      currentFile: null,
      configurationValid: false,
      configurationOptions: [],
      selectedConfig: '',
      showConfigurationModal: false,
      configurationName: '',
      jobTypes,
      rowPeakSliderOpen: false,
    };
  },
  computed: {
    ...mapGetters('jobs', ['importTasksJobId']),
    ...mapGetters('import', ['isUploadingForImport', 'filename']),
    isNewImport() {
      return this.$route.query.new;
    },
    duplicateHeadersList() {
      return this.duplicateHeaders.join(', ');
    },
  },
  created() {
    setOptions({
      ...filePondLocale,
      // https://pqina.nl/filepond/docs/patterns/api/server/
      server: {
        // eslint-disable-next-line no-unused-vars
        process: (fieldName, file, _metadata, load, _error, progress, abort, _transfer, _options) => {
          this.loading = true;

          this.setFilename(file.name);

          if (!this.hasLoadedCSVHeaders) {
            this.sniffHeaders(file, abort, load);
          }
        },
      },
    });
  },
  async mounted() {
    await this.getConfigurationOptions();

    document.addEventListener('keydown', this.onSliderHotkey);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', this.onSliderHotkey);
    });
  },

  methods: {
    ...mapActions('status', {
      checkStatusAll: CHECK_STATUS_ALL,
    }),
    ...mapMutations('import', {
      resetMergeFlow: RESET_FLOW,
      setFilename: SET_FILENAME,
      setCurrentFields: SET_CURRENT_FIELDS_AFTER_IMPORT,
      addCompletedMergeFlowStep: ADD_COMPLETED_MERGE_FLOW_STEP,
    }),
    ...mapActions('import', {
      importTasks: IMPORT_TASKS,
      uploadForMergePlan: UPLOAD_FOR_MERGE_PLAN,
      getCsvHeaders: GET_CSV_HEADERS,
    }),
    onSliderHotkey(event) {
      if (event.altKey && event.key === 'm' && this.hasLoadedCSVHeaders) {
        this.rowPeakSliderOpen = !this.rowPeakSliderOpen;
      }
    },
    async onJobPollerClose() {
      // reset view completely
      this.loading = false;
      this.summary = null;
      this.hasLoadedCSVHeaders = false;
      this.duplicateHeaders = [];
      this.csvHeaders = [];
      this.csvFirstRowPeakMap = {};
      this.currentFile = null;
      this.configurationValid = false;
      this.configurationOptions = [];
      this.selectedConfig = '';
      this.showConfigurationModal = false;
      this.configurationName = '';
    },
    async getConfigurationOptions() {
      try {
        const { data } = await ConfigurationsService.getAllByType('IMPORT');
        this.configurationOptions = data.configs;
      } catch (error) {
        this.$unhandledError(error, true);
      }
    },
    async onInputChange() {
      try {
        const { data } = await ConfigurationsService.get(this.selectedConfig);
        this.configurationName = data.name;
        this.$refs.configMappings.setValuesFromConfiguration(data.config);
      } catch (error) {
        this.$unhandledError(error, true);
      }
    },
    onConfigurationChange({ valid }) {
      this.configurationValid = valid;
    },
    getCustomMIMEType(source, type) {
      if (window.navigator.platform.indexOf('Win') > -1) {
        if (type === 'application/vnd.ms-excel') {
          // eslint-disable-next-line no-param-reassign
          type = 'text/csv';
        }
      }
      return Promise.resolve(type);
    },
    async onTaskImportResult(result) {
      this.summary = result;
      this.checkStatusAll();
    },
    async uploadFile(configuration) {
      const importantMappings = this.$refs.configMappings.getImportantMappings();

      const firstRowMissingValues = importantMappings
        .filter((importantField) => !this.csvFirstRowPeakMap[importantField.mapped])
        .map((field) => field.mapped)
        .filter((value) => value);
      const missingImportantMappings = importantMappings.filter((importantField) => !importantField.mapped).map((field) => field.title);

      const requiresConfirmation = firstRowMissingValues.length || missingImportantMappings.length;
      if (requiresConfirmation) {
        const ComponentClass = Vue.extend(ImportConfigurationValidation);
        const instance = new ComponentClass({
          propsData: {
            missingFirstRowValues: firstRowMissingValues,
            missingMappings: missingImportantMappings,
          },
        });
        instance.$mount();

        try {
          await this.$confirm({
            type: 'warning',
            title: 'Mulig miskonfiguration',
            bodyText: instance.$el.innerHTML,
            isHTML: true,
            confirmText: 'Fortsæt import',
          });
        } catch (_) {
          // do nothing
          return;
        }
      }

      try {
        this.$ntf.info('Begynder importering af opgaver');
        await this.importTasks({ file: this.currentFile, config: configuration, filename: this.filename });
      } catch (error) {
        this.$unhandledError(error, false);
      }
      this.currentFile = null;
    },
    sniffHeaders(file, abort) {
      this.duplicateHeaders = [];

      this.getCsvHeaders({ file })
        .then((data) => {
          this.duplicateHeaders = data.headers.filter((value, index, arr) => arr.indexOf(value) !== index);
          this.currentFile = file;
          this.csvHeaders = data.headers;
          this.csvFirstRowPeakMap = data.peak;
          this.hasLoadedCSVHeaders = true;
        })
        .catch((err) => {
          if (err?.data?.error?.file) {
            this.$ntf({
              type: 'error',
              subtitle: this.$ntf.error(err?.data?.error?.file),
              duration: 7000,
            });
          } else {
            this.$unhandledError(err);
          }
          abort();
        })
        .finally(() => {
          this.loading = false;
          this.$refs.pond.removeFiles();
        });
    },
    async submitWithConfig(shouldUpdate) {
      this.showConfigurationModal = false;
      const configuration = this.$refs.configMappings.getConfiguration();
      const payload = {
        id: this.selectedConfig,
        name: this.configurationName,
        type: 'IMPORT',
        data: configuration,
      };

      try {
        if (shouldUpdate) {
          await ConfigurationsService.update(payload);
          this.$ntf.success('Opdaterede opsætning');
        } else {
          await ConfigurationsService.create(payload);
          this.$ntf.success('Oprettede opsætning');
        }
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async submitWithoutConfig() {
      const configuration = this.$refs.configMappings.getConfiguration();
      await this.uploadFile(configuration);
    },
    async initateMergeFlow() {
      try {
        const tmpFilename = this.filename;

        this.resetMergeFlow();
        this.$ntf.info('Påbegynder flow..');
        const jobId = await this.uploadForMergePlan({ file: this.currentFile });
        const configuration = this.$refs.configMappings.getConfiguration();
        this.setCurrentFields({
          jobId,
          configuration,
          filename: tmpFilename,
        });

        this.addCompletedMergeFlowStep(mergeFlowSteps.IMPORT);
        this.$router.push({ name: 'task-import-flow' });
      } catch (error) {
        this.$unhandledError(error, false);
      }
    },
  },
};
</script>

<style>
/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
  @apply h-32;
}

.filepond--hopper {
  @apply border-2 border-dashed rounded-md bg-white border-gray-200;
}

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: white;
}

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: red;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: black;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

.filepond--panel-bottom {
  box-shadow: none !important;
}

/* the text color of the file status and info labels */
.filepond--file {
  color: black;
}

.filepond--file-wrapper {
  border: 1px solid rgb(226, 232, 240) !important;
  @apply rounded-md;
}

/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  @apply bg-red-700;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  @apply bg-green-50;
}

/* bordered drop area */
.filepond--panel-root {
  background-color: transparent;
}
</style>

<template>
  <slider v-model="model" width="max-w-lg" title="Opret kørelister" :close-on-overlay-click="true">
    <div class="my-8">
      <base-alert
        title="Hvorfor ser jeg ikke alle køretøjer?"
        description="Køretøjer uden opgaver vises ikke"
        type="info"
        :dismissable="true"
      ></base-alert>

      <div class="relative mt-8">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-base font-medium text-gray-500">Køretøjer</span>
        </div>
      </div>

      <ul>
        <li v-for="vehicle in vehicleOptions" :key="vehicle.value" class="flex items-center">
          <icon-truck class="w-4 h-4 text-indigo-700 mr-2"></icon-truck>
          <span class="text-gray-800">{{vehicle.label}}</span>
        </li>
      </ul>

      <div class="relative mt-8">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-base font-medium text-gray-500">Format</span>
        </div>
      </div>

      <base-input class="mt-4">
        <base-checkbox
          slot="input"
          v-model="form.generate_excel"
          tabindex="9"
          description="Opret kørelister som Excel"
          label="Excel"
        ></base-checkbox>
      </base-input>

      <base-input class="mt-4">
        <base-checkbox
          slot="input"
          v-model="form.generate_pdf"
          tabindex="9"
          description="Opret kørelister som PDF"
          label="PDF"
        ></base-checkbox>
      </base-input>

      <div class="relative mt-8">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-base font-medium text-gray-500">Rækkefølge</span>
        </div>
      </div>

      <base-input class="mt-4">
        <base-checkbox
          slot="input"
          v-model="form.generate_with_order"
          tabindex="9"
          description="Opret kørelister med rækkefølge"
          label="Med rækkefølge"
        ></base-checkbox>
      </base-input>

      <base-input v-if="form.generate_with_order" class="mt-4">
        <base-checkbox
          slot="input"
          v-model="form.generate_statistics"
          tabindex="10"
          description="Opret kørelister med statistik"
          label="Statistik"
        ></base-checkbox>
      </base-input>

      <template v-if="!isLightWorkspace">
        <div class="relative mt-8">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="px-3 bg-white text-base font-medium text-gray-500">Type</span>
          </div>
        </div>

        <base-input class="mt-4" label="Inkludér alle tømninger">
          <base-radio
            slot="input"
            :value="form.type"
            name="type"
            :radio-value="DRIVING_LIST_TYPES.ONLY_FULL_YEAR"
            :inline="true"
            @change="form.type = DRIVING_LIST_TYPES.ONLY_FULL_YEAR"
          >
            <span class="ml-4 text-xs">Helårstømninger</span>
          </base-radio>
        </base-input>
      </template>

      <!--<base-input class="mt-4" label="Inkludér kun sommertømninger ">
        <base-radio
          slot="input"
          :value="form.type"
          name="type"
          :radio-value="DRIVING_LIST_TYPES.WITH_SUMMER"
          :inline="true"
          @change="form.type = DRIVING_LIST_TYPES.WITH_SUMMER"
        >
          <span class="ml-4 text-xs">Alle opgaver</span>
        </base-radio>
      </base-input>-->

      <div class="flex justify-end mt-6">
        <base-button tabindex="10" :disabled="isDisabled" color="indigo" @click="submit">Opret</base-button>
      </div>
    </div>
  </slider>
</template>

<script>
import Slider from '@/components/Slider.vue';
import DRIVING_LIST_TYPES from '@/util/drivingListTypes';

import { mapGetters } from 'vuex';

export default {
  name: 'CreateDrivingListsSlider',
  components: {
    Slider,
  },
  props: {
    value: {
      type: Boolean,
    },
    vehicleOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        vehicles: [],
        type: DRIVING_LIST_TYPES.ONLY_FULL_YEAR,
        generate_with_order: false,
        generate_excel: true,
        generate_pdf: true,
        generate_statistics: false,
      },
      DRIVING_LIST_TYPES,
    };
  },
  computed: {
    ...mapGetters('departments', ['isLightWorkspace']),
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);
      },
    },
    isDisabled() {
      return !this.form.vehicles.length || (!this.form.generate_pdf && !this.form.generate_excel);
    },
  },
  watch: {
    vehicleOptions: {
      immediate: true,
      handler: 'vehicleOptionsChanged',
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.form);
    },
    vehicleOptionsChanged() {
      // preselect vehicles
      this.form.vehicles = this.vehicleOptions.map((x) => x.value);
    },
  },
};
</script>

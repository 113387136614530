export default (record) => {
  if (record.address) return record.address;

  const door = record.address_door_letter ?? '';
  const number = record.address_number ?? '';
  const city = record.city ?? '';
  const zip_code = record.zip_code ?? '';
  const road_name = record.address_road ?? '';

  return `${road_name} ${number} ${door} ${zip_code} ${city}`;
};
